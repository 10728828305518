import React, { useEffect, useState } from "react";

import { useDrivers } from "./context/DriversContext";
import { DriverssRow } from "./Types/Driver";
import DriversTable from "./components/DriversTable";
import {
  allDriversColumns,
  notConfirmedDriversColumns,
  notVerifiedDriversColumns,
} from "./Types/DataTable";
import { useSearch } from "../Search/context/search.context";
import { Link } from "react-router-dom";

export default function Drivers() {
  const [selectedTable, setSelectedTable] = useState(1);
  const { drivers } = useDrivers();

  const [allDriversData, setAllDriversData] = useState<DriverssRow[]>([]);

  const [searchedDrivers, setSearchedDrivers] = useState<[]>([]);
  const { searchDrivers } = useSearch();
  async function searchHandler(e) {
    if (e.target.value) {
      const data = await searchDrivers?.({ text: e.target.value });

      const simillarDriver: [] = data || [];

      setSearchedDrivers([...simillarDriver]);
    } else {
      setSearchedDrivers([]);
    }
  }

  useEffect(() => {
    const sampleData: DriverssRow[] = [];
    drivers?.map((driver) => {
      sampleData.push({
        ...driver,
      });
    });

    setAllDriversData(sampleData);
    return () => {};
  }, [drivers, searchedDrivers]);

  console.log({
    searchedDrivers,
  });

  return (
    <>
      <div className="app-title">
        <div>
          <h1>
            <i className="fa fa-th-list"></i>السائقين
          </h1>
        </div>
        <div>
          <div className="position-relative">
            <form action="#">
              <label htmlFor="amount"> إبحث </label>
              <input
                className="form-control"
                type="text"
                name="search"
                autoComplete="off"
                placeholder="رقم الهاتف بدون 0"
                onChange={searchHandler}
              />
            </form>
            <div
              className="card position-absolute"
              style={{ zIndex: 10000000 }}
            >
              <ul className="list-group " style={{ padding: "0" }}>
                {searchedDrivers.map((driver, index) => {
                  return (
                    <li key={index} className="list-group-item">
                      <Link to={`/driver/${driver["uuid"]}`}>
                        <div className="d-flex flex-row">
                        {" "}
                        <span> {driver["firstname"]}  {driver["lastname"]}  </span>
                        </div>
                        <div className="d-flex">
                          <p>الهاتف : </p>

                          <p className="text-secondary">
                            {" "}
                            {driver["phoneNumber"]}{" "}
                          </p>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div onClick={() => setSelectedTable(1)} className="col-md-6 col-lg-3">
          <div className="widget-small primary coloured-icon">
            <i className="icon fa fa-users fa-3x"></i>
            <div className="info">
              <h4>عدد السائقين الكلي </h4>
              <p>
                <b> {drivers?.length || 0} </b>
              </p>
            </div>
          </div>
        </div>

        <div onClick={() => setSelectedTable(2)} className="col-md-6 col-lg-3">
          <div className="widget-small primary coloured-icon">
            <i className="icon fa fa-users fa-3x"></i>
            <div className="info">
              <h4> حسابات غير مؤكدة </h4>
              <h4>sms</h4>
              <p>
                <b>
                  {" "}
                  {drivers?.filter((driver) => driver.active !== true)
                    ?.length || 0}{" "}
                </b>
              </p>
            </div>
          </div>
        </div>
        <div onClick={() => setSelectedTable(3)} className="col-md-6 col-lg-3">
          <div className="widget-small primary coloured-icon">
            <i className="icon fa fa-users fa-3x"></i>
            <div className="info">
              <h4> حسابات غير مفعلة </h4>
              <p>
                <b>
                  {" "}
                  {drivers
                    ?.filter((driver) => driver.active === true)
                    .filter((driver) => driver.statuscode !== 111)?.length ||
                    0}{" "}
                </b>
              </p>
            </div>
          </div>
        </div>
        <div onClick={() => setSelectedTable(4)} className="col-md-6 col-lg-3">
          <div className="widget-small primary coloured-icon">
            <i className="icon fa fa-users fa-3x"></i>
            <div className="info">
              <h4> حسابات  سيارات  اساسية غير مفعلة </h4>
              <p>
                <b>
                {drivers
                    ?.filter((driver) => driver.statuscode === 111)
                    .filter((driver) => driver.active === true).filter((driver) => driver.driverDetails !== null)
                    .filter((driver) => driver.defaultcar?.statuscode !== 111)
                    ?.length 
                    || 0}
                </b>
              </p>
            </div>
          </div>
        </div>

        <div onClick={() => setSelectedTable(5)} className="col-md-6 col-lg-3">
          <div className="widget-small info coloured-icon">
            <i className="icon fa fa-thumbs-o-up fa-3x"></i>
            <div className="info">
              <h4>نشط</h4>
              <p>
                <b>
                  {drivers
                    ?.filter((driver) => driver.statuscode === 111)
                    .filter((driver) => driver.active === true).filter((driver) => driver.driverDetails !== null)
                    .filter((driver) => driver.defaultcar?.statuscode === 111)
                    ?.length 
                    || 0}
                </b>
              </p>
            </div>
          </div>
        </div>
        <div onClick={() => setSelectedTable(6)} className="col-md-6 col-lg-3">
          <div className="widget-small warning coloured-icon">
            <i className="icon fa fa-files-o fa-3x"></i>
            <div className="info">
              <h4>محظور</h4>
              <p>
                <b>
                  {" "}
                  {drivers?.filter((driver) => driver.statuscode === 222)
                    .length || 0}{" "}
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
      {<DriversTableShower data={allDriversData} id={selectedTable} />}
      <br />
      <br />
    </>
  ); 
}

function DriversTableShower({ id, data }: { id: number; data: DriverssRow[] }) {
  switch (id) {
    case 1:
      return (
        <DriversTable columns={allDriversColumns} data={data}></DriversTable>
      );
    case 2:
      return (
        <DriversTable
          columns={notVerifiedDriversColumns}
          data={data.filter((driver) => driver.active !== true)}
        ></DriversTable>
      );

    case 3:
      return (
        <DriversTable
          columns={notConfirmedDriversColumns}
          data={data
            .filter((driver) => driver.active === true)
            .filter((driver) => driver.statuscode !== 111)}
        ></DriversTable>
      );

    case 4:
      return (
        <DriversTable
          columns={allDriversColumns}
          data={data
            .filter((driver) => driver.active === true).filter((driver) => driver.driverDetails !== null)
            .filter((driver) => driver.statuscode === 111).filter((driver) => driver.defaultcar?.statuscode !== 111)}
        ></DriversTable>
      );

    case 5:
      return (
        <DriversTable
          columns={allDriversColumns}
          data={data
            .filter((driver) => driver.active === true).filter((driver) => driver.driverDetails !== null)
            .filter((driver) => driver.statuscode === 111).filter((driver) => driver.defaultcar?.statuscode === 111)}
        ></DriversTable>
      );
    case 6:
      return (
        <DriversTable
          columns={notConfirmedDriversColumns}
          data={data.filter((driver) => driver.statuscode === 222)}
        ></DriversTable>
      );

    default:
      return <></>;
  }
}
