import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Admin } from '../../../../entities/Admin';
import { useAdmin } from '../../Context/admin.context';

export default function EditAdminPassword() {
  const {admin, updateAdminPassword} = useAdmin();
  const { adminuuid } = useParams();
  let navigate = useNavigate();
  
  
const [updatedPassword, setUpdatedPassword] = React.useState({
    oldPassword:null,
    newPassword: null,
    confirmNewPassword: null

});
function changer(value) {
    setUpdatedPassword({
    ...value
  })
}

async function handlerSubmit() {
  
    // if(updatedPassword.newPassword === updatedPassword.confirmNewPassword) {
        // console.log({
        //     updatedPassword
        // });
        await updateAdminPassword?.({
            uuid: adminuuid,
            credentials: {
                oldPassword:updatedPassword.oldPassword,
                newPassword: updatedPassword.newPassword,
            }
          })
    // }

  
//   navigate("/user")

}

React.useEffect(() => {
  if(adminuuid === admin?.uuid) {
    // setUpdatedAdmin({...admin})
  }
 
  return () => {
    
  }
}, [])


  return (
<div className="px-3 mt-5">
    <div className="card  my-3 px-3 ">
      <div className="pt-3">
        <h3> تعديل كلمة السر </h3>
      </div>
      <div className="card-body ">
        <form onSubmit={(e) => e.preventDefault()} action="#">
        
        
          <div>
            <label htmlFor="amount">  كلمة السر القديمة  </label>
            <input
              className="form-control"
              type="password"
              name="oldPassword"
              id=""
            
              onChange={(e) => changer({...updatedPassword, oldPassword: e.target.value.toString() })}
            />
          </div>
        
          <div>
            <label htmlFor="amount">  كلمة السر الجديدة  </label>
            <input
              className="form-control"
              type="password"
              name="newPassword"
              id=""
            
              onChange={(e) => changer({...updatedPassword, newPassword: e.target.value.toString() })}
            />
          </div>
        
          <div>
            <label htmlFor="amount"> تأكيد كلمة السر الجديدة   </label>
            <input
              className="form-control"
              type="password"
              name="confirmNewPassword"
              id=""
          
              onChange={(e) => changer({...updatedPassword, confirmNewPassword: e.target.value.toString() })}
            />
          </div>
          {/* <div className="form-group">
                    <label htmlFor="exampleSelect1"> الصلاحية </label>
                    <select  value={updatedAdmin?.rolecode}    onChange={(e) => changer({...updatedAdmin, rolecode: Number(e.target.value) })} className="form-control" id="exampleSelect1">
                        {
                           roles.map(number => {
                             return   <option key={number.code} value={number.code}>{number.name}</option>
                            })
                        }
                
                     
                    </select>
                  </div> */}

          <button
            onClick={e => handlerSubmit()}
            className="my-3 btn btn-primary"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
  )
}
