import axios from "axios";
import React, { createContext, ReactNode, useContext, useState } from "react";
import { useEffect } from "react";

type ErrorsType = {
    message: string,
    code: number
}
type ErrorsContextType = {
  errors?: Array<ErrorsType>,
  setErrors: Function
};
const ErrorsContext = createContext<ErrorsContextType>({
    errors: [],
    setErrors: Function

});
type ProvidersProps = {
  name: string;
  children?: React.ReactNode;
};
export const ErrorsProvider: React.FunctionComponent<ProvidersProps> = ({
  children,
}) => {

    const [errors, setErrors] = useState<Array<ErrorsType>>([
 
    ]);


  useEffect(() => {

    const interval = setInterval(() => {

        
     if(errors.length > 0) {
      const shiftedErrors = errors.slice(1); 
 
      
      if(shiftedErrors !== undefined) {
        setErrors(state => 
          [
            ...shiftedErrors
          ]
        )
      }
     }
   
    
    }, 3000);
    return () => clearInterval(interval);
  }, [errors]);


 
  return (
    <ErrorsContext.Provider
      value={{ errors, setErrors}}
    >
      {children}
    </ErrorsContext.Provider>
  );
};

export const useErrors = () => useContext(ErrorsContext);
