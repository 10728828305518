import React from 'react'
import DataTable from 'react-data-table-component'
import { Navigate } from 'react-router-dom';
import { PassengersColumn, PassengersRow } from '../types/Passengers';

export default function PassengersTable({
    columns,
    data,
  }: {
    columns: PassengersColumn[];
    data: PassengersRow[];
  }) {
  function openDriver(row: PassengersRow): void {
    // throw Navigate('/passenger')
  }

  return (
    <>

<DataTable
        pagination
        paginationPerPage={200}
        columns={columns}
        data={data}
        selectableRows 
        onRowClicked={(row) => openDriver(row)}
      />
    </>
  )
}
