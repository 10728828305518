/* eslint-disable no-restricted-globals */
import { useAdmin } from "./app/components/Admins/Context/admin.context";
import React from "react";
import Login from "./app/components/Admins/Components/Auth/Login";
import Errormessage from "./app/components/Common-components/Error/Error-message";

export default function AuthRoute({
  children,
}: {
  children?: React.ReactElement;
}) {
  const url = window.location.pathname;

  const { admin, getAdmin } = useAdmin();
  React.useEffect(() => {
    getAdmin?.();

    return () => {};
  }, []);
  if (!admin || admin === null) {
    
    return < LoadingScreen url={url} />
 
  }
  //Signed in
  return (
    <>
      <Errormessage />
      {children}
    </>
  );
}


export  function LoadingScreen({url}: {url: string}) {
  const { admin,adminloading, getAdmin } = useAdmin();
  


  // if(!admin || admin === null) {
    
  //   return <Login from={url} />
  // }
  return adminloading  === true ?   <div>Loading....</div> : <Login from={url} />
}

