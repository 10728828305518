import React from "react";

export default function Loader() {
  return (
    <div className="d-flex justify-content-center">
      <div className="loader-wrapper">
        <div className="loader"></div>
      </div>
    </div>
  );
}
