import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { useEffect } from "react";
import { BASE_URL, socket } from "../../../core/common";
import { ProvidersProps } from "../../../core/types";
import { Trip } from "../../entities/trip";
import { TRIP_EVENTS } from "../../Socket-Events/events";
import { useErrors } from "../Common-components/Error/error.context";
import { TripRepository } from "./repository/trip.repository";
import { TripService } from "./Service/Trip.service";

type PrivateTripContextType = {
  privateTriploading?: boolean;
  setLoading?: Function | null;
  allPrivateTrip? : Trip[] | null;
  ongoingPrivateTrip? : Trip[] | null;
  getAPrivateTrip? : Function;
  createTrip? : Function;
  createTripLoading? : boolean;
  selectedPrivateTrip?: Trip | null
  createdTrip?: Trip | null
  cancelTrip?: Function
  joinAsocket?: Function,
  SetSelectedPrivateTrip?: Function

};
const PrivateTripContext = createContext<PrivateTripContextType>({
  privateTriploading: false,
  // allPrivateTrip : null,
  getAPrivateTrip : Function,
  createTrip : Function,
  selectedPrivateTrip: null,
  createdTrip: null,
  cancelTrip: Function,
  createTripLoading: false,

  SetSelectedPrivateTrip: Function,
  joinAsocket: Function
});
const tripService:TripService = new  TripService();

export const PrivateTripProvider: React.FunctionComponent<ProvidersProps> = ({
  children,
}) => {

    const [allPrivateTrip, setAllPrivateTrip] = useState<Trip[] | null | undefined>();
    const [privateTriploading, setPrivateTriploading] = useState<boolean>();
    const [selectedPrivateTrip, SetSelectedPrivateTrip] = useState<Trip | null | undefined>();
    const [ongoingPrivateTrip, setOngoingPrivateTrip] = useState<Trip[] | null | undefined>();
    const { setErrors } = useErrors();

    const [createdTrip, setcreatedTrip] = useState<Trip | null>();
    const [createTripLoading, setCreateTripLoading] = useState<boolean>(false);


   
    async function getALlPrivateTrips() {
      try {
        setPrivateTriploading(true)
          const trips= await tripService.find({filter: {}});
          setAllPrivateTrip(trips);
      
            setPrivateTriploading(false)

      } catch (error:any) {
        setErrors((state: any) => [
          ...state,
          { message: error.message, code:error.statusCode },
        ]);
        setPrivateTriploading(false)

      }
      
      
      
    
      
    }

    async function getAPrivateTrip({uuid}:{uuid: string}) {
      try {
        setPrivateTriploading(true)
          const trip= await tripService.findOne({filter: {uuid: uuid}});
           
            
            SetSelectedPrivateTrip({...trip})
            setPrivateTriploading(false)

      } catch (error:any) {
        setErrors((state: any) => [
          ...state,
          { message: error.message, code:error.statusCode },
        ]);
        setPrivateTriploading(false)

      }
    }
  
    async function createTrip({input}:{input: Trip}) {
      try {
          
        
          setCreateTripLoading(true)
          const data= await tripService.create({input: {...input}});
        
          // const {} = await tripService.tripCreatedNotification({})
          setCreateTripLoading(false)
            return {trip: data}

      } catch (error:any) {
      
        
        setErrors((state: any) => [
          ...state,
          { message: error.message, code:error.statusCode },
        ]);
        setCreateTripLoading(false)

      }
    }
  
    async function cancelTrip({input}:{input: Trip}) {
      try {
          
        
          setCreateTripLoading(true)
          const data= await tripService.passengerCancelTrip({input: {...input}});
        
          // const {} = await tripService.tripCreatedNotification({})
          setCreateTripLoading(false)
            return {trip: data}

      } catch (error:any) {
      
        
        setErrors((state: any) => [
          ...state,
          { message: error.message, code:error.statusCode },
        ]);
        setCreateTripLoading(false)

      }
    }
  
 
  function joinAsocket() {
    console.log({id: socket.id}); 

 
  }
    useEffect(() => {
      getALlPrivateTrips();
      // socket.on('connect', ()=>{
      //   socket.on(TRIP_EVENTS.TRIP_ACCEPTED, (TRIP_ACCEPTED_DATA) => {
      //     console.log({TRIP_ACCEPTED_DATA});
          
      //   })
      // })
    
      return () => {
        
      }
    }, [])
    
  
    

  return <PrivateTripContext.Provider value={{    
    allPrivateTrip,
    ongoingPrivateTrip,
    getAPrivateTrip,
    selectedPrivateTrip,
    createdTrip,
    createTrip,
    cancelTrip,
    createTripLoading,
    privateTriploading,
    joinAsocket,
    SetSelectedPrivateTrip
    }}>{children}</PrivateTripContext.Provider>;
};

export const usePrivateTrip = () => useContext(PrivateTripContext);
