import axios from "axios";
import React, { createContext, ReactNode, useContext, useState } from "react";
import { useEffect } from "react";
import { BASE_URL } from "../../../../core/common";
import { ProvidersProps } from "../../../../core/types";
import { Admin } from "../../../entities/Admin";
import { Balance } from "../../../entities/Balance";
import { useAdmin } from "../../Admins/Context/admin.context";
import { useDrivers } from "./DriversContext";

type BalanceContextType = {
  BalanceLoading?: false;
  addBalance?: Function 
  editBalance?: Function
};
const BalanceContext = createContext<BalanceContextType>({
  BalanceLoading: false,
  addBalance: Function,
  editBalance: Function

});

export const BalanceProvider: React.FunctionComponent<ProvidersProps> = ({
  children,
}) => {

    const [BalanceLoading, setBalanceLoading] = useState(false);

    const [balance, setBalance] = useState<Balance | null | undefined>();
    const {getAdmin} = useAdmin();
    const {getDrivers,getDriver} = useDrivers();

    const addBalance = async (balance: {}) => {
        
        try {
          setBalanceLoading(true)
          const res = await axios.post(
            `${BASE_URL}/api/dashboard/driver/balance/add`,
            {
                ...balance
            },
            
            {
              withCredentials: true,
              headers: {
                "authorization": localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
                "x-api-key":
                  "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
                "Content-Type": "application/json",
              },
            }
            );
                
            getDriver?.({uuid: balance['driveruuid']})
            setBalanceLoading(false)
        
        } catch(e:any) {
            console.log({e});
            setBalanceLoading(false)
            
        }
    }
    const editBalance = async (balance: {}, driveruuid: string) => {
        
        try {
          setBalanceLoading(true)
          const res = await axios.put(
            `${BASE_URL}/api/dashboard/driver/balance/edit/${driveruuid}`,
            {
                ...balance
            },
            {
              withCredentials: true,
              headers: {
                "authorization": localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
                "x-api-key":
                  "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
                "Content-Type": "application/json",
              },
            }
            );
                
            getDriver?.({uuid: driveruuid})
            setBalanceLoading(false)
        
        } catch(e:any) {
            console.log({e});
            setBalanceLoading(false)
            
        }
    }


    useEffect(() => {
    
      return () => {
        
      }
    }, [])
    

   
  return <BalanceContext.Provider value={{addBalance, editBalance}}>{children}</BalanceContext.Provider>;
};

export const useBalance = () => useContext(BalanceContext);
