import { socketapi,api } from "../../../../core";
import { Passenger } from "../../../entities/Passenger";
import { ServiceCenter } from "../../../entities/ServiceCenter";

export class PassengerRepository {

    async getPirvateTrips({accessToken, refreshToken, passengeruuid}: {accessToken: string, refreshToken: string, passengeruuid: string}) {
        try {

            const res = await api.get(`api/dashboard/passengers/private-trip/all/${passengeruuid}`, {
                withCredentials: true,
                headers: {
                    authorization: `Bearer ${accessToken}`,
                    "x-refresh-token": refreshToken
                }
            });
            

           return { data: res.data}
            
        } catch(e:any) {
            console.log({e});
            
           throw e.response.data;
            
        }
    }
  async createPassenger({ input }: { input: Passenger }) {
    try {

      const res = await socketapi.post(`/api/passenger/register`,
        {
          ...input
        },
        {
          withCredentials: true,
          headers: {
            "authorization": localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
            "x-api-key":
              "asdf233mdslfm23mdlkmlskd",
            "Content-Type": "application/json",
          },
        }
      );


      return { data: res.data.data }

    } catch (e: any) {

      throw e.response.data ?? { error: e.message, statusCode: 400 };

    }
  }
  async sndSMS({ input }: { input: {} }) {
    try {

      const res = await api.post(`/api/dashboard/passengers/send-sms`,
        {
        ...input
        },
        {
          withCredentials: true,
          headers: {
            "authorization": localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
            "Content-Type": "application/json",
          },
        }
      );


      return { data: res.data.data }

    } catch (e: any) {

      throw e.response.data ?? { error: e.message, statusCode: 400 };

    }
  }

}