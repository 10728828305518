import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { useChannel } from './channel.context';
import { Channel } from '../../entities/channel';
import ChannelTable from './components/Private-trip-channels/channelsTable';
import { ChannelsRow } from './components/Private-trip-channels/types/channel';
import { Link } from 'react-router-dom';

type ChannelsColumn = {
  name: any,
  selector: any,

}


export default function Channels() {
    const {channels}= useChannel();

  const [data, setData] = useState<ChannelsRow[]>([]);
    useEffect(() => {
      const sampleData: ChannelsRow[] = []
      channels?.map(channel=> {
          sampleData.push({
            uuid: channel.uuid,
            name: channel.name,
            openPrice: channel.openPrice,
            pricePerKm: channel.pricePerKm,
            statuscode: channel.statuscode,
            Status: channel.Status,
            code: channel.code
            
          })
      })
      
      setData(sampleData)
      return () => {
        
      }
    }, [channels])
    
    const columns : ChannelsColumn[] = [
      {
          name: 'اسم القناة',
          selector: (row: { name: any; }) => row.name,
      },
      {
          name: 'سعر فتح المشوار',
          selector: (row: { openPrice: any; }) => row.openPrice,
      },
      {
          name: 'سعر الكيلو',
          selector:  (row: Channel) => row.pricePerKm,
      },
      {
          name: ' الحالة',
          selector: (row: Channel) => row?.Status?.name,
      },
  ];


    return (

      <>
         <div className="app-title">
        <div>
          <h1><i className="fa fa-th-list"></i> قنوات الرحلة الخاصة</h1>
        </div>

      </div>
      
      <Link to={"/add-channel"} >
      <div className="btn btn-primary">أضافة قناة</div>

      </Link>

      <br />
      <br /> 

      < ChannelTable columns={columns} data={data} />


      </>
    
    )
    
 
}
