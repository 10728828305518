import axios from "axios";
import React, { createContext, ReactNode, useContext, useState } from "react";
import { useEffect } from "react";
import { BASE_URL } from "../../../../core/common";
import { ProvidersProps } from "../../../../core/types";
import { Passenger } from "../../../entities/Passenger";
import { PassengerRepository } from "../Repository/passenger.repository";




type PassengersContextType = {

    loading?: false,
    setLoading?: Function | null,
    passengers?: Passenger[] | undefined | null,
    setPassengers?: Function | null
    getPrivateTrips?: Function

      createPassengerloading?: boolean,
      setCreatePassengerLoading?: Function
    createPassenger?: Function
      createdPassenger?: Passenger | null
      addedPassenger?: Passenger | null 
      sndSMS?: Function
}
const PassengerContext = createContext<PassengersContextType>({
    passengers: null,
    setPassengers: null,
    loading: false,
    setLoading: null,
    getPrivateTrips: Function,


    createPassengerloading: false,
    setCreatePassengerLoading: Function,
    createPassenger: Function,
    createdPassenger: null,
    addedPassenger:null ,

    sndSMS: Function
});
const passengerRepository:PassengerRepository = new PassengerRepository();

export const  PassengerProvider : React.FunctionComponent<ProvidersProps>= ({ children }) =>   {
  const [passengers, setPassengers] = React.useState<Passenger[] | null>();

  // create passenger
  const [createPassengerloading, setCreatePassengerLoading] = React.useState<boolean>(false);
  const [createdPassenger, setcreatedPassenger] = React.useState<Passenger | null>(null);
  const [addedPassenger, setAddedPassenger] = React.useState<Passenger | null>(null);


  async function getPassengers() {
    const res = await axios.get(`${BASE_URL}/api/dashboard/passengers/all`, {
      // withCredentials: true,
      headers: {
        "x-api-key": "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
        "Content-Type": "application/json",
      },
    });
    
    setPassengers(res.data.data);
    
    
  }

  useEffect(() => {
    getPassengers();
    return () => {};
  }, []);


  const createPassenger = async (input:Passenger) => {
    try {
      setCreatePassengerLoading(true)
      const {data} =  await passengerRepository.createPassenger({input: input});

      console.log({data});
      
      if(data) {
        
      await getPassengers();

      return {trip: data}
      }
      setCreatePassengerLoading(false)
    } catch(e:any) {
        console.log({e});
        setCreatePassengerLoading(false)
    }
}
  const sndSMS = async (input:{}) => {
    try {
 
      
      setCreatePassengerLoading(true)
      const {data} =  await passengerRepository.sndSMS({input: {...input}});

      console.log({data});
      
      setCreatePassengerLoading(false)
    } catch(e:any) {
        console.log({e});
        setCreatePassengerLoading(false)
    }
}

  async function getPrivateTrips(passengeruuid: string) {
    try {
      const response = await passengerRepository.getPirvateTrips({
        passengeruuid,
        accessToken:
        localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
      refreshToken:
        localStorage.getItem("share-taxi-dashboard-refresh-token") ?? "",
      });

      console.log({response});
      
    } catch(e:any) {
      console.log({
        e
      });
      
    }
  }

  return (
    <PassengerContext.Provider value={{ passengers,createPassenger,createPassengerloading,
      createdPassenger,
      sndSMS,
      addedPassenger,setPassengers, getPrivateTrips}} >
      {children}
    </PassengerContext.Provider>
  );
}

export const usePassengers = () => useContext(PassengerContext);
