import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { Trip } from "../../../entities/trip";
import { usePrivateTrip } from "../private-trip.context";
import { TripsColumn, TripsRow } from "../Types/Trip";

export default function TripsTable({
  columns,
  data,
}: {
  columns: TripsColumn[];
  data: TripsRow[];
}) {
  // modal

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {getAPrivateTrip, privateTriploading} = usePrivateTrip();
  const [selectedTrip, setSelectedTrip] = useState<Trip>({});
  function openTrip(row) {
    // getAPrivateTrip?.({uuid:row.uuid})
    setSelectedTrip({ ...row });
    handleShow();
  }
  if(privateTriploading === true) {
    return (
      <div className="card">
        <div className="card-body">
          <h1>loading</h1>
        </div>
      </div>
    )
  }
  return (
    <>
      <DataTable
      pagination
      paginationPerPage={200}
        columns={columns}
        data={data}
        selectableRows
        onRowClicked={(row) => openTrip(row)}
      />
  
      <div>
         <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
      <Modal.Header closeButton>
          <Modal.Title>تفاصيل الرحلة</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex-col justify-content-between ">

            <div className="d-flex justify-content-between my-3">
              <span> الاسم الراكب بالكامل </span>
              <span>
                {selectedTrip?.passenger?.firstname ?? "--"} {selectedTrip?.passenger?.lastname ?? "--"}
              </span>
            </div>

            <div className="d-flex justify-content-between my-3">
              <span> رقم الهاتف </span>
              <span>{selectedTrip?.passenger?.phoneNumber}</span>
            </div>

            <div className="d-flex justify-content-between my-3">
              <span> نقطة البداية </span>
              <span> {selectedTrip?.startLocation?.name} </span>
               </div>

            <div className="d-flex justify-content-between my-3">
              <span> نقطة النهاية </span>
              <span> {selectedTrip?.endLocation?.name}</span>
               </div>

            <div className="d-flex justify-content-between my-3">
              <span> المسافة </span>
              <span> {selectedTrip?.distance} Km</span>
               </div>

            <div className="d-flex justify-content-between my-3">
              <span> نوع الرحلة </span>
              <span> {selectedTrip?.channel?.name}</span>
               </div>

            <div className="d-flex justify-content-between my-3">
              <span> سعر الكيلو </span>
              <span> {selectedTrip?.channel?.pricePerKm}</span>
               </div>

            <div className="d-flex justify-content-between my-3">
              <span> الحالة </span>
              <span> {selectedTrip?.tripStatus?.name} </span>
               </div>

            <div className="d-flex justify-content-between my-3">
              <span> note </span>
              <span> {selectedTrip?.note} </span>
               </div>
            <div className="d-flex justify-content-between my-3">
              <span> أنشأت </span>
              <span> {selectedTrip?.createdAt?.toString()} </span>
               </div>

            <div className="d-flex justify-content-between my-3">
              <span> التكلفة الكلية </span>
              <span> {selectedTrip?.totalAmount} جنيه </span>
               </div>
         

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            غلق
          </Button>
          <Link to={`/trip/${selectedTrip.uuid}`}>
            <Button variant="primary">كل التفاصيل</Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
    </>
  );
}
