import axios from "axios";
import React, { createContext, ReactNode, useContext, useState } from "react";
import { useEffect } from "react";
import { BASE_URL } from "../../../../core/common";
import { ProvidersProps } from "../../../../core/types";
import { ServiceCenter } from "../../../entities/ServiceCenter";
import {  ServiceCenterRepository } from "../Repository/Service-Center.repository";

type ServiceCenterContextType = {
  loading?: boolean;
  setLoading?: Function | null;
  addServiceCenter:Function,
  activateOrdeactivateServiceCenter:Function,
  updateServiceCenter:Function,
  servicesCenters?: ServiceCenter[] | null
};
const ServiceCenterContext = createContext<ServiceCenterContextType>({
  loading: true,
  addServiceCenter:Function,
  activateOrdeactivateServiceCenter:Function,
  updateServiceCenter:Function,
  servicesCenters: []

});

const serviceCenterRepository:ServiceCenterRepository = new ServiceCenterRepository();
export const ServiceCenterProvider: React.FunctionComponent<ProvidersProps> = ({
  children,
}) => {
  
  const [loading, setLoading] = useState(false);

  const [servicesCenters, setservicesCenters] = useState<ServiceCenter[] | null>();

    const getServiceCenters = async () => {
        try {
          setLoading(true)
          const {data} =  await serviceCenterRepository.getServiceCenters();

          
          if(data) {
          setservicesCenters(data)
          }
          setLoading(false)
        } catch(e:any) {
            console.log({e});
            setLoading(false)
        }
    }
    const addServiceCenter = async (input:ServiceCenter) => {
        try {
          setLoading(true)
          const {data} =  await serviceCenterRepository.addServiceCenter({input: input});

          console.log({data});
          
          if(data) {
            getServiceCenters()
          }
          setLoading(false)
        } catch(e:any) {
            console.log({e});
            setLoading(false)
        }
    }


  
    
    const updateServiceCenter = async (uuid:string, input: ServiceCenter) => {

      try {
        setLoading(true)
        const {data} =  await serviceCenterRepository.updateServiceCenter({uuid:uuid, input: input});

        console.log({data});
        
        if(data) {
          getServiceCenters()
        }
        setLoading(false)
      } catch(e:any) {
          console.log({e});
          setLoading(false)
      }
    }
  

    const activateOrdeactivateServiceCenter = async (uuid:string) => {

      try {
        setLoading(true)
        const {data} =  await serviceCenterRepository.activateOrdeactivateServiceCenter({uuid:uuid});

        console.log({data});
        
        if(data) {
          getServiceCenters()
        }
        setLoading(false)
      } catch(e:any) {
          console.log({e});
          setLoading(false)
      }
    }


    useEffect(() => {
      getServiceCenters()
      return () => {
        
      }
    }, [])
    

  return <ServiceCenterContext.Provider value={{
    loading,
    addServiceCenter, 
    servicesCenters,
    activateOrdeactivateServiceCenter,
    updateServiceCenter,
  
  }}>{children}</ServiceCenterContext.Provider>;
};

export const useServiceCenter = () => useContext(ServiceCenterContext);
