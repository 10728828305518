import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Passenger } from "../../entities/Passenger";
import { useErrors } from "../Common-components/Error/error.context";
import Loader from "../Loader";
import { usePassengers } from "../passenger/context/PassengerContext";
import { useSearch } from "../Search/context/search.context";

export default function CallCenterPage() {
  const navigate = useNavigate();

  const { setErrors } = useErrors();
  const [number, setnumber] = useState<string | null>(null);

  const { createPassengerloading, createPassenger, sndSMS } = usePassengers();

  const [newPassenger, setNewPassenger] = useState<Passenger>({});
  function changer(field) {
    setNewPassenger({ ...newPassenger, ...field });
  }

  const [smsText, setsmsText] = useState("");
  function smschanger(text) {
    setsmsText(text)
  }
  const [phoneNumber, setphoneNumber] = useState(0);
  function pnchanger(text) {
    setphoneNumber(text)
  }
  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //2 modal
  const [showSec, setShowSec] = useState(false);
  const handleCloseSec = () => setShowSec(false);
  const handleShowSec = () => setShowSec(true);

  //3 modal
  const [showthird, setShowthird] = useState(false);
  const handleClosethird = () => setShowthird(false);
  const handleShowthird = () => setShowthird(true);

  // search
  const [searchedPassengers, setSearchedPassenger] = useState<[] | null>(null);
  const { searchPassengers } = useSearch();
  async function searchHandler(e) {
    if (e.target.value) {
      const data = await searchPassengers?.({ text: e.target.value });
      const passengers: [] = data || [];

      setSearchedPassenger([...passengers]);
    } else {
      setSearchedPassenger([]);
    }
  }

  useEffect(() => {
    return () => {};
  }, [number]);

  return (
    <>
      <div className="app-title">
        <div>
          <h1>
            <i className="fa fa-dashboard"></i> .شاشه الكول سنتر
          </h1>
          <div></div>
        </div>
        <ul className="app-breadcrumb breadcrumb">
          <li className="breadcrumb-item">
            <i className="fa fa-home fa-lg"></i>
          </li>
          <li className="breadcrumb-item">
            <a href="#"> .شاشه الكول سنتر</a>
          </li>
        </ul>
      </div>
      <div className="card">
        <div className="card-body">
          <button onClick={() => handleShow()} className="my-2 btn btn-primary">
            إنشاء رحلة
          </button>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <button onClick={() => handleShowthird()} className="my-2 btn btn-primary">
             ارسال sms
          </button>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title> انشاء رحلة</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" my-3">
            <div className="d-flex justify-content-between">
              <span className="ml-3"> رقم الهاتف </span>

              <div>
                <input
                  className="form-control"
                  type="number"
                  name="search"
                  autoComplete="off"
                  placeholder="رقم الهاتف  "
                  id=""
                  onChange={(e) => {
                    searchHandler(e);
                    setnumber(e.target.value);
                    if (e.target.value.length === 10) {
                      setErrors((state: any) => []);
                    } else {
                      setErrors((state: any) => [
                        // ...state,
                        { message: "رقم الهاتف أقل من 10", code: 400 },
                      ]);
                    }
                  }}
                />
                <div className="position-relative">
                  <div
                    className="card position-absolute"
                    style={{ zIndex: 10000000 }}
                  >
                    <ul
                      className="list-group  list-group-flush"
                      style={{ padding: "0" }}
                    >
                      {searchedPassengers?.length === 0 ? (
                        <li className="list-group-item">
                          {number!.length > 9 ? (
                            // <Link to={`/passenger/create/${number}`}>
                            <div className="d-flex flex-row">
                              <button
                                onClick={() => {
                                  // changer({})
                                  handleClose();
                                  handleShowSec();
                                }}
                                className="my-2 btn btn-primary"
                              >
                                إنشاء حساب راكب جديد
                              </button>
                            </div>
                          ) : (
                            // </Link>
                            <div className="d-flex flex-row">
                              <button
                                // onClick={() => handleShow()}
                                className="my-2 btn btn-secondary"
                              >
                                إنشاء حساب راكب جديد
                              </button>
                            </div>
                          )}
                        </li>
                      ) : (
                        searchedPassengers?.map((passenger, index) => {
                          return (
                            <li key={index} className="list-group-item">
                              <Link
                                to={`/passenger/create-trip/${passenger["phoneNumber"]}`}
                              >
                                <div className="d-flex flex-row">
                                  {" "}
                                  <span>
                                    {passenger["firstname"]}{" "}
                                    {passenger["lastname"]}{" "}
                                  </span>
                                </div>
                                <div className="d-flex">
                                  <p>الهاتف : </p>

                                  <p className="text-secondary">
                                    {" "}
                                    {passenger["phoneNumber"]}{" "}
                                  </p>
                                </div>
                              </Link>
                            </li>
                          );
                        })
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={showSec}
        onHide={handleCloseSec}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title> بيانات الراكب الاساسية</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {createPassengerloading === false ? (
            <div className=" my-3">
              <div className="d-flex justify-content-between">
                <span className="ml-3"> رقم الهاتف </span>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    name="phoneNumber"
                    autoComplete="off"
                    disabled
                    placeholder={number?.toString()}
                    id=""
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between my-3">
                <span className="ml-3"> الاسم الاول </span>
                <div>
                  <input
                    className="form-control"
                    type="text"
                    name="firstname"
                    autoComplete="off"
                    placeholder=" الاسم الاول  "
                    id=""
                    onChange={(e) => {
                      changer({ firstname: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <span className="ml-3"> الاسم الثاني</span>
                <div>
                  <input
                    className="form-control"
                    type="text"
                    name="secondname"
                    autoComplete="off"
                    placeholder=" الاسم الثاني  "
                    id=""
                    onChange={(e) => {
                      changer({ lastname: e.target.value });
                    }}
                  />
                </div>
              </div>
              <button
                onClick={async () => {
                  await createPassenger?.({
                    ...newPassenger,
                    phoneNumber: number?.toString(),
                    password: "123456",
                  });

                  navigate(`/passenger/create-trip/249${number?.substring(1,11)}`);
                }}
                className="my-2 btn btn-primary"
              >
                إنشاء حساب
              </button>
            </div>
          ) : (
            <Loader />
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={showthird}
        onHide={handleClosethird}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title> بيانات الراكب الاساسية</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {createPassengerloading === false ? (
            <div className=" my-3">
              <div className="flex justify-content-between">
                <span className="ml-3">   phone Number</span>
                <div>
                  <input
                    className="form-control my-3"
                    type="number"
                    name="secondname"
                    autoComplete="off"
                    placeholder="  phone number  "
                    id=""
                    onChange={(e) => {
                      pnchanger( e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-content-between">
                <span className="ml-3">  نص الرسالة</span>
                <div>
                  <input
                    className="form-control my-3"
                    type="text"
                    name="secondname"
                    autoComplete="off"
                    placeholder="  النص  "
                    id=""
                    onChange={(e) => {
                      smschanger( e.target.value);
                    }}
                  />
                </div>
              </div>
              <button
                onClick={async () => {
                  sndSMS?.({input: {sms:smsText, phoneNumber}})

                  // navigate(`/passenger/create-trip/249${number?.substring(1,11)}`);
                  handleClosethird()
                }}
                className="my-2 btn btn-primary"
              >
                 إرسال
              </button>
            </div>
          ) : (
            <Loader />
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}
