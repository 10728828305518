import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ServiceCenter } from "../../entities/ServiceCenter";
import PickupLocationMap from "../Map/pickup-location";
import ServiceCenterPickupLocationMap from "./components/pick-up-location-map";
import { useServiceCenter } from "./context/service-center.context";

export default function EditServiceCenter() {
    const { uuid } = useParams();
  const {updateServiceCenter, servicesCenters} = useServiceCenter();
  let navigate = useNavigate();
  const [serviceCenter, setServiceCenter] = React.useState<ServiceCenter | null>({

  });
  function changer(value) {
    setServiceCenter({
      ...value
    })
  }

 async function handlerSubmit() {
  console.log({
    serviceCenter
  });
  
    if(serviceCenter?.name && serviceCenter?.description && serviceCenter?.latitude && serviceCenter?.longitude  ) {
      
        
     await updateServiceCenter(
        serviceCenter.uuid,
        {
          ...serviceCenter
        }
      )
      return navigate("/service-center"); 
   
        
    }
  }

  React.useEffect(() => {
    setServiceCenter({ ...servicesCenters?.find((d) => d.uuid === uuid) });
    return () => {};
  }, []);

 
  
  return (
    <div className="px-3 mt-5">
      <div className="card  my-3 px-3 ">
        <div className="pt-3">
          <h3> أضافة مركز </h3>
        </div>
        <div className="card-body ">
          <form onSubmit={(e) => e.preventDefault()} action="#">
            <div>
              <label htmlFor="amount"> اسم المركز </label>
              <input
                className="form-control"
                type="text"
                name="name"
                id=""
                value={serviceCenter?.name}
                onChange={(e) => changer({...serviceCenter, name: e.target.value })}
              />
            </div>
            <div>
              <label htmlFor="amount">  الوصف </label>
              <input
                className="form-control"
                type="text"
                name="description"
                id=""
                value={serviceCenter?.description}
                onChange={(e) => changer({...serviceCenter, description: e.target.value })}
              />
            </div>

            <div className="my-3">
            <ServiceCenterPickupLocationMap locationSetter={({latitude, longitude}) => {
                                changer({
                                  ...serviceCenter,
                                  latitude: latitude,
                                  longitude:longitude,
                                })
             
            }} />

            </div>
            <button
              onClick={e => handlerSubmit()}
              className="my-3 btn btn-primary"
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
