import { api } from "../../../../core";

export class AdminRepository {

    async me({accessToken, refreshToken}: {accessToken: string, refreshToken: string}) {
        try {

            const res = await api.get('api/dashboard/admin/me', {
                withCredentials: true,
                headers: {
                    authorization: `Bearer ${accessToken}`,
                    "x-refresh-token": refreshToken
                }
            });
            

           return { data: res.data}
            
        } catch(e:any) {
            // console.log({error: e.response.data.data});
            
           throw e.response.data !== undefined ?  {message: e.response.data.data.error, statusCode: e.response.data.data.statusCode ?? 400 }: {message:e.message, statusCode: 500};
            
        }
    }
}