import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useAdmin } from "./Admins/Context/admin.context";
import ProtectedComponentsWrapper from "./Protected-components-wrapper";

export default function SideBar() {
  const { admin } = useAdmin();
  const [show, setShow] = useState({
    dashboard_info: false,
    admins: false,
    privateTrip: false,
    sharedTrip: false,
    settings: false,

  });

  function openNav(data) {
    setShow({
      ...data,
    });
  }

  useEffect(() => {
    return () => {};
  }, [show, admin]);
  if (!admin || admin === null) {
    //Not signed in
    return <Navigate to="/login" />;
  }
  return (
    <div>
      {/* <!-- Sidebar menu--> */}
      <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
      <aside className="app-sidebar">
        <Link to="/user">
          <div className="app-sidebar__user d-flex  justify-content-center">
            <div className="d-flex flex-column  align-items-center justify-items-center">
              <i className="fa fa-user mb-3"></i>
              <p className="app-sidebar__user-name mb-2"> {admin?.name} </p>
              <p className="app-sidebar__user-designation">
                {admin?.phoneNumber}{" "}
              </p>
            </div>
          </div>
        </Link>
        <ul className="app-menu">






          <li
            onClick={() => openNav({ ...show, dashboard_info: !show.dashboard_info })}
            className={show.dashboard_info ? "treeview is-expanded" : "treeview"}
          >
            <a className="app-menu__item" href="#" data-toggle="treeview">
            <i className="app-menu__icon fa fa-dashboard"></i>
              <span className="app-menu__label">لوحة المعلومات</span>
              <i className="treeview-indicator fa fa-angle-right"></i>
            </a>
            <ul className="treeview-menu">
              <Link to="/">
                <li>
                  <div className="treeview-item">
                    <i className="icon fa fa-circle-o"></i> شاشة التشغيل
                  </div>
                </li>
              </Link>

              <Link to="/live-data">
                <li>
                  <div className="treeview-item">
                    <i className="icon fa fa-circle-o"></i>  بيانات جارية
                  </div>
                </li>
              </Link>
              <Link to="/call-center">
                <li>
                  <div className="treeview-item">
                    <i className="icon fa fa-circle-o"></i>   شاشة الكول سنتر
                  </div>
                </li>
              </Link>
            </ul>
          </li>



          <li
            onClick={() => openNav({ ...show, admins: !show.admins })}
            className={show.admins ? "treeview is-expanded" : "treeview"}
          >
            <a className="app-menu__item" href="#" data-toggle="treeview">
              <i className="app-menu__icon fa fa-laptop"></i>
              <span className="app-menu__label">المسؤولين </span>
              <i className="treeview-indicator fa fa-angle-right"></i>
            </a>
            <ul className="treeview-menu">
              <ProtectedComponentsWrapper
                admin={admin ?? {}}
                allow={[111, 222]}
              >
                <Link to={"/create-admin"}>
                  <li>
                    <div className="treeview-item">
                      <i className="icon fa fa-circle-o"></i> إضافة مسؤول
                    </div>
                  </li>
                </Link>
              </ProtectedComponentsWrapper>

              <Link to={"/all-admins"}>
                <li>
                  <div className="treeview-item">
                    <i className="icon fa fa-circle-o"></i> كل المسؤولين
                  </div>
                </li>
              </Link>
            </ul>
          </li>

          <li
            onClick={() => openNav({ ...show, privateTrip: !show.privateTrip })}
            className={show.privateTrip ? "treeview is-expanded" : "treeview"}
          >
            <a className="app-menu__item" href="#" data-toggle="treeview">
              <i className="app-menu__icon fa fa-laptop"></i>
              <span className="app-menu__label">الرحلة المفتوحة</span>
              <i className="treeview-indicator fa fa-angle-right"></i>
            </a>
            <ul className="treeview-menu">
              <Link to={"/ongoing-private-trip"}>
                <li>
                  <div className="treeview-item">
                    <i className="icon fa fa-circle-o"></i> الحالية
                  </div>
                </li>
              </Link>
              <Link to={"/all-private-trip"}>
                <li>
                  <div className="treeview-item">
                    <i className="icon fa fa-circle-o"></i> كل الرحلات
                  </div>
                </li>
              </Link>
            </ul>
          </li>

          <li
            onClick={() => openNav({ ...show, sharedTrip: !show.sharedTrip })}
            className={show.sharedTrip ? "treeview is-expanded" : "treeview"}
          >
            <a className="app-menu__item" href="#" data-toggle="treeview">
              <i className="app-menu__icon fa fa-laptop"></i>
              <span className="app-menu__label">الرحلة المشتركة</span>
              <i className="treeview-indicator fa fa-angle-right"></i>
            </a>
            <ul className="treeview-menu">
              <Link to={"/ongoing-shared-trips"}>
                <li>
                  <div className="treeview-item">
                    <i className="icon fa fa-circle-o"></i> الحالية
                  </div>
                </li>
              </Link>

              <Link to={"/all-shared-trips"}>
                <li>
                  <div className="treeview-item">
                    <i className="icon fa fa-circle-o"></i> كل الرحلات
                  </div>
                </li>
              </Link>
            </ul>
          </li>

          <Link to={"/drivers"}>
            <li>
              <div className="app-menu__item">
                <i className="app-menu__icon fa fa-dashboard"></i>
                <span className="app-menu__label">السائقين</span>
              </div>
            </li>
          </Link>

          <Link to={"/passengers"}>
            <li>
              <div className="app-menu__item">
                <i className="app-menu__icon fa fa-dashboard"></i>
                <span className="app-menu__label">الركاب</span>
              </div>
            </li>
          </Link>
          <Link to={"/service-center"}>
            <li>
              <div className="app-menu__item">
                <i className="app-menu__icon fa fa-dashboard"></i>
                <span className="app-menu__label">مراكز الخدمات</span>
              </div>
            </li>
          </Link>

          <li
            onClick={() => openNav({ ...show, settings: !show.settings })}
            className={show.settings ? "treeview is-expanded" : "treeview"}
          >
            <a className="app-menu__item" href="#" data-toggle="treeview">
              <i className="app-menu__icon fa fa-laptop"></i>
              <span className="app-menu__label">الأعدادات</span>
              <i className="treeview-indicator fa fa-angle-right"></i>
            </a>
            <ul className="treeview-menu">
              <Link to="/trip-channels">
                <li>
                  <div className="treeview-item">
                    <i className="icon fa fa-circle-o"></i> قنوات الرحلة
                    المفتوحة
                  </div>
                </li>
              </Link>

              <Link to="/shared-trip-channels">
                <li>
                  <div className="treeview-item">
                    <i className="icon fa fa-circle-o"></i> قنوات الرحلة
                    المشتركة
                  </div>
                </li>
              </Link>

              <Link to="/apks">
                <li>
                  <div className="treeview-item">
                    <i className="icon fa fa-circle-o"></i>
                    تحديثات التطبيقات
                  </div>
                </li>
              </Link>
            </ul>
          </li>
        </ul>
      </aside>
    </div>
  );
}
