import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Admin } from '../../../../entities/Admin';
import { useAdmin } from '../../Context/admin.context';

export default function EditAdmins() {
    const {admins, updateOtherAdmins} = useAdmin();
    const { uuid } = useParams();
    let navigate = useNavigate();
    
    const roles = [
        // {
        //     name: "super admin",
        //     code: 111
        // },
        {
            name: "admin",
            code: 222
        },
        {
            name: "tech support ",
            code: 333
        }
    ]
  const [updatedAdmin, setUpdatedAdmin] = React.useState<Admin | null>({
  
  });
  function changer(value) {
    setUpdatedAdmin({
      ...value
    })
  }
  
  async function handlerSubmit() {
    
   await updateOtherAdmins?.({
      ...updatedAdmin
    })
    
    navigate("/all-admins")
  
  }
  
  React.useEffect(() => {
    setUpdatedAdmin({ ...admins?.find((d) => d.uuid === uuid) });
   
    return () => {
      
    }
  }, [])
  
  return (
   
<div className="px-3 mt-5">
    <div className="card  my-3 px-3 ">
      <div className="pt-3">
        <h3> تعديل البيانات </h3>
      </div>
      <div className="card-body ">
        <form onSubmit={(e) => e.preventDefault()} action="#">
       
        
       
          <div className="form-group">
                    <label htmlFor="exampleSelect1"> الصلاحية </label>
                    <select  value={updatedAdmin?.rolecode}    onChange={(e) => changer({...updatedAdmin, rolecode: Number(e.target.value) })} className="form-control" id="exampleSelect1">
                        {
                           roles.map(number => {
                             return   <option key={number.code} value={number.code}>{number.name}</option>
                            })
                        }
                
                     
                    </select>
                  </div>

          <button
            onClick={e => handlerSubmit()}
            className="my-3 btn btn-primary"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
  )
}
