import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PHOTOS_BASE_URL } from "../../../../core/common";
import { Trip } from "../../../entities/trip";
import { usePrivateTrip } from "../private-trip.context";

export default function SingleTrip() {
  const { uuid } = useParams();
  const { selectedPrivateTrip, privateTriploading, getAPrivateTrip } =
    usePrivateTrip();

  useEffect(() => {
    getAPrivateTrip?.({ uuid });
    return () => {};
  }, []);



  if (privateTriploading === true) {
    return (
      <div className="card">
        <div className="card-body">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <h3> بيانات الرحلة الخاصة </h3>
        </div>
      
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th scope="col">بيانات الرحلة </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>الاسم الراكب</td>
              <td> {selectedPrivateTrip?.passenger?.firstname} </td>
            </tr>
            <tr>
              <td>الاسم السائق</td>
              <td> {selectedPrivateTrip?.driver?.firstname} </td>
            </tr>

            <tr>
              <td> نقطة البداية </td>
              <td> {selectedPrivateTrip?.startLocation?.name} </td>
            </tr>

            <tr>
              <td> نقطة النهاية </td>
              <td> {selectedPrivateTrip?.endLocation?.name}</td>
            </tr>

            <tr>
              <td> المسافة </td>
              <td> {selectedPrivateTrip?.distance} Km</td>
            </tr>

            <tr>
              <td> نوع الرحلة </td>
              <td> {selectedPrivateTrip?.channel?.name}</td>
            </tr>

            <tr>
              <td> سعر الكيلو </td>
              <td> {selectedPrivateTrip?.channel?.pricePerKm}</td>
            </tr>

            <tr>
              <td> الحالة </td>
              <td> {selectedPrivateTrip?.tripStatus?.name} </td>
            </tr>

            <tr>
              <td> note </td>
              <td> {selectedPrivateTrip?.note} </td>
            </tr>
            <tr>
              <td> أنشأت </td>
              <td> {selectedPrivateTrip?.createdAt?.toString()} </td>
            </tr>

            <tr>
              <td> التكلفة الكلية </td>
              <td> {selectedPrivateTrip?.totalAmount} جنيه </td>
            </tr>
          </tbody>
        </table>

        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th scope="col">بيانات الراكب</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> الاسم بالكامل</td>
              <td>
                {" "}
                {selectedPrivateTrip?.passenger?.firstname}{" "}
                {selectedPrivateTrip?.passenger?.lastname}{" "}
              </td>
            </tr>
            <tr>
              <td> رقم الهاتف </td>
              <td> {selectedPrivateTrip?.passenger?.phoneNumber} </td>
            </tr>

            <tr>
              <td>تاريخ الإنضمام </td>
              <td> {selectedPrivateTrip?.passenger?.createdAt} </td>
            </tr>

            <tr>
              <td> حالة الحساب </td>
              <td> {selectedPrivateTrip?.passenger?.Status?.name} </td>
            </tr>
          </tbody>
        </table>

        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th scope="col">بيانات السائق</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> الاسم بالكامل</td>
              <td>
                {" "}
                {selectedPrivateTrip?.driver?.firstname}{" "}
                {selectedPrivateTrip?.driver?.lastname}{" "}
              </td>
            </tr>
            <tr>
              <td> رقم الهاتف </td>
              <td> {selectedPrivateTrip?.driver?.phoneNumber} </td>
            </tr>

            <tr>
              <td>تاريخ الإنضمام </td>
              <td> {selectedPrivateTrip?.driver?.createdAt} </td>
            </tr>

            <tr>
              <td> حالة الحساب </td>
              <td> {selectedPrivateTrip?.driver?.Status?.name} </td>
            </tr>
          </tbody>
        </table>
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th scope="col">بيانات السيارة</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> الشركة</td>
              <td> {selectedPrivateTrip?.car?.brand}</td>
            </tr>
            <tr>
              <td> اسم الموديل </td>
              <td> {selectedPrivateTrip?.car?.model} </td>
            </tr>
            <tr>
              <td> رقم اللوحة </td>
              <td> {selectedPrivateTrip?.car?.licensePlate} </td>
            </tr>
            <tr>
              <td> سنة الصنع </td>
              <td> {selectedPrivateTrip?.car?.year} </td>
            </tr>

            <tr>
              <td> صورة شهادة البحث </td>
              <td>
                <img
                  src={`${PHOTOS_BASE_URL}/${selectedPrivateTrip?.car?.licenseImage}`}
                  alt=""
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
