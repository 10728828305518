import React from "react";
import { Link } from "react-router-dom";
import { useAdmin } from "../../Context/admin.context";

export default function AdminProfile() {
  const { logoutAdmin, admin } = useAdmin();
  return (
    <div className="card">
      <div className="card-body ">
        <h3>الحساب الشخصي</h3>
      </div>
      <table className="table table-bordered">
        <thead className="thead-light">
          <tr>
            <th scope="col">البيانات</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>الاسم الكامل</td>
            <td> {admin?.name} </td>
          </tr>

          <tr>
            <td> رقم الهاتف</td>
            <td> {admin?.phoneNumber} </td>
          </tr>

          <tr>
            <td> نوع الحساب </td>
            <td> {admin?.rolecode}</td>
          </tr>
          <tr>
            <td> الحالة </td>
            <td> {admin?.statuscode}</td>
          </tr>
        </tbody>
      </table>
      <div className="d-flex  justify-content-between mx-3 my-3">
        <button onClick={() => logoutAdmin?.()} className="btn btn-danger">
          {" "}
          تسجيل خروج{" "}
        </button>
        <Link to={`/edit-admin/${admin?.uuid}` }>
          <button className="btn btn-info"> تعديل البيانات </button>
        </Link>
        <Link to={`/edit-admin-password/${admin?.uuid}` }>
          <button className="btn btn-info"> تغيير كلمة السر  </button>
        </Link>
      </div>
    </div>
  );
}
