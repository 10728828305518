import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { ServiceCenter } from '../../entities/ServiceCenter';
import { useServiceCenter } from './context/service-center.context';
import { ServicesCentersColumn, ServicesCentersRow } from './types/service-center';

export default function ServiceCenterTable({
  columns,
  data,
}: {
  columns: ServicesCentersColumn[];
  data: ServicesCentersRow[];
}) {
  const { servicesCenters, activateOrdeactivateServiceCenter} = useServiceCenter();
  // modal
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedCenter, setselectedCenter] = useState<ServiceCenter | null | undefined>({});
  function openDriver(row) {
    setselectedCenter({ ...row });
    handleShow();
  }

  useEffect(() => {
    setselectedCenter(servicesCenters?.find(ch => ch.uuid === selectedCenter?.uuid));
    return () => {};
  }, [servicesCenters]);

  return (
    <div>
         <DataTable
        columns={columns}
        data={data}
        onRowClicked={(row) => openDriver(row)}
        // selectableRows={true}
      />

<Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header closeButton>
          <Modal.Title>تفاصيل القناة</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex-col justify-content-between ">
            <div className="d-flex justify-content-between my-3">
              <span> اسم  </span>
              <span>{selectedCenter?.name}</span>
            </div>

            <div className="d-flex justify-content-between my-3">
              <span>   الوصف </span>
              <span>{selectedCenter?.description}</span>
            </div>

            <div className="d-flex justify-content-between my-3">
              <span>  latitude </span>
              <span>{selectedCenter?.latitude}</span>
            </div>
            <div className="d-flex justify-content-between my-3">
              <span>  longitude </span>
              <span>{selectedCenter?.longitude}</span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {selectedCenter?.statuscode === 111 ? (
            <button
              onClick={() => activateOrdeactivateServiceCenter?.(selectedCenter?.uuid)}
              className="my-3 btn btn-danger"
              type="submit"
            >
              وقف المركز
            </button>
          ) : (
            <button
              onClick={() => activateOrdeactivateServiceCenter?.(selectedCenter?.uuid)}
              className="my-3 btn btn-primary"
              type="submit"
            >
              تفعيل بيانات المركز
            </button>
          )}

          <Button variant="secondary" onClick={handleClose}>
            غلق
          </Button>
          <Link to={`/edit-service-center/${selectedCenter?.uuid}`}>
            <Button variant="warning"> تعديل </Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
