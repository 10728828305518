import axios from "axios";
import React, { createContext, ReactNode, useContext, useState } from "react";
import { useEffect } from "react";
import { BASE_URL } from "../../../../core/common";
import { ProvidersProps } from "../../../../core/types";
import { Admin } from "../../../entities/Admin";
import { ApkVersion } from "../../../entities/apk-version";

type ApkVersionsContextType = {
  loading?: false;
  setLoading?: Function | null;
  apkVersions?: ApkVersion[]  | undefined | null
  getApksVersions?: Function;
  updateApkVersion?: Function;
};
const ApkVersionsContext = createContext<ApkVersionsContextType>({
  loading: false,
  setLoading: null,
  apkVersions: null
,
  getApksVersions: Function,
  updateApkVersion: Function,

});

export const ApkVersionProvider: React.FunctionComponent<ProvidersProps> = ({
  children,
}) => {
  const [apkVersions, setApkVersions] = useState<ApkVersion[] | null | undefined>();

  const getApksVersions = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/api/dashboard/apk-version/all`,

        {
          withCredentials: true,
          headers: {
            authorization:
              localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
            "x-api-key":
              "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
            "Content-Type": "application/json",
          },
        }
      );

    
        
   

      if (res?.data?.data) {
            setApkVersions(res.data?.data)
        
      }
    } catch (e: any) {
      console.log({ e });
    }
  };

  const updateApkVersion = async (version:ApkVersion) => {
 
    
    try {
      const res = await axios.put(
        `${BASE_URL}/api/passenger/apk-version/latest/${version?.uuid}`,
        {
            newVersion: version.version,
        important: version.important
        },
        {
          withCredentials: true,
          headers: {
            authorization:
              localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
            "x-api-key":
              "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
            "Content-Type": "application/json",
          },
        }
      );

      if (res?.data?.data) {
       getApksVersions()
        
      }
    } catch (e: any) {
      console.log({ e });
    }
  };


  useEffect(() => {
    getApksVersions()
    return () => {};
  }, []);

 
  return (
    <ApkVersionsContext.Provider
      value={{ apkVersions,updateApkVersion }}
    >
      {children}
    </ApkVersionsContext.Provider>
  );
};

export const useApkVersion = () => useContext(ApkVersionsContext);
