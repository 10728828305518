/* eslint-disable no-throw-literal */
import { api } from "../../../../core";

export class SharedTripRepository {

   

    async getAllSharedtrips() {
        try {

            const res = await api.get(`api/dashboard/shared-trips/all`, {
            });
                
                    
           return [ ...res.data.data]
            
        } catch(e:any) {
            // console.log({error: e.response.data.data});
            
           throw e.response.data !== undefined ?  {message: e.response.data.data.error, statusCode: e.response.data.data.statusCode }: {message:e.message, statusCode: 500};
            
        }
    }

    async getASharedtrip({uuid}: {uuid: string}) {
        try {

            const res = await api.get(`api/dashboard/shared-trips/find/${uuid}`, {
            });
                  
                    
           return { ...res.data.data}
            
        } catch(e:any) {
            // console.log({error: e.response.data.data});
            
           throw e.response.data !== undefined ?  {message: e.response.data.data.error, statusCode: e.response.data.data.statusCode }: {message:e.message, statusCode: 500};
            
        }
    }
}




  
  
  