import io from "socket.io-client";

//local
export const BASE_URL = process.env.REACT_APP_BACKEND_SERVICES_URI;
export const PHOTOS_BASE_URL = process.env.REACT_APP_PHOTOS_SERVICES_URI;
export const SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_SERVICE ?? "";

export const socket = io(SOCKET_BASE_URL);
// export const SEARCH_BASE_URL = process.env.SEARCH_BASE_URL;

// prod
// export const BASE_URL = "http://api.sharetaxisd.com:2021";
// export const PHOTOS_BASE_URL = "http://dashboard.sharetaxisd.com";

// dev
// export const PHOTOS_BASE_URL = "http://devdashboard.sharetaxisd.com";
// export const BASE_URL = "http://devapi.sharetaxisd.com:2021";
