import React, { useEffect } from "react";
import { useDrivers } from "./Drivers/context/DriversContext";
import { usePrivateTrip } from "./Priavate-Trip/private-trip.context";

import { Chart, registerables } from "chart.js";
import { getRelativePosition } from "chart.js/helpers";
import { usePassengers } from "./passenger/context/PassengerContext";
import LiveDataMap from "./Map/google-map";
import { useLiveData } from "./LiveData/live-data.context";
import ShowTwoLocations from "./Map/small-maps/show-two-locations";
import { Trip } from "../entities/trip";

export default function Main() {
  const [seletedTrip, setseletedTrip] = React.useState<Trip | null>(null);

  const { passengers } = usePassengers();
  const { liveDriversWithLocation, currentliveSharedTrips, currentliveTrips } =
    useLiveData();

  useEffect(() => {
    return () => {};
  }, [liveDriversWithLocation, currentliveTrips, seletedTrip]);


  Chart.register(...registerables);
  const { drivers } = useDrivers();
  const { allPrivateTrip } = usePrivateTrip();
  return (
    <div>
      <div className="app-title">
        <div>
          <h1>
            <i className="fa fa-dashboard"></i> شاشة التشغيل
          </h1>
        </div>
        <ul className="app-breadcrumb breadcrumb">
          <li className="breadcrumb-item">
            <i className="fa fa-home fa-lg"></i>
          </li>
          <li className="breadcrumb-item">
            <a href="#"> شاشة التشغيل</a>
          </li>
        </ul>
      </div>

      {/* <!-- Row 1 --> */}
      <h2>بيانات جارية الآن (Live)</h2>
      <div className="row">
        <div className="col-md-6 col-lg-3">
          <div className="widget-small primary coloured-icon d-flex justify-content-between">
            <i className="icon fa fa-users fa-3x"></i>
            <div className="info ">
              <h4> السائقين النشطين</h4>
            </div>
            <div className="d-flex  align-items-center mx-2">
              <b className="h4"> {liveDriversWithLocation?.length}</b>
            </div>
          </div>
        </div>

        <div
      
          className="col-md-6 col-lg-3"
        >
          <div className="widget-small warning coloured-icon">
            <i className="icon fa fa-files-o fa-3x"></i>
            <div className="info">
              <h4>الرحلات الخاصة المطلوبة</h4>
              <p>
                <b>{currentliveTrips?.length} </b>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="widget-small danger coloured-icon">
            <i className="icon fa fa-star fa-3x"></i>
            <div className="info">
              <h4>الرحلات المشتركة المطلوبة</h4>
              <p>
                <b>{currentliveSharedTrips?.length}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex container">
      <div className="card mx-2">
        <div className="card-body">
              {
                currentliveTrips?.map(trip => {
                  return ( <div onClick={() => {
                    setseletedTrip(null)
                    setTimeout(() => {
                      setseletedTrip({...trip})

                    }, 1000)

                  }} className="border  px-2 pt-2 my-2" key={trip.uuid}  style={{cursor: "pointer"}}>
                  <p>{trip.uuid?.substring(0,8)} </p>
                  </div>)
                })
              }
        </div>
      </div>

    <div className=" flex-grow-1">
        { seletedTrip !== null ? (
          <LiveDataMap  selectedTrip={{...seletedTrip ?? {}}} />
        )  : <></>
        }
        </div>
      </div>

      {/* <ShowTwoLocations endLocation={{...currentliveTrips?.[0]?.endLocation} } startlocation={{...currentliveTrips?.[0]?.startLocation}} /> */}
    </div>
  );
}
