import { Car } from "./car";
import { Channel } from "./channel";
import { Driver } from "./Driver";
import { Location } from "./locations";
import { Passenger } from "./Passenger";
import { Status } from "./Status";

export class Trip {
  uuid?: string;
  driveruuid?: string;
  caruuid?: string;

  passengeruuid?: string;

  channeluuid?: string;

  startLocationuuid?: string;
  endLocationuuid?: string;
  startLocation?: Location;
  endLocation?: Location;

  startedAt?: string;
  endedAt?: string;

  paymentcode?: string;

  route?: string;
  totalAmount?: number;
  driverPrecentage?: number;
  driverAmount?: number;
  companyAmount?: number;

  distance?: number;
  passengerRate?: number;
  note?: string;
  tripStatuscode?: number;
  sharedTripuuid?: string | null
  createdAt?: Date;

  tripStatus?: Status;
  passenger?: Passenger;
  channel?: Channel;
  driver?: Driver;
  car?: Car;
  
  constructor({
    uuid,
    driveruuid,
    caruuid,
    passengeruuid,
    channeluuid,
    startLocationuuid,
    endLocationuuid,
    startedAt,
    endedAt,
    route,
    totalAmount,
    paymentcode,
    driverPrecentage,
    distance,
    passengerRate,
    note,
    tripStatuscode,
    createdAt
  }: {
    uuid: string;
    driveruuid: string;
    caruuid: string;

    passengeruuid: string;

    channeluuid: string;

    startLocationuuid: string;
    endLocationuuid: string;

    startedAt: string;
    endedAt: string;
    paymentcode: string;
    route: string;
    totalAmount: number;
    driverPrecentage: number;
    distance: number;
    passengerRate: number;
    note: string;
    tripStatuscode: number;
  createdAt?: Date;

  }) {
    this.uuid = uuid;
    this.driveruuid = driveruuid;
    this.caruuid = caruuid;
    this.passengeruuid = passengeruuid;
    this.channeluuid = channeluuid;
    this.startLocationuuid = startLocationuuid;
    this.endLocationuuid = endLocationuuid;
    this.startedAt = startedAt;
    this.endedAt = endedAt;
    this.route = route;
    this.totalAmount = totalAmount;
    this.driverPrecentage = driverPrecentage;
    this.distance = distance;
    this.paymentcode = paymentcode;
    this.passengerRate = passengerRate;
    this.note = note;
    this.tripStatuscode = tripStatuscode;
    this.createdAt = createdAt;
  }
}
