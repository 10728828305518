import axios from "axios";
import React, { createContext, ReactNode, useContext, useState } from "react";
import { useEffect } from "react";
import { BASE_URL } from "../../../core/common";
import { ProvidersProps } from "../../../core/types";
import { Channel } from "../../entities/channel";
import { SharedTripChannel } from "../../entities/shared-trip-channel";

type ChannelContextType = {
  loading?: false;
  setLoading?: Function;
  channels?: Channel[] | undefined | null;
  setChannels?: Function;
  disableCHannel?: Function;
  createChannel?: Function;
  updateChannel?: Function;
  //
  sharedTripChannels?: SharedTripChannel[] | undefined | null;
  disableSharedTripChannel?: Function;
  updateSharedTripChannel?: Function;
  createSharedTripChannel?: Function;
};
const ChannelContext = createContext<ChannelContextType>({
  channels: null,
  setChannels: Function,
  loading: false,
  setLoading: Function,
  disableCHannel: Function,
  createChannel: Function,
  updateChannel: Function,
  //
  sharedTripChannels: null,
  disableSharedTripChannel: Function,
  updateSharedTripChannel: Function,
  createSharedTripChannel: Function,
});

export const ChannelProvider: React.FunctionComponent<ProvidersProps> = ({
  children,
}) => {
  const [channels, setChannels] = React.useState<Channel[] | null>();
  const [sharedTripChannels, setSharedTripChannels] = React.useState<
    SharedTripChannel[] | null
  >();

  // get channel
  async function getChannels() {
    const res = await axios.get(`${BASE_URL}/api/dashboard/channels/all`, {
      // withCredentials: true,
      headers: {
        "x-api-key":
          "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
        "Content-Type": "application/json",
      },
    });
    setChannels(res.data.data);
  }

  async function disableCHannel(uuid: string) {
    const res = await axios.get(
      `${BASE_URL}/api/dashboard/channels/disable-enable/${uuid}`,
      {
        // withCredentials: true,
        headers: {
          "x-api-key":
            "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
          "Content-Type": "application/json",
        },
      }
    );
    if (res.data.data) {
      await getChannels();
    }
  }

  async function createChannel(channel: Channel) {
    const res = await axios.post(
      `${BASE_URL}/api/dashboard/channels/add`,
      {
        ...channel,
      },
      {
        // withCredentials: true,
        headers: {
          "x-api-key":
            "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
          "Content-Type": "application/json",
        },
      }
    );

    if (res.data.data) {
      await getChannels();
    }
  }

  async function updateChannel(uuid: string, channel: Channel) {
    delete channel.Status;

    console.log({ channel });

    const res = await axios.put(
      `${BASE_URL}/api/dashboard/channels/update/${uuid}`,
      {
        ...channel,
      },
      {
        // withCredentials: true,
        headers: {
          "x-api-key":
            "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
          "Content-Type": "application/json",
        },
      }
    );

    if (res.data.data) {
      await getChannels();
    }
  }
  // get Shared channel
  async function getSharedTripChannels() {
    const res = await axios.get(
      `${BASE_URL}/api/dashboard/shared-trip-channels/all`,
      {
        // withCredentials: true,
        headers: {
          "x-api-key":
            "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
          "Content-Type": "application/json",
        },
      }
    );

    setSharedTripChannels(res.data.data);
  }

  async function disableSharedTripChannel(uuid: string) {
    const res = await axios.get(
      `${BASE_URL}/api/dashboard/shared-trip-channels/disable-enable/${uuid}`,
      {
        // withCredentials: true,
        headers: {
          "x-api-key":
            "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
          "Content-Type": "application/json",
        },
      }
    );
    if (res.data.data) {
      await getSharedTripChannels();
    }
  }

  async function createSharedTripChannel(channel: Channel) {
    const res = await axios.post(
      `${BASE_URL}/api/dashboard/shared-trip-channels/add`,
      {
        ...channel,
      },
      {
        // withCredentials: true,
        headers: {
          "x-api-key":
            "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
          "Content-Type": "application/json",
        },
      }
    );

    if (res.data.data) {
      await getSharedTripChannels();
    }
  }
  async function updateSharedTripChannel(uuid: string, channel: Channel) {
    delete channel.Status;

    console.log({ channel });

    const res = await axios.put(
      `${BASE_URL}/api/dashboard/shared-trip-channels/update/${uuid}`,
      {
        ...channel,
      },
      {
        // withCredentials: true,
        headers: {
          "x-api-key":
            "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
          "Content-Type": "application/json",
        },
      }
    );

    if (res.data.data) {
      await getSharedTripChannels();
    }
  }

  // <  > //
  useEffect(() => {
    getChannels();
    getSharedTripChannels();
    return () => {};
  }, []);

  return (
    <ChannelContext.Provider
      value={{
        channels,
        setChannels,
        createChannel,
        disableCHannel,
        updateChannel,
        //
        sharedTripChannels,
        disableSharedTripChannel,
        updateSharedTripChannel,
        createSharedTripChannel,
      }}
    >
      {children}
    </ChannelContext.Provider>
  );
};

export const useChannel = () => useContext(ChannelContext);
