/* eslint-disable no-throw-literal */
import axios from "axios";
import { api, socketapi } from "../../../../core";
import { BaseRepository } from "../../../../core/base.repository";
import { Trip } from "../../../entities/trip";

export class TripRepository implements BaseRepository<Trip> {
  async create({ input }: { input: Trip }): Promise<Trip> {
    try {

      const res = await socketapi.post(`/api/passenger/trip/add-from-dashboard`,
        {
          ...input
        },
        {
          withCredentials: true,
          headers: {
            "authorization": localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
            "x-api-key":
              "asdf233mdslfm23mdlkmlskd",
            "Content-Type": "application/json",
          },
        }
      );



      return new Trip(res.data.data)

    } catch (e: any) {
      console.log({
        e
      });
      throw e.response.data !== undefined ? { message: e.response.data.data.error, statusCode: e.response.data.data.statusCode } : { message: e.message ?? "خطأ", statusCode: 500 };


    }
  }
  async passengerCancelTrip({ input }: { input: Trip }): Promise<Trip> {
    try {

      const res = await socketapi.put(`/api/passenger/trip/cancel`,
        {
          tripuuid:input.uuid,
          dashboard: true
        },
        {
          withCredentials: true,
          headers: {
            "authorization": localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
            "x-api-key":
              "asdf233mdslfm23mdlkmlskd",
            "Content-Type": "application/json",
          },
        }
      );



      return new Trip(res.data.data)

    } catch (e: any) {
      console.log({
        e
      });
      throw e.response.data !== undefined ? { message: e.response.data.data.error, statusCode: e.response.data.data.statusCode } : { message: e.message ?? "خطأ", statusCode: 500 };


    }
  }
  update({ uuid, input }: { uuid: string; input: Trip; }): Promise<boolean> {
    throw new Error("Method not implemented.");
  }
  delete({ uuid }: { uuid: string; }): Promise<boolean> {
    throw new Error("Method not implemented.");
  }
  async find({ filter }: { filter: Trip | undefined; }): Promise<Trip[]> {
    try {

      const res = await api.get(`api/dashboard/trips/all`, {
      });


      return [...res.data.data]

    } catch (e: any) {
      // console.log({error: e.response.data.data});

      throw e.response.data !== undefined ? { message: e.response.data.data.error, statusCode: e.response.data.data.statusCode } : { message: e.message ?? "خطأ", statusCode: 500 };

    }
  }
  async findOne({ filter }: { filter: Trip; }): Promise<Trip> {
    try {

      const res = await api.get(`api/dashboard/private-trip/${filter.uuid}`, {
      });


      return { ...res.data.data }

    } catch (e: any) {
      // console.log({error: e.response.data.data});

      throw e.response.data !== undefined ? { message: e.response.data.data.error, statusCode: e.response.data.data.statusCode } : { message: e.message ?? "خطأ", statusCode: 500 };

    }
  }









}






