import { Link } from "react-router-dom";
import { Driver } from "../../../entities/Driver";
import { Status } from "../../../entities/Status";
import { Trip } from "../../../entities/trip";
import { DriversColumn } from "./Driver";

export
    const allDriversColumns: DriversColumn[] = [
        {
            name: " الاسم الاول",
            selector: (row: { firstname: any }) => row.firstname,
        },
        {
            name: "الاسم الاخير",
            selector: (row: { lastname: any }) => row.lastname,
        },
        {
            name: "رقم الهاتف",
            selector: (row: { phoneNumber: any }) => row.phoneNumber,
        },
        {
            name: "تأكيدالحساب",
            cell: (row, index, column, id) => {
                    return    row.active === true ? <p className="text-success">مؤكد </p> :   <p className="text-danger">غير مؤكد</p>
            }
        },
        {
            name: " حالة الحساب",
            cell: (row, index, column, id) => {
                return    row.Status.code === 111 ? <p className="text-success">{row.Status.name} </p> :   <p className="text-danger">{row.Status.name}</p>
        }
        },
        {
            name: " الرصيد",
            selector: (row: Driver) => ` SDG ${row.balance?.total} `,
            sortable: true,
        },
        {
            name: 'تارخ الإنشاء',
            selector: (row:  Driver) => row.createdAt,
            cell: (row, index, column, id) => {
                return    <p className="">{new Date(row.createdAt).toLocaleDateString()} </p> 
        },
            sortable: true,
        },
        {
            name: "تفاصيل",
            cell: (row: Trip, index, column, id) => {
                return   <Link to={`/driver/${row.uuid}`} > <button className="btn btn-primary"> full details</button> </Link>
        },
        },
    ];


// new drviers
export
    const newDriversColumns: DriversColumn[] = [
        {
            name: " الاسم الاول",
            selector: (row: { firstname: any }) => row.firstname,
        },
        {
            name: "الاسم الاخير",
            selector: (row: { lastname: any }) => row.lastname,
        },
        {
            name: "رقم الهاتف",
            selector: (row: { phoneNumber: any }) => row.phoneNumber,
        },
        {
            name: " حالة الحساب",
            selector: (row: Driver) => (row?.active === false ? "غير مفعل" : "مفعل"),
        },
        {
            name: " الحالة",
            selector: (row: { Status: Status }) => row.Status.name,
        },
        {
            name: 'تارخ الإنشاء',
            selector: (row:  Driver) => row.createdAt,
            cell: (row, index, column, id) => {
                return    <p className="">{new Date(row.createdAt).toLocaleDateString()} </p> 
        },
            sortable: true,
        },
    ];
// new drviers
export
    const notVerifiedDriversColumns: DriversColumn[] = [
        {
            name: " الاسم الاول",
            selector: (row: { firstname: any }) => row.firstname,
        },
        {
            name: "الاسم الاخير",
            selector: (row: { lastname: any }) => row.lastname,
        },
        {
            name: "رقم الهاتف",
            selector: (row: { phoneNumber: any }) => row.phoneNumber,
        },
        {
            name: "تأكيدالحساب",
            cell: (row, index, column, id) => {
                    return    row.active === true ? <p className="text-success">مؤكد </p> :   <p className="text-danger">غير مؤكد</p>
            }
        },
        {
            name: 'تارخ الإنشاء',
            selector: (row:  Driver) => row.createdAt,
            cell: (row, index, column, id) => {
                return    <p className="">{new Date(row.createdAt).toLocaleDateString()} </p> 
        },
            sortable: true,
        },
    ];
// new drviers
export
    const notConfirmedDriversColumns: DriversColumn[] = [
        {
            name: " الاسم الاول",
            selector: (row: { firstname: any }) => row.firstname,
        },
        {
            name: "الاسم الاخير",
            selector: (row: { lastname: any }) => row.lastname,
        },
        {
            name: "رقم الهاتف",
            selector: (row: { phoneNumber: any }) => row.phoneNumber,
        },

        {
            name: " حالة الحساب",
            cell: (row, index, column, id) => {
                return    row.Status.code === 111 ? <p className="text-success">{row.Status.name} </p> :   <p className="text-danger">{row.Status.name}</p>
        }
    },
    {
        name: 'تارخ الإنشاء',
        selector: (row:  Driver) => row.createdAt,
        cell: (row, index, column, id) => {
            return    <p className="">{new Date(row.createdAt).toLocaleDateString()} </p> 
    },
        sortable: true,
    },
    ];