import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom';
import { Admin } from '../../../../entities/Admin';
import { useAdmin } from '../../Context/admin.context';
import { AdminsColumn, AdminsRow } from './types/admins';

export default function AllAdminsTable({
    columns,
    data,
  }: {
    columns: AdminsColumn[];
    data: AdminsRow[];
  }) {
    const {  admins,admin, activateORdeactivate, resetAdminPassword } = useAdmin();
    // modal
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const [selectedAdmin, setselectedAdmin] = React.useState<Admin | null | undefined>({});
    function openDriver(row) {
      setselectedAdmin({ ...row });
      handleShow();
    }
  
    React.useEffect(() => {
      setselectedAdmin(admins?.find(ch => ch.uuid === selectedAdmin?.uuid));
      return () => {};
    }, [admins]);
  
    
  return (
    <div>

         <DataTable
        columns={columns}
        data={data}
        onRowClicked={(row) => openDriver(row)}
        selectableRows={true}
      />
       <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}

      >
       <Modal.Header closeButton>
          <Modal.Title>تفاصيل المسؤول</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex-col justify-content-between ">
            <div className="d-flex justify-content-between my-3">
              <span> اسم المسؤول </span>
              <span>{selectedAdmin?.name}</span>
            </div>
            <div className="d-flex justify-content-between my-3">
              <span>  رقم الهاتف </span>
              <span>{selectedAdmin?.phoneNumber}</span>
            </div>
            <div className="d-flex justify-content-between my-3">
              <span>  الصلاحية </span>
              <span>{selectedAdmin?.role?.name}</span>
            </div>
            <div className="d-flex justify-content-between my-3">
              <span>  الحالة </span>
              <span>{selectedAdmin?.status?.name}</span>
            </div>

          
          </div>
        </Modal.Body> 
     {
      selectedAdmin?.rolecode  === 111 ? selectedAdmin.rolecode === admin?.rolecode ?  <Modal.Footer>
      {selectedAdmin?.statuscode === 111 ? (
        <button
          onClick={() => activateORdeactivate?.(selectedAdmin?.uuid)}
          className="my-3 btn btn-danger"
          type="submit"
        >
          وقف المسؤول
        </button>
      ) : (
        <button
        onClick={() => activateORdeactivate?.(selectedAdmin?.uuid)}
          className="my-3 btn btn-primary"
          type="submit"
        >
          تفعيل المسؤول
        </button>
      )}

      <Button variant="secondary" onClick={handleClose}>
        غلق
      </Button>
      <button
          onClick={() => resetAdminPassword?.(selectedAdmin?.uuid)}
          className="my-3 btn btn-info"
          type="submit"
        >
           إعادة تعيين كلمة السر
        </button>
      <Link to={`/edit-admins/${selectedAdmin?.uuid}`}>
        <Button variant="warning"> تعديل </Button>
      </Link>
    </Modal.Footer> : <></> :  (
        <Modal.Footer>
          {selectedAdmin?.statuscode === 111 ? (
            <button
              onClick={() => activateORdeactivate?.(selectedAdmin?.uuid)}
              className="my-3 btn btn-danger"
              type="submit"
            >
              وقف المسؤول
            </button>
          ) : (
            <button
            onClick={() => activateORdeactivate?.(selectedAdmin?.uuid)}
              className="my-3 btn btn-primary"
              type="submit"
            >
              تفعيل المسؤول
            </button>
          )}

          <Button variant="secondary" onClick={handleClose}>
            غلق
          </Button>
          <button
              onClick={() => resetAdminPassword?.(selectedAdmin?.uuid)}
              className="my-3 btn btn-info"
              type="submit"
            >
               إعادة تعيين كلمة السر
            </button>
          <Link to={`/edit-admins/${selectedAdmin?.uuid}`}>
            <Button variant="warning"> تعديل </Button>
          </Link>
        </Modal.Footer>
      )
     }
        
      </Modal>
    </div>
  )
}
