export const DRIVER_EVENTS = {
    DRIVER_CONNECT: "DRIVER_CONNECT",
    DRIVER_CONNECTED: "DRIVER_CONNECTED",

    GET_DRIVER_BALANCE: "GET_DRIVER_BALANCE",
    DRIVER_BALANCE_RECIEVED: "DRIVER_BALANCE_RECIEVED",

    DRIVER_ACTIVATE: "DRIVER_ACTIVATE",
    DRIVER_ACTIVATED: "DRIVER_ACTIVATED",

    DRIVER_DEACTIVATE: "DRIVER_DEACTIVATE",
    DRIVER_DEACTIVATED: "DRIVER_DEACTIVATED",

    CHECK_DRIVER_ACTIVATATION: "CHECK_DRIVER_ACTIVATATION",
    DRIVER_ACTIVATATION_CHECKED: "DRIVER_ACTIVATATION_CHECKED",

    SEND_CURRENT_LOCATION: "SEND_CURRENT_LOCATION",

    ALL_DRIVERS_LOCATION_SENDED: "ALL_DRIVERS_LOCATION_SENDED",

    UPDATE_DRIVER_BALANCE: "UPDATE_DRIVER_BALANCE",
    DRIVER_BALANCE_UPDATED: "DRIVER_BALANCE_UPDATED",
}

// PASSENGER events
export const PASSENGER_EVENTS = {
    PASSENGER_CONNECT: "PASSENGER_CONNECT",
    PASSENGER_CONNECTED: "PASSENGER_CONNECTED",
}

//  trip full events
export const TRIP_EVENTS = {
    CREATE_TRIP: "CREATE_TRIP",
    TRIP_CREATED: "TRIP_CREATED",



    ACCEPT_TRIP: "ACCEPT_TRIP",
    TRIP_ACCEPTED: "TRIP_ACCEPTED",

    REACH_TRIP: "REACH_TRIP",
    TRIP_REACHED: "TRIP_REACHED",

    START_TRIP: "START_TRIP",
    TRIP_STARTED: "TRIP_STARTED",

    COMPLETE_TRIP: "COMPLETE_TRIP",
    TRIP_COMPELTED: "TRIP_COMPELTED",

    PASSENGER_CANCEL_TRIP: "PASSENGER_CANCEL_TRIP",
    PASSENGER_TRIP_CANCELLED: "PASSENGER_TRIP_CANCELLED",


    DRIVER_CANCEL_TRIP: "DRIVER_CANCEL_TRIP",
    DRIVER_TRIP_CANCELLED: "DRIVER_TRIP_CANCELLED",


    SEND_DRIVER_LOCATION_PRIVATE_TRIP: "SEND_DRIVER_LOCATION_PRIVATE_TRIP",
    DRIVER_LOCATION_SENDED_PRIVATE_TRIP: "DRIVER_LOCATION_SENDED_PRIVATE_TRIP",

    STOP_SEND_DRIVER_LOCATION_PRIVATE_TRIP: "STOP_SEND_DRIVER_LOCATION_PRIVATE_TRIP",
    SEND_DRIVER_LOCATION_STOPED_PRIVATE_TRIP: "SEND_DRIVER_LOCATION_STOPED_PRIVATE_TRIP",

}