import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { ServiceCenter } from "../../entities/ServiceCenter";
import { Icon } from "@iconify/react";
import locationIcon from "@iconify/icons-mdi/map-marker";

export default function ServiceCenterMap({serviceCenters}: {serviceCenters: ServiceCenter[]}) {
    const defaultProps = {
        center: {
          lat: 15.577608,
          lng: 32.568565,
        },
        zoom: 14,
      };
    
      const [liveCenters, setLiveCenters] = React.useState<ServiceCenter[]>([]);
    
      useEffect(() => {
        setLiveCenters([...serviceCenters])
        return () => {};
      }, []);
    
      return (
        // Important! Always set the container height explicitly
        <div className="card">
          <div className="card-body ">
            <div className="" style={{ height: "50vh" }}>
              <div className="w-100 h-100">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyC6F_v_8d-6mmUZJYXIEh-EA_WFu2oUqEk",
                  }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                >
                  {liveCenters.map((center) => {
                    return (
                      <AnyReactComponent
                        key={center.uuid}
                        lat={center.latitude ?? 0.0}
                        lng={center.longitude ?? 0.0}
                        text
                        com={
                          <>
                                      <Icon color={center.statuscode === 111 ? "#28a745" : "#dc3545"} fontSize={60} icon={locationIcon} className= " pin-icon" />
                    
                          </>
                        }
                      />
                    );
                  })}
                  {/* <AnyReactComponent
              lat={ 15.577608}
              lng={ 32.568565}
              text="Share taxi"
              com={(<>     </>)}
            /> */}
                </GoogleMapReact>
              </div>
            </div>
          </div>
        </div>
      );
}

const AnyReactComponent = ({
    text,
    lat,
    lng,
    com,
  }: {
    text: any;
    lat: number;
    lng: number;
    com: any;
  }) => (
    <div>
      {text} {com}
    </div>
  );
  