import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { useChannel } from "../../channel.context";
import { SharedTripChannel } from "../../../../entities/shared-trip-channel";



type Inputs = {
  name: string;
  openPrice: string;
  pricePerKm: string;
};

export default function SingleSharedTripChannel() {

  let navigate = useNavigate();
  const { uuid } = useParams();
  const { sharedTripChannels, updateSharedTripChannel  } = useChannel();

  const [channel, setChannel] = useState<SharedTripChannel>({});

  useEffect(() => {
    setChannel({ ...sharedTripChannels?.find((d) => d.uuid === uuid) });

    return () => {};
  }, [sharedTripChannels]);

  function changer(value) {
    setChannel({
      ...value,
    });
  }

 
  return (
    <div className="px-3 mt-5">
      <Link to="/trip-channels"> <span className="h5"> القنوات </span> </Link>

      <div className="card  my-3 px-3 ">
        <div className="pt-3">
          <h3> تعديل أسعار الكيلو </h3>
        </div>
        <div className="card-body ">
          <Formik
            initialValues={{
              openPrice: channel?.openPrice,
              pricePerKm: channel?.pricePerKm,
            }}
            onSubmit={async (values) => {
              console.log({channel});
              
              if (
                channel.name !== "" && channel.openPrice !== null  && channel.pricePerKm !== null
              ) {
         
              
               await updateSharedTripChannel?.(uuid, {
                  ...channel
                })

                return navigate("/shared-trip-channels"); 
             
              }
            }}
          >
            <Form>
              <label htmlFor="openPrice">   اسم القناة </label>
              <Field
                className="form-control"
                name="name"
                type="text"
                value={channel?.name || ""}
                onChange={(e) =>
                  changer({ ...channel, name: e.target.value })
                }
              />
              <label htmlFor="openPrice"> سعر فتح المشوار </label>
              <Field
                className="form-control"
                name="openPrice"
                type="number"
                value={channel?.openPrice || ""}
                onChange={(e) =>
                  changer({ ...channel, openPrice:Number(e.target.value) })
                }
              />

              <label htmlFor="pricePerKm">سعر الكيلو</label>
              <Field
                name="pricePerKm"
                className="form-control"
                type="number"
                value={channel?.pricePerKm || ""}
                onChange={(e) =>
                  changer({ ...channel, pricePerKm:Number(e.target.value) })
                }
              />
              <button className="my-3 btn btn-primary" type="submit">Submit</button>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}
