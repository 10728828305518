import React from 'react'
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom';
import { ApkTypesColumn, ApkTypesRow } from './types/apk-types';

export default function ApkVersionTable({
    columns,
    data,
  }: {
    columns: ApkTypesColumn[];
    data: ApkTypesRow[];
  })  {
    let navigate = useNavigate();
  return (
    <div>

<DataTable
        columns={columns}
        data={data}
        onRowClicked={(row) =>navigate(`/edit-apk-version/${row.uuid}`) }
        selectableRows={true}
      />
    </div>
  )
}
