import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { SharedTripChannel } from "../../../../entities/shared-trip-channel";
import { useChannel } from "../../channel.context";
import { SharedTripChannelsColumn, SharedTripChannelsRow } from "./types/channel";

export default function SharedTripChannelTable({
  columns,
  data,
}: {
  columns: SharedTripChannelsColumn[];
  data: SharedTripChannelsRow[];
}) {
  const { sharedTripChannels, disableSharedTripChannel } = useChannel();
  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedChannel, setselectedChannel] = useState<SharedTripChannel | null | undefined>({});
  function openDriver(row) {
    setselectedChannel({ ...row });
    handleShow();
  }

  useEffect(() => {
    setselectedChannel(sharedTripChannels?.find(ch => ch.uuid === selectedChannel?.uuid));
    return () => {};
  }, [sharedTripChannels]);

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        onRowClicked={(row) => openDriver(row)}
        selectableRows={true}
      />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header closeButton>
          <Modal.Title>تفاصيل القناة</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex-col justify-content-between ">
            <div className="d-flex justify-content-between my-3">
              <span> اسم القناة </span>
              <span>{selectedChannel?.name}</span>
            </div>

            <div className="d-flex justify-content-between my-3">
              <span> سعر فتح المشوار </span>
              <span>{selectedChannel?.openPrice}</span>
            </div>

            <div className="d-flex justify-content-between my-3">
              <span> سعر الكيلو </span>
              <span>{selectedChannel?.pricePerKm}</span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {selectedChannel?.statuscode === 111 ? (
            <button
              onClick={() => disableSharedTripChannel?.(selectedChannel?.uuid)}
              className="my-3 btn btn-danger"
              type="submit"
            >
              وقف القناة
            </button>
          ) : (
            <button
              onClick={() => disableSharedTripChannel?.(selectedChannel?.uuid)}
              className="my-3 btn btn-primary"
              type="submit"
            >
              تفعيل القناة
            </button>
          )}

          <Button variant="secondary" onClick={handleClose}>
            غلق
          </Button>
          <Link to={`/shared-trip-channel/${selectedChannel?.uuid}`}>
            <Button variant="warning"> تعديل </Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
}
