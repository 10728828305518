import axios from "axios";
import React, { createContext, ReactNode, useContext, useState } from "react";
import { useEffect } from "react";
import { BASE_URL } from "../../../../core/common";
import { ProvidersProps } from "../../../../core/types";
import { Admin } from "../../../entities/Admin";
import { useErrors } from "../../Common-components/Error/error.context";
import { AdminRepository } from "../repository/admin.repo";

const adminRepository: AdminRepository = new AdminRepository();

type AdminContextType = {
  adminloading?: boolean;
  setLoading?: Function | null;
  admin?: Admin | null | undefined;
  admins?: Admin[] | null | undefined;
  loginAdmin?: Function;
  getAdmin?: Function;
  logoutAdmin?: Function;
  createAdmin?: Function;
  updateAdmin?: Function;
  updateOtherAdmins?: Function;
  activateORdeactivate?: Function;
  resetAdminPassword?: Function;
  updateAdminPassword?: Function;
};
const AdminContext = createContext<AdminContextType>({
  adminloading: false,
  admin: null,
  admins: [],
  setLoading: null,
  loginAdmin: Function,
  getAdmin: Function,
  logoutAdmin: Function,
  createAdmin: Function,
  updateAdmin: Function,
  updateOtherAdmins: Function,
  activateORdeactivate: Function,
  resetAdminPassword: Function,
  updateAdminPassword: Function,
});

export const AdminProvider: React.FunctionComponent<ProvidersProps> = ({
  children,
}) => {
  const [admin, setAdmin] = useState<Admin | null | undefined>();
  const [adminloading, setadminloading] = useState(false)
  const [admins, setAdmins] = useState<Admin[] | null | undefined>();
  const { errors, setErrors } = useErrors();
  const getAdmins = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/api/dashboard/admin/all`,

        {
          withCredentials: true,
          headers: {
            authorization:
              localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
            "x-api-key":
              "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
            "Content-Type": "application/json",
          },
        }
      );

      if (res?.data?.data) {
        setAdmins(res.data?.data);
      }
    } catch (e: any) {
      console.log({ e });
    }
  };
  const createAdmin = async (admin: Admin) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/api/dashboard/admin/create-account`,
        {
          ...admin,
        },
        {
          withCredentials: true,
          headers: {
            authorization:
              localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
            "x-api-key":
              "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
            "Content-Type": "application/json",
          },
        }
      );

      if (res?.data?.data) {
        await getAdmins();
      }
    } catch (e: any) {
      console.log({ e });
    }
  };
  const updateAdmin = async (admin: Admin) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/api/dashboard/admin/update-account/${admin?.uuid}`,
        {
          ...admin,
        },
        {
          withCredentials: true,
          headers: {
            authorization:
              localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
            "x-api-key":
              "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
            "Content-Type": "application/json",
          },
        }
      );

      if (res?.data?.data) {
        await getAdmin();
        console.log({ res: res.data });
      }
    } catch (e: any) {
      console.log({ e });
    }
  };
  const updateOtherAdmins = async (admin: Admin) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/api/dashboard/admin/update-admin/${admin?.uuid}`,
        {
          ...admin,
        },
        {
          withCredentials: true,
          headers: {
            authorization:
              localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
            "x-api-key":
              "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
            "Content-Type": "application/json",
          },
        }
      );

      if (res?.data?.data) {
        await getAdmins();
        console.log({ res: res.data });
      }
    } catch (e: any) {
      console.log({ e });
    }
  };
  const activateORdeactivate = async (uuid: string) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/api/dashboard/admin/activate-or-deactivate-admin/${uuid}`,
        {
          ...admin,
        },
        {
          withCredentials: true,
          headers: {
            authorization:
              localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
            "x-api-key":
              "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
            "Content-Type": "application/json",
          },
        }
      );

      if (res?.data?.data) {
        await getAdmins();
        console.log({ res: res.data });
      }
    } catch (e: any) {
      console.log({ e });
    }
  };
  const updateAdminPassword = async ({
    uuid,
    credentials,
  }: {
    uuid: string;
    credentials: {};
  }) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/api/dashboard/admin/update-admin-password/${uuid}`,
        {
          ...credentials,
        },
        {
          withCredentials: true,
          headers: {
            authorization:
              localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
            "x-api-key":
              "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
            "Content-Type": "application/json",
          },
        }
      );

      if (res?.data?.data) {
        await getAdmins();
        console.log({ res: res.data });
      }
    } catch (e: any) {
      console.log({ e });
    }
  };
  const resetAdminPassword = async (uuid: string) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/api/dashboard/admin/reset-admin-password/${uuid}`,
        {
          ...admin,
        },
        {
          withCredentials: true,
          headers: {
            authorization:
              localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
            "x-api-key":
              "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
            "Content-Type": "application/json",
          },
        }
      );

      if (res?.data?.data) {
        await getAdmins();
        console.log({ res: res.data });
      }
    } catch (e: any) {
      console.log({ e });
    }
  };

  const loginAdmin = async (admin: Admin) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/api/dashboard/admin/login`,
        {
          phoneNumber: admin.phoneNumber,
          password: admin.password,
        },
        {
          withCredentials: true,
          headers: {
            authorization:
              localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
            "x-api-key":
              "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
            "Content-Type": "application/json",
          },
        }
      );

      if (res?.data?.data?.user) {
        setAdmin(res.data.data.user);
        localStorage.setItem(
          "share-taxi-dashboard-access-token",
          res.data.data.accessToken
        );
        localStorage.setItem(
          "share-taxi-dashboard-refresh-token",
          res.data.data.refreshToken
        );
      }
    } catch (e: any) {
      console.log({ error: e.data });
    }
  };
  const getAdmin = async () => {
    try {
    
      setadminloading(true)
      const res = await adminRepository.me({
        accessToken:
          localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
        refreshToken:
          localStorage.getItem("share-taxi-dashboard-refresh-token") ?? "",
      });
  
     
      
      if (res?.data?.data) {
        setAdmin(res.data.data);
      } else {
        setAdmin(null)
      }
      setadminloading(false)

    } catch (e: any) {
      setadminloading(false)
      setAdmin(null)
      setErrors((state: any) => [
        ...state,
        { message: e.message, code:e.statusCode },
      ]);
    }
  };
  const logoutAdmin = async () => {
    try {
      const res = await axios.delete(
        `${BASE_URL}/api/dashboard/admin/logout`,

        {
          withCredentials: true,
          headers: {
            authorization:
              localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
            "x-api-key":
              "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
            "Content-Type": "application/json",
          },
        }
      );

localStorage.removeItem("share-taxi-dashboard-access-token");
localStorage.removeItem("share-taxi-dashboard-refresh-token");
      if (res.data.data) {
        localStorage.removeItem("share-taxi-dashboard-access-token");
        localStorage.removeItem("share-taxi-dashboard-refresh-token");
        // cookies.remove("accessToken")
        // cookies.remove("refreshToken")
        setAdmin(null);
      }
    } catch (e: any) {
      console.log({ e });
    }
  };

  useEffect(() => {
    getAdmin();
    getAdmins();
    return () => {};
  }, []);

  return (
    <AdminContext.Provider
      value={{
        adminloading,
        admin,
        admins,
        loginAdmin,
        getAdmin,
        logoutAdmin,
        createAdmin,
        updateAdmin,
        updateOtherAdmins,
        activateORdeactivate,
        updateAdminPassword,
        resetAdminPassword,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => useContext(AdminContext);
