
import { Link } from "react-router-dom";
import { Trip } from "../../../entities/trip";
import { TripsColumn } from "./Trip";

export
    const allTripsColumns: TripsColumn[] = [
        {
            name: "uuid",
            selector: (row: Trip) => (`${row.uuid?.slice(0, 5)  }`) ,
        },
        {
            name: "أسم الراكب",
            selector: (row: Trip) => (`${row.passenger?.firstname  } ${ row.passenger?.lastname}`) ,
        },
       
        {
            name: "أسم السائق",
            selector: (row: Trip) => (`${row.driver?.firstname ?? "_"  } ${ row.driver?.lastname ?? "_"}`) ,
        },
       
        {
            name: "نقطة البداية",
            selector: (row: Trip) => row.startLocation?.name,
        },
        {
            name: "نقطة النهاية",
            selector: (row: Trip) => row.endLocation?.name,
        },
        {
            name: "السعر",
            selector: (row: Trip) => `${row.totalAmount} SDG`,
        },
        {
            name: "المسافة",
            selector: (row: Trip) => `${row.distance} Km`,
        },
        {
            name: " الحالة",
            selector: (row: Trip) => row.tripStatus?.name,
        },
        {
            name: 'تارخ الإنشاء',
            selector: (row:  Trip) => row.createdAt,
            cell: (row, index, column, id) => {
                return    <p className="">{new Date(row.createdAt).toLocaleDateString()} </p> 
        },
            sortable: true,
        },
        {
            name: "تفاصيل",
            cell: (row: Trip, index, column, id) => {
                return   <Link to={`/trip/${row.uuid}`} > <button className="btn btn-primary"> full details</button> </Link>
        },
        },
    ];


// new drviers
export
    const ongoingPrivateTrip: TripsColumn[] = [
        {
            name: "passenger",
            selector: (row: Trip) => row.passengeruuid,
        },

        {
            name: "statusCode",
            selector: (row: Trip) => row.tripStatus?.name,
        },
       
    ];