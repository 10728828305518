import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Admin } from '../../../../entities/Admin';
import { useAdmin } from '../../Context/admin.context';

export default function CreateAdmin() {
  let navigate = useNavigate();
    const {createAdmin} = useAdmin();

    const roles = [
        // {
        //     name: "super Admin",
        //     code: 111
        // },
        { 
            name: "Admin",
            code: 222
        },
        {
            name: "tech support ",
            code: 333
        }
    ]
  const [admin, setAdmin] = React.useState<Admin | null>({
    rolecode:222
  });
  function changer(value) {
    setAdmin({
      ...value
    })
  }

 async function handlerSubmit() {
    console.log({
        admin
    });
    
   await createAdmin?.(
        {
            ...admin
        }
    )
    navigate("/all-admins")
}

  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="px-3 mt-5">
    <div className="card  my-3 px-3 ">
      <div className="pt-3">
        <h3> أضافة مسؤول </h3>
      </div>
      <div className="card-body ">
        <form onSubmit={(e) => e.preventDefault()} action="#">
          <div>
            <label htmlFor="amount"> الاسم  </label>
            <input
              className="form-control"
              type="text"
              name="name"
              id=""
              onChange={(e) => changer({...admin, name: e.target.value })}
            />
          </div>
        
          <div>
            <label htmlFor="amount">  phone Number </label>
            <input
              className="form-control"
              type="text"
              name="phoneNumber"
              id=""
              placeholder='0118455907'
              onChange={(e) => changer({...admin, phoneNumber: e.target.value.toString() })}
            />
          </div>
          <div>
            <label htmlFor="email">  Email  </label>
            <input
              className="form-control"
              type="text"
              name="email"
              id=""
              placeholder='example@test.com'
              onChange={(e) => changer({...admin, email: e.target.value.toString() })}
            />
          </div>
          <div className="form-group">
                    <label htmlFor="exampleSelect1"> الصلاحية </label>
                    <select  value={admin?.rolecode}    onChange={(e) => changer({...admin, rolecode: Number(e.target.value) })} className="form-control" id="exampleSelect1">
                        {
                           roles.map(number => {
                             return   <option key={number.code} value={number.code}>{number.name}</option>
                            })
                        }
                


                     
                    </select>
                  </div>

          <button
            onClick={e => handlerSubmit()}
            className="my-3 btn btn-primary"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
  )
}
