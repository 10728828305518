import { Link } from "react-router-dom";
import { Passenger } from "../../../entities/Passenger";
import { Status } from "../../../entities/Status";
import { Trip } from "../../../entities/trip";
import { PassengersColumn } from "./Passengers";

// all passengers
export
    const allPassengersColumns: PassengersColumn[] = [
        {
            name: '#',
            selector: (row:  any) => row.createdAt,
            cell: (row, index, column, id) => {
                return    <p className="">{index + 1} </p> 
        },
            sortable: true,
        },
        {
            name: " الاسم الاول",
            selector: (row: { firstname: any }) => row.firstname,
        },
        {
            name: "الاسم الاخير",
            selector: (row: { lastname: any }) => row.lastname,
        },
        {
            name: "رقم الهاتف",
            selector: (row: { phoneNumber: any }) => row.phoneNumber,
        },
        {
            name: "تأكيدالحساب",
            cell: (row, index, column, id) => {
                    return    row.active === true ? <p className="text-success">مؤكد </p> :   <p className="text-danger">غير مؤكد</p>
            }
        },
        {
            name: " حالة الحساب",
            cell: (row, index, column, id) => {
                return    row.Status.code === 111 ? <p className="text-success">{row.Status.name} </p> :   <p className="text-danger">{row.Status.name}</p>
        }
        },

        {
            name: "تاريخ الانضام",
            sortable: true,
            selector: (row:  any) => row.createdAt,
            cell: (row, index, column, id) => {
                return    <p className="">{new Date(row.createdAt).toLocaleDateString()} </p>  
        },
        },
        {
            name: "تفاصيل",
            cell: (row: Passenger, index, column, id) => {
                return   <Link to={`/passenger/${row.uuid}`} > <button className="btn btn-primary"> full details</button> </Link>
        },
        },
   
    ];

// new drviers
export
    const newPassengersColumns: PassengersColumn[] = [
        {
            name: '#',
            selector: (row:  any) => row.createdAt,
            cell: (row, index, column, id) => {
                return    <p className="">{index + 1} </p> 
        },
            sortable: true,
        },
        {
            name: " الاسم الاول",
            selector: (row: { firstname: any }) => row.firstname,
        },
        {
            name: "الاسم الاخير",
            selector: (row: { lastname: any }) => row.lastname,
        },
        {
            name: "رقم الهاتف",
            selector: (row: { phoneNumber: any }) => row.phoneNumber,
        },
        {
            name: " حالة الحساب",
            selector: (row: Passenger) => (row?.active === false ? "غير مفعل" : "مفعل"),
        },
        {
            name: " الحالة",
            selector: (row: { Status: Status }) => row.Status.name,
        },
     
        {
            name: 'تارخ الإنشاء',
            selector: (row:  Passenger) => row.createdAt,
            cell: (row, index, column, id) => {
                return    <p className="">{new Date(row.createdAt).toLocaleDateString()} </p> 
        },
            sortable: true,
        },
        {
            name: "تفاصيل",
            cell: (row: Passenger, index, column, id) => {
                return   <Link to={`/passenger/${row.uuid}`} > <button className="btn btn-primary"> full details</button> </Link>
        },
        },
    ];

    export
    const notVerifiedPassengerColumns: PassengersColumn[] = [
        {
            name: '#',
            selector: (row:  any) => row.createdAt,
            cell: (row, index, column, id) => {
                return    <p className="">{index + 1} </p> 
        },
            sortable: true,
        },
        {
            name: " الاسم الاول",
            selector: (row: { firstname: any }) => row.firstname,
        },
        {
            name: "الاسم الاخير",
            selector: (row: { lastname: any }) => row.lastname,
        },
        {
            name: "رقم الهاتف",
            selector: (row: { phoneNumber: any }) => row.phoneNumber,
        },
        {
            name: "تأكيدالحساب",
            cell: (row, index, column, id) => {
                    return    row.active === true ? <p className="text-success">مؤكد </p> :   <p className="text-danger">غير مؤكد</p>
            }
        },
   
        {
            name: 'تارخ الإنشاء',
            selector: (row:  Passenger) => row.createdAt,
            cell: (row, index, column, id) => {
                return    <p className="">{new Date(row.createdAt).toLocaleDateString()} </p> 
        },
            sortable: true,
        },
        {
            name: "تفاصيل",
            cell: (row: Passenger, index, column, id) => {
                return   <Link to={`/passenger/${row.uuid}`} > <button className="btn btn-primary"> full details</button> </Link>
        },
        },
    ];
// new drviers
export
    const notConfirmedPassengerColumns: PassengersColumn[] = [
        {
            name: '#',
            selector: (row:  any) => row.createdAt,
            cell: (row, index, column, id) => {
                return    <p className="">{index + 1} </p> 
        },
            sortable: true,
        },
        {
            name: " الاسم الاول",
            selector: (row: { firstname: any }) => row.firstname,
        },
        {
            name: "الاسم الاخير",
            selector: (row: { lastname: any }) => row.lastname,
        },
        {
            name: "رقم الهاتف",
            selector: (row: { phoneNumber: any }) => row.phoneNumber,
        },

        {
            name: " حالة الحساب",
            cell: (row, index, column, id) => {
                return    row.Status.code === 111 ? <p className="text-success">{row.Status.name} </p> :   <p className="text-danger">{row.Status.name}</p>
        }
    },

    {
        name: 'تارخ الإنشاء',
        selector: (row:  Passenger) => row.createdAt,
        cell: (row, index, column, id) => {
            return    <p className="">{new Date(row.createdAt).toLocaleDateString()} </p> 
    },
        sortable: true,
    },
    {
        name: "تفاصيل",
        cell: (row: Passenger, index, column, id) => {
            return   <Link to={`/passenger/${row.uuid}`} > <button className="btn btn-primary"> full details</button> </Link>
    },
    },
    ];