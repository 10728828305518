import { Formik, Field } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Balance } from "../../../entities/Balance";
import { useBalance } from "../context/balance.context";
import { useDrivers } from "../context/DriversContext";

export default function AddBalance() {
  let navigate = useNavigate();
  const { driveruuid } = useParams();
  const [amount, setAmount] = React.useState<number>(0.0);
  const { addBalance } = useBalance();
  function changer(value) {
    setAmount(value);
  }

   function handlerSubmit() {
    if (amount > 0) {
        
       addBalance?.({
        amount,
        driveruuid
       });
      return navigate(`/driver/${driveruuid}`);
    }
  }

  React.useEffect(() => {
    return () => {};
  }, [amount]);

  return (
    <div className="px-3 mt-5">
      <div className="card  my-3 px-3 ">
        <div className="pt-3">
          <h3> أضافة رصيد </h3>
        </div>
        <div className="card-body ">
          <form onSubmit={(e) => e.preventDefault()} action="#">
            <label htmlFor="amount"> الكمية </label>
            <input
              className="form-control"
              type="number"
              name="amount"
              id=""
              value={amount}
              onChange={(e) => changer(Number(e.target.value))}
            />
            <button
              onClick={  handlerSubmit}
              className="my-3 btn btn-primary"
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
