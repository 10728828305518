import React, { createContext, useContext } from "react";
import { useEffect } from "react";
import { ProvidersProps } from "../../../../core/types";
import { useErrors } from "../../Common-components/Error/error.context";
import { useDrivers } from "../../Drivers/context/DriversContext";
import { usePassengers } from "../../passenger/context/PassengerContext";
import { usePrivateTrip } from "../../Priavate-Trip/private-trip.context";
import { useSharedTripTrip } from "../../Shared-Trip/shared-trip.context";
import { SearchRepository } from "../repository/search.repository";

const searchRepository: SearchRepository = new SearchRepository();

type SearchContextType = {
  loading?: false;
  setLoading?: Function | null;
  searchDrivers?: Function;
  searchPassengers?: Function;
  searchTrips?: Function;
  searchSharedTrips?: Function;
  
};
const SearchContext = createContext<SearchContextType>({
  loading: false,
  setLoading: null,
  searchDrivers: Function,
  searchPassengers: Function,
  searchTrips: Function,
  searchSharedTrips: Function,
});

export const SearchProvider: React.FunctionComponent<ProvidersProps> = ({
  children,
}) => {
  const { errors, setErrors } = useErrors();
  const { drivers } = useDrivers();
  const { passengers } = usePassengers();
  const { allPrivateTrip } = usePrivateTrip();
  const { allSharedTrip } = useSharedTripTrip();

  const searchPassengers = async ({ text }: { text: string }) : Promise<{} | undefined > => {
    try {
      const matchingPassenger = passengers?.filter(passenger => passenger.phoneNumber?.includes(text))
        
      return  matchingPassenger ?? []
    } catch (e: any) {
      console.log({
        e
      });
      setErrors((state: any) => [
        ...state,
        { message: e.error, code: e.statusCode },
      ]);
    }
  };
  const searchDrivers = async ({ text }: { text: string }) : Promise<{} | undefined > => {
    try {
    
      const matchingDrivers = drivers?.filter(driver => driver.phoneNumber?.includes(text))
        
      return  matchingDrivers ?? []
    } catch (e: any) {
     
      setErrors((state: any) => [
        ...state,
        { message: e.error, code: e.statusCode },
      ]);
    }
  };
  const searchTrips = async ({ text }: { text: string }) : Promise<{} | undefined > => {
    try {
      const matchingTrips = allPrivateTrip?.filter(trip => trip.uuid?.includes(text))
        
      return  matchingTrips ?? []
    } catch (e: any) {
  
      
      setErrors((state: any) => [
        ...state,
        { message: e.error, code: e.statusCode },
      ]);
    }
  };
  const searchSharedTrips = async ({ text }: { text: string }) : Promise<{} | undefined > => {
    try {
      const matchingTrips = allSharedTrip?.filter(trip => trip.uuid?.includes(text))
        
      return  matchingTrips ?? []
    } catch (e: any) {
  
      
      setErrors((state: any) => [
        ...state,
        { message: e.error, code: e.statusCode },
      ]);
    }
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <SearchContext.Provider
      value={{
        searchDrivers,
        searchPassengers,
        searchTrips,
        searchSharedTrips
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);
