
import { Link } from "react-router-dom";
import { SharedTrip } from "../../../entities/SharedTrip";
import { Trip } from "../../../entities/trip";
import { SharedTripsColumn, SharedTripsRow } from "./Trip";

export
    const allTripsColumns: SharedTripsColumn[] = [
        {
          name: "uuid",
            selector: (row: SharedTrip) => (`${row.uuid?.slice(0, 5) }`) ,
        },
        {
            name:  " أسم الراكب الأول",
            selector: (row: SharedTrip) => (`${row.masterPassenger?.firstname  } ${ row.masterPassenger?.lastname}`) ,
        },
       
        {
            name: "أسم السائق",
            selector: (row: SharedTrip) => (`${row.driver?.firstname ?? "_"  } ${ row.driver?.lastname ?? "_"}`) ,
        },
       
        {
            name: "نقطة البداية",
            selector: (row: SharedTrip) => row.startLocation?.name,
        },
        {
            name: "نقطة النهاية",
            selector: (row: SharedTrip) => row.endLocation?.name,
        },
        {
            name: "السعر",
            selector: (row: SharedTrip) => `${row.totalAmount} SDG`,
        },
        {
            name: "المسافة",
            selector: (row: SharedTrip) => `${row.distance} Km`,
        },
        {
            name: " الحالة",
            selector: (row: SharedTrip) => row.tripStatus?.name,
        },
        {
            name: 'تارخ الإنشاء',
            selector: (row:  SharedTrip) => row.createdAt,
            cell: (row, index, column, id) => {
                return    <p className="">{new Date(row.createdAt).toLocaleDateString()} </p> 
        },
            sortable: true,
        },
        {
            name: "تفاصيل",
            cell: (row: Trip, index, column, id) => {
                return   <Link to={`/shared-trip/${row.uuid}`} > <button className="btn btn-primary"> full details</button> </Link>
        },
        },
    ];

