import React from 'react'
import { Location } from '../../../entities/locations';
import GoogleMapReact from "google-map-react";

export default function ShowTwoLocations({startlocation, endLocation}: {startlocation: Location, endLocation: Location}) {
    const defaultProps = {
        center: {
          lat: 15.577608,
          lng: 32.568565,
        },
        zoom: 16,
      };
    const apiIsLoaded = (map, maps) => {
     
        
        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer();
        directionsRenderer.setMap(map);
        const origin = {
          lat: Number(startlocation.latitude) ??15.56855187,
          lng: Number(startlocation.longitude) ?? 32.53818356,
        };
        const destination = {
          lat: Number(endLocation.latitude) ?? 15.58359937,
          lng: Number(endLocation.longitude) ?? 32.57933938,
        };
    
        console.log({
            startlocation,
            endLocation,
            origin,
            destination
        });
        
        try {
          directionsService.route(
            {
              origin: origin,
              destination: destination,
              travelMode: google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
             
              
              if (status === google.maps.DirectionsStatus.OK) {
                const fullROute = result?.routes[0].overview_path.map((pa) => {
                  return { lat: pa.lat(), lng: pa.lng() };
                });
      
             
                console.log({
                  startName: result?.routes[0].legs[0].start_address,
                 endName: result?.routes[0].legs[0].end_address,
                  distance: result?.routes[0].legs[0].distance,
                  fullROute,
                });
      
                directionsRenderer.setDirections(result);
              } else {
                console.error(`error fetching directions ${result}`);
              }
            }
          );
        } catch (error) {
          console.log({
            error
          });
          
        }
       
      };



  return (
   <>
    <div className="card-body ">
        <div className="" style={{ height: "65vh" }}>
          <div className="w-100 h-100">
    <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyC6F_v_8d-6mmUZJYXIEh-EA_WFu2oUqEk",
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onGoogleApiLoaded={({ map, maps }) => {
          apiIsLoaded(map, maps);

        }}
  
      >
      

      </GoogleMapReact> 
          </div>
        </div>
      </div></>
  )
}
