import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Trip } from "../../../entities/trip";
import { useSearch } from "../../Search/context/search.context";
import { usePrivateTrip } from "../private-trip.context";
import { allTripsColumns } from "../Types/DataTable";
import { TripsRow } from "../Types/Trip";
import TripsTable from "./Trips-Table";

export function PrivateTrip({ id }: { id: number }) {
  const { allPrivateTrip } = usePrivateTrip();

  const [allTripsData, setAllallTripsData] = useState<TripsRow[]>([]);

  const [searchedTrips, setSearchedTrips] = useState<[]>([]);
  const { searchTrips } = useSearch();
  async function searchHandler(e) {
    if (e.target.value) {
      const data = await searchTrips?.({ text: e.target.value });

      const trips: [] = data || [];

      setSearchedTrips([...trips]);
    } else {
      setSearchedTrips([]);
    }
  }
  useEffect(() => {

    const sampleData: TripsRow[] = [];
    allPrivateTrip?.map((trip) => {
      sampleData.push({
        ...trip,
      });
    });

    setAllallTripsData(sampleData);
    return () => {};
  }, [allPrivateTrip, searchedTrips]);

  const [selectedTable, setSelectedTable] = useState(1);

  
  return (
    <>
     <div className="row">
        <div onClick={() => setSelectedTable(1)} className="col-md-6 col-lg-3">
          <div className="widget-small primary coloured-icon">
            <i className="icon fa fa-users fa-3x"></i>
            <div className="info">
              <h4> الرحل الكلية </h4>
              <p>
                <b> {allTripsData?.length || 0} </b>
              </p>
            </div>
          </div>
        </div>

        <div onClick={() => setSelectedTable(2)} className="col-md-6 col-lg-3">
          <div className="widget-small primary coloured-icon">
            <i className="icon fa fa-users fa-3x"></i>
            <div className="info">
              <h4>   الرحل المكتملة  </h4>
              <p>
                <b>
                  {" "}
                  {allTripsData?.filter((trip) => trip?.tripStatus?.code === 666)
                    ?.length || 0}{" "}
                </b>
              </p>
            </div>
          </div>
        </div>
        <div onClick={() => setSelectedTable(3)} className="col-md-6 col-lg-3">
          <div className="widget-small primary coloured-icon">
            <i className="icon fa fa-users fa-3x"></i>
            <div className="info">
              <h4>    الرحل الملغية </h4>
              <p>
                <b>
                {" "}
                  {allTripsData?.filter((trip) => trip?.tripStatus?.code  === 555)
                    ?.length || 0}{" "}
                </b>
              </p>
            </div>
          </div>
        </div>

    
        <div onClick={() => setSelectedTable(4)} className="col-md-6 col-lg-3">
          <div className="widget-small warning coloured-icon">
            <i className="icon fa fa-files-o fa-3x"></i>
            <div className="info">
              <h4>الرحل النشطة</h4>
              <p>
                <b>
                {" "}
                  {allTripsData?.filter((trip) => trip?.tripStatus?.code  !== 555)?.filter((trip) =>trip?.tripStatus?.code  !== 666)
                    ?.length || 0}{" "}
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
      <div className="card px-2 py-3">
        <div className="mb-5">
          <div className="position-relative">
            <form action="#">
              <label htmlFor="amount"> إبحث </label>
              <input
                className="form-control"
                type="text"
                name="search"
                autoComplete="off"
                placeholder="Trip uuid example: 8x1v5"
                onChange={searchHandler}
              />
            </form>
            <div
              className="card position-absolute"
              style={{ zIndex: 10000000 }}
            >
              <ul className="list-group " style={{ padding: "0" }}>
                {searchedTrips.map((trip: Trip, index) => {
                  return (
                    <li key={index} className="list-group-item">
                      <Link to={`/trip/${trip["uuid"]}`}>
                        <span> {trip["uuid"]} </span>
                        <div className="d-flex flex-row">
                          <div className="text-secondary">
                            <span>  الراكب </span>
                            <span> {trip?.["passenger"]?.["firstname"]} </span>
                            <span> {trip?.["passenger"]?.["lastname"]} </span>
                          </div>
                          <div className="mx-3 text-secondary">
                            <span>  السائق </span>
                            <span> {trip.driver ?  trip?.["driver"]?.["firstname"] : <span className="text-danger"> لا يوجد </span> } </span>
                            <span> {trip?.["driver"]?.["lastname"]} </span>
                          </div>
                        </div>
                        <div className="d-flex">
                        <p> التاريخ </p> :
                        <p className="mx-2">
                          {" "}
                          {new Date(
                            trip?.createdAt ?? ""
                          ).toLocaleDateString()}{" "}
                          {new Date(
                            trip?.createdAt ?? ""
                          ).toLocaleTimeString()}{" "}
                        </p>
                      </div>
                        <div>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        {<TripsTableShower data={allTripsData} id={selectedTable} />}
      </div>
    </div>
    </>
  
  );
}

export function TripsTableShower({ id, data, filtered }: { id: number; data: TripsRow[], filtered?: any }) {
  switch (id) {
    case 0:
      return (
        <TripsTable
          columns={allTripsColumns}
          data={data.filter((trip) =>trip?.tripStatus?.code  === 111).filter(trip => trip?.driver === null)}
        ></TripsTable>
      );
    case 1:
      return <TripsTable columns={allTripsColumns} data={data}></TripsTable>;
      break;
    case 2:
      return (
        <TripsTable
          columns={allTripsColumns}
          data={data
            .filter((trip) =>trip?.tripStatus?.code  === 666)}
        ></TripsTable>
      );
      break;
    case 3:
      return (
        <TripsTable
          columns={allTripsColumns}
          data={data
            .filter((trip) =>trip?.tripStatus?.code  === 555)}
        ></TripsTable>
      );
      break;
    case 4:
      return (
        <TripsTable
          columns={allTripsColumns}
          data={data
            .filter((trip) =>trip?.tripStatus?.code  !== 555)
            .filter((trip) =>trip?.tripStatus?.code  !== 666)}
        ></TripsTable>
      );
      break;


    default:
      return <></>;
      break;
  }
}
