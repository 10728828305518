import React, { createContext, useContext } from "react";
import { useEffect } from "react";
import { ProvidersProps } from "../../../core/types";
import { useErrors } from "../Common-components/Error/error.context";
import { io } from "socket.io-client";
import { SOCKET_BASE_URL } from "../../../core/common";
import { Driver } from "../../entities/Driver";
import { useDrivers } from "../Drivers/context/DriversContext";
import { Trip } from "../../entities/trip";
import { SharedTrip } from "../../entities/SharedTrip";

type LiveDriverType = {
  uuid: string;
  fcmID: string;
  location: {
    latitude: number;
    longitude: number;
  };
};
export type LiveDriversWithLocationType = {
  driver: Driver;
  location: {
    latitude: number;
    longitude: number;
  };
};

type LiveDataContextType = {
  loading?: false;
  setLoading?: Function | null;
  getFullLiveData?: Function;
  liveDriversWithLocation?: LiveDriversWithLocationType[] | null
  currentliveSharedTrips?: SharedTrip[] | null
  currentliveTrips?: Trip[] | null
  
  
};
const LiveDataContext = createContext<LiveDataContextType>({
  loading: false,
  setLoading: null,
  getFullLiveData: Function,
  liveDriversWithLocation: [],
  currentliveSharedTrips: [],
  currentliveTrips: []
});

export const LiveDataProvider: React.FunctionComponent<ProvidersProps> = ({
  children,
}) => {
  const { errors, setErrors } = useErrors();
  const [currentliveDrivers, setcurrnetLiveDrivers] = React.useState<LiveDriverType[]>([]);
  const [liveDriversWithLocation, setLiveDriversWithLocation] = React.useState<LiveDriversWithLocationType[]>([]);

  const [currentliveTrips, setcurrnetLiveTrips] = React.useState<Trip[]>([]);
  const [currentliveSharedTrips, setcurrnetLiveSharedTrips] = React.useState<Trip[]>([]);


  const { drivers } = useDrivers();
//   const { passengers } = usePassengers();
//   const { allPrivateTrip } = usePrivateTrip();
//   const { allSharedTrip } = useSharedTripTrip();





  let socket;
  useEffect(() => {
    // socket = io("http://localhost:1000");
    socket = io(SOCKET_BASE_URL)

    socket.on("connect", (socketid) => {
      socket.on("ALL_DRIVERS_LOCATION_SENDED", (data) => {
        setcurrnetLiveDrivers([...data.data["activeDrivers"]]);
        
        setcurrnetLiveSharedTrips([...data.data['onGoingSharedTrips']])
        setcurrnetLiveTrips([...data.data['onGoingPrivateTrips']])
        // console.log({
        //   privateTrip: data.data,
        //   SharedTrip: data.data,
        // });
        

    
      });
    });
    return () => {};
  }, []);

  useEffect(() => {
    const fullDriverHolderArray= [] as Array<LiveDriversWithLocationType>;
    currentliveDrivers.map(driver => {
     const signedInDriver = drivers?.find(d => d.uuid === driver.uuid);

     
     if(signedInDriver) {
      fullDriverHolderArray.push({
        location: {
          ...driver.location
        },
        driver: { ...signedInDriver}
      })
     }
    })

    setLiveDriversWithLocation(fullDriverHolderArray)
  
    return () => {
      
    }
  }, [currentliveDrivers])
  


  return (
    <LiveDataContext.Provider
      value={{
        liveDriversWithLocation,
        currentliveSharedTrips,
        currentliveTrips
      }}
    >
      {children}
    </LiveDataContext.Provider>
  );
};

export const useLiveData = () => useContext(LiveDataContext);
