import React from "react";
import { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import car from "./car.svg";
import { io } from "socket.io-client";
import { Icon } from "@iconify/react";
import locationIcon from "@iconify/icons-mdi/map-marker";

const AnyReactComponent = ({
  text,
  lat,
  lng,
  com,
}: {
  text: any;
  lat: number;
  lng: number;
  com: any;
}) => (
  <div>
    {text} {com}
  </div>
);

export default function ServiceCenterPickupLocationMap({locationSetter}: {locationSetter: Function}) {
  const defaultProps = {
    center: {
      lat: 15.577608,
      lng: 32.568565,
    },
    zoom: 16,
  };

  const [CurrentSelectedLocation, setCurrentSelectedLocation] =
    useState<CurrentLocation>({ latitude: null, longitude: null });



  useEffect(() => {
locationSetter({...CurrentSelectedLocation})

    return () => {};
  }, [CurrentSelectedLocation,  ]);



  console.log({
    CurrentSelectedLocation
  });
  
  return (
    // Important! Always set the container height explicitly
    <div className="card">
      <div className="card-body ">
        <div className="" style={{ height: "65vh" }}>
          <div className="w-100 h-100">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyC6F_v_8d-6mmUZJYXIEh-EA_WFu2oUqEk",
              }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              onClick={(clicked) => {
                // console.log({
                //   CurrentSelectedLocation:  CurrentSelectedLocation,
                //   secondCurrentSelectedLocation: secondCurrentSelectedLocation
                // });
                
                setCurrentSelectedLocation({
                    latitude: clicked.lat,
                    longitude: clicked.lng,
                  })
              
              }}
            >
     

              <AnyReactComponent
                lat={CurrentSelectedLocation?.latitude ?? 0.0}
                lng={CurrentSelectedLocation?.longitude ?? 0.0}
                text=""
                com={
                  <>
                    {" "}
                    <div className="pin">
                      <Icon color="ffc107" fontSize={60} icon={locationIcon} className="pin-icon" />
                      <p className="pin-text"></p>
                    </div>{" "}
                  </>
                }
              />

            </GoogleMapReact>
          </div>
        </div>
      </div>
    </div>
  );
}

type CurrentLocation = {
  latitude: number | null;
  longitude: number | null;
};
