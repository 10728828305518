import { api } from "../../../../core";
import { ServiceCenter } from "../../../entities/ServiceCenter";

export class ServiceCenterRepository {

  async getServiceCenters() {
    try {

      const res = await api.get(`/api/dashboard/services-centers/all`,
        {
          withCredentials: true,
          headers: {
            "authorization": localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
            "x-api-key":
              "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
            "Content-Type": "application/json",
          },
        }
      );


      return { data: res.data.data }

    } catch (e: any) {

      throw e.response.data ?? { error: e.message, statusCode: 400 };

    }
  }

  async addServiceCenter({ input }: { input: ServiceCenter }) {
    try {

      const res = await api.post(`/api/dashboard/services-centers/add`,
        {
          ...input
        },
        {
          withCredentials: true,
          headers: {
            "authorization": localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
            "x-api-key":
              "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
            "Content-Type": "application/json",
          },
        }
      );


      return { data: res.data.data }

    } catch (e: any) {

      throw e.response.data ?? { error: e.message, statusCode: 400 };

    }
  }
  async updateServiceCenter({uuid, input }: { uuid : string,input: ServiceCenter }) {
    try {
   
      const res = await api.put(`/api/dashboard/services-centers/update/${uuid}`,
      {
        name: input.name,
        description: input.description,
        latitude: input.latitude,
        longitude: input.longitude,
       },
        {
          withCredentials: true,
          headers: {
            "authorization": localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
            "x-api-key":
              "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
            "Content-Type": "application/json",
          },
        }
      );


      return { data: res.data.data }

    } catch (e: any) {

      throw e.response.data ?? { error: e.message, statusCode: 400 };

    }
  }
  async activateOrdeactivateServiceCenter({uuid }: { uuid : string}) {
    try {
   
      const res = await api.get(`/api/dashboard/services-centers/activate-deactivate/${uuid}`,
        {
          withCredentials: true,
          headers: {
            "authorization": localStorage.getItem("share-taxi-dashboard-access-token") ?? "",
            "x-api-key":
              "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
            "Content-Type": "application/json",
          },
        }
      );


      return { data: res.data.data }

    } catch (e: any) {

      throw e.response.data ?? { error: e.message, statusCode: 400 };

    }
  }

}