import React from "react";
import { useAdmin } from "../../Context/admin.context";
import { Admin } from "../../../../entities/Admin";
import { Navigate, useLocation } from "react-router-dom";
import Errormessage from "../../../Common-components/Error/Error-message";

export default function Login({from}: {from: string}) {
  const { loginAdmin, admin , adminloading} = useAdmin();
  const [user, setUser] = React.useState<Admin>({});
  const location  = useLocation();
  console.log({
    location
  });
  
  function changer(value) {
    setUser({
      ...value,
    });
  }
 
  if (admin) {
    if(from === "") {
    return <Navigate to="/" />; 

    }
    //Not signed in
    return <Navigate to={from} />; 
  }
  return (
      <div className="container h-100 ">
      <Errormessage />
<div className="car-body  row h-100  d-flex align-items-center justify-content-center ">
  <div className="col-6">
    <div className="flex  align-items-center justify-content-center text-center">
      <h3 className="my-5"> share taxi</h3>

      <h3> تسجيل دخول </h3>
    </div>
    <form
      onSubmit={ async (e) => {
        e.preventDefault();

        // if(user.phoneNumber !== "" && user.password !== user.password) {
        await loginAdmin?.({
          phoneNumber: user.phoneNumber,
          password: user.password,
        });
        // }
      }}
      action="#"
    >
      <input
        name="phoneNumber"
        className="form-control my-3"
        type="text"
        placeholder="رقم الهاتف"
        onChange={(e) =>
          changer({ ...user, phoneNumber: e.target.value })
        }
      />
      <input
        name="password"
        className="form-control my-3"
        type="password"
        placeholder=" كلمة السر"
        onChange={(e) => changer({ ...user, password: e.target.value })}
      />
      <div className="d-flex align-items-stretch">
        <button className=" btn btn-primary" type="submit">
          تسجيل
        </button>
      </div>
    </form>
  </div>
</div>
</div>
   
  );
}
