import { BaseService } from "../../../../core/Base.service";
import { Trip } from "../../../entities/trip";
import { TripRepository } from "../repository/trip.repository";


const tripRepository: TripRepository = new TripRepository();

export class TripService implements BaseService<Trip> {
  async  create({ input }: { input?: Trip  }): Promise<Trip | null> {
     try {
        const trip = await tripRepository.create({input: {...input}});

        return trip
     } catch (error) {
           throw new Error("Method not implemented.");
     }
    }
 async   find({ filter }: { filter: {}  }): Promise<Trip[] | null> {
     try {
        const trips = await tripRepository.find({filter: {...filter}});

        return trips
     } catch (error) {
           throw new Error("Method not implemented.");
     }
    }
    async findOne({ filter }: { filter?: {}  }): Promise<Trip | null> {
     try {
        const trip = await tripRepository.findOne({filter: {...filter}});

        return trip
     } catch (error) {
           throw new Error("Method not implemented.");
     }
    }
 async   update({ filter, input }: { filter: Trip; input?: Trip  }): Promise<boolean | null> {
     try {
        const trip = await tripRepository.update({uuid: filter.uuid!,input: {...input}});

        return trip
     } catch (error) {
           throw new Error("Method not implemented.");
     }
    }
 async   passengerCancelTrip({ filter, input }: { filter?: Trip; input?: Trip  }): Promise<Trip | null> {
     try {
        const trip = await tripRepository.passengerCancelTrip({input: {...input}});

        return trip
     } catch (error) {
           throw new Error("Method not implemented.");
     }
    }
    async delete({ filter }: { filter: Trip; }): Promise<boolean | null> {
     try {
        const trip = await tripRepository.delete({uuid: filter.uuid!});

        return trip
     } catch (error) {
           throw new Error("Method not implemented.");
     }
    }
 
    }
