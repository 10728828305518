import logo from "./logo.svg";
import "./App.css";
import Home from "./app/components/Home";
import SideBar from "./app/components/SideBar";
import Main from "./app/components/Main";
import Channels from "./app/components/Channels/channels";
import React, { ReactNode } from "react";
import Navbar from "./app/components/Navbar";
interface Props {
  children?: ReactNode;
  // any props that come into the component
}
function App({ children }: Props) {
  return (
    <>
      <Navbar />
      <SideBar />
      <main className="app-content">{children}
      </main>
    </>
  );
}

export default App;
