import { create } from "domain";
import React, { useState, useEffect } from "react";
import { Location } from "../../../../entities/locations";
import { Trip } from "../../../../entities/trip";
import { useChannel } from "../../../Channels/channel.context";
import PickupLocationMap, { ShowPickupLocationMap } from "../../../Map/pickup-location";
import { usePassengers } from "../../../passenger/context/PassengerContext";
import { usePrivateTrip } from "../../private-trip.context";
import { useParams, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
export default function CreateTrip() {

  const navigate = useNavigate();
  const { createTrip, createTripLoading } = usePrivateTrip();
  const { passengers } = usePassengers();

  const [createdTrip, setCreatedTrip] = useState<Trip | null>();
  const [locations, setlocations] = useState<{
    startLocation?: Location;
    endLocation?: Location;
  } | null>({
    startLocation: {},
    endLocation: {},
  });
  const { phoneNumber } = useParams();
  function changer(value: Trip) {
    
    setCreatedTrip({ ...createdTrip, ...value });
  }



    //2 modal
    const [showSec, setShowSec] = useState(false);
    const handleCloseSec = () => setShowSec(false);
    const handleShowSec = () => setShowSec(true);

  async function handlSubmit() : Promise<Trip | null> {
    console.log({
      locations
    });
    
    const trip =  await createTrip?.({
      input: {
        ...createdTrip,
        paymentcode: createdTrip?.paymentcode,
        // passengeruuid: passengeruuid,
        note: "passenger note",
        startLocation: {
          name: createdTrip?.startLocation?.name,
          ...locations?.startLocation,
        },
        endLocation: {
          name: createdTrip?.endLocation?.name,

          ...locations?.endLocation,
        },
        route: JSON.stringify([
          { ...locations?.startLocation },
          { ...locations?.endLocation },
        ]),
      },
    });


    return trip?.trip;
  }
  const { channels } = useChannel();

  useEffect(() => {
    setCreatedTrip({
      paymentcode: "111",
      channeluuid: channels?.filter(channel => channel.statuscode === 111)?.[0].uuid,
      passengeruuid: passengers?.find((pass) =>
        pass.phoneNumber?.includes(phoneNumber?.toString() ?? "")
      )?.uuid,
    });

    return () => {};
  }, [locations,createTripLoading]);

  return (
    <div className="card my-3">

      <div className="card-body">
        <div className="form-group">
          <label htmlFor="exampleSelect1"> القنوات </label>
          <select
            value={createdTrip?.channel?.uuid}
            onChange={(e) => changer({ channeluuid: e.target.value })}
            className="form-control"
            id="exampleSelect1"
          >
            {channels?.filter(channel => channel.statuscode === 111)?.map((channel) => {
              return (
                <option key={channel.uuid} value={channel.uuid}>
                  {channel.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="exampleSelect1">طريقة الدفع </label>
          <select
            value={createdTrip?.channel?.code?.toString()}
            onChange={(e) =>
              changer({ paymentcode: e.target.value.toString() })
            }
            className="form-control"
            id="exampleSelect1"
          >
            <option key={111} value={"111"}>
              بنكك
            </option>
            <option key={222} value={"222"}>
              كاش
            </option>
          </select>
        </div>
      </div>

      <div className="mx-3">
       {
        <PickupLocationMap
          secLocationSetter={(secData) => {
            changer({
              endLocation: {
                ...secData,
              }
            })
            setlocations({
              ...locations,
              endLocation: {
                ...secData,
              },
            });
          }}
          locationSetter={(firstdata) => {
            changer({
              startLocation: {
                ...firstdata,
              }
            })
            setlocations({
              ...locations,
              startLocation: { ...firstdata },
            });
          }}
        />
       } 
      </div>

      <button
        onClick={async () => {
          handleShowSec()
          // handlSubmit().then((trip) => {
       
            
     
            
          //   if(trip && createTripLoading === false) {
          //     // navigate(`/trip/${trip?.uuid}`)
        
          //   }
          // });
          // await createPassenger?.({...newPassenger,phoneNumber: number?.toString(), password: "123456"})

          // navigate(`/passenger/creaet-trip/${number}`)
        }}
        className="my-2 mx-2 btn btn-primary"
      >
        عرض رحلة
      </button>


      <Modal
        show={showSec}
        onHide={handleCloseSec}
        backdrop="static"
        keyboard={false}
        
      >
        <Modal.Header closeButton>
          <Modal.Title> بيانات  الرحلة</Modal.Title>
        </Modal.Header>
        <Modal.Body>
       {
        locations?.endLocation?.longitude ? <>
   
        <ShowPickupLocationMap tripDataGetter={(data) => {
  
 
          changer({
            distance: data.distance,
            route:data.route,
            startLocation: { name: data.startLocationName},
            endLocation: { name: data.endLocationName },

          })
        }} startlocation={ {...locations?.startLocation } } endLocation={{...locations.endLocation}} />
        
        </> : <></>

       }
        </Modal.Body>
        <Modal.Footer>
        <button
        onClick={async () => {
          handlSubmit().then((trip) => {
          handleCloseSec()
            
            if(trip && createTripLoading === false) {
              // createTripFirebase?.({input: {...trip}})
              navigate(`/passenger/trip-created/${trip?.uuid}`)
        
            }
          });
    
        }}
        className="my-2 mx-2 btn btn-primary"
      >
        إنشاء رحلة
      </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
