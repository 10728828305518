import React from 'react'
import { ApkVersion } from '../../entities/apk-version';
import ApkVersionTable from './apk-version-table';
import { useApkVersion } from './context/apk-version.context'
import { ApkTypesColumn, ApkTypesRow } from './types/apk-types';

export default function ApkVersions() {
    const {apkVersions} = useApkVersion();

    const [data, setData] = React.useState<ApkTypesRow[]>([]);
    React.useEffect(() => {
      const sampleData: ApkTypesRow[] = []
      apkVersions?.map(version=> {
          sampleData.push({
         ...version
            
          })
      })
      
      setData(sampleData)
      return () => {
        
      }
    }, [apkVersions])
    
    const columns : ApkTypesColumn[] = [
     
        {
            name: 'اخر تحديث',
            selector:  (row: ApkVersion) => row.version,
        },
        {
            name: ' مهم',
            selector: (row: ApkVersion) => row?.important?.toString(),
        },
        {
            name: ' التطبيق',
            selector: (row: ApkVersion) => row?.type?.name
        },
    ];
  
  return (
    <div>

<ApkVersionTable  columns={columns} data={data} />

    </div>
  )
}
