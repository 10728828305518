import React, { ReactElement } from "react";
import { Admin } from "../entities/Admin";

export default function ProtectedComponentsWrapper({
  children,
  allow,
  admin,
}: {
  children: any;
  allow: Array<Number>;
  admin?: Admin;
}) {

  React.useEffect(() => {
    return () => {};
  }, []);

  const ele =  allow.filter((allowable) =>allowable === admin?.rolecode );

  if(ele.length > 0) {
    return children;
  } else {
    <></>
  }
}
