import React, {useState, useEffect} from "react";
import { useLiveData } from "../LiveData/live-data.context";
import { TripsTableShower } from "../Priavate-Trip/Components/Private-trip";
import { usePrivateTrip } from "../Priavate-Trip/private-trip.context";
import { TripsRow } from "../Priavate-Trip/Types/Trip";
import { SharedTripsTableShower } from "../Shared-Trip/components/shared-trips";

export default function LiveDataPage() {
  const { allPrivateTrip } = usePrivateTrip();
  const {currentliveTrips,currentliveSharedTrips} = useLiveData();
  const [allTripsData, setAllallTripsData] = useState<TripsRow[]>([]);
  const [selectedTable, setSelectedTable] = useState(1);
  useEffect(() => {

    const sampleData: TripsRow[] = [];
    allPrivateTrip?.map((trip) => {
      sampleData.push({
        ...trip,
      });
    });

    setAllallTripsData(sampleData);
    return () => {};
  }, [allPrivateTrip]);
  return (
    <>
      <div className="app-title">
        <div>
          <h1>
            <i className="fa fa-dashboard"></i> بيانات جارية
          </h1>
        </div>
        <ul className="app-breadcrumb breadcrumb">
          <li className="breadcrumb-item">
            <i className="fa fa-home fa-lg"></i>
          </li>
          <li className="breadcrumb-item">
            <a href="#"> بيانات جارية</a>
          </li>
        </ul>
      </div>
      <h2>بيانات جارية الآن (Live)</h2>
      <div className="row">
        <div onClick={() => setSelectedTable(1)} className="col-md-6 col-lg-3">
          <div className="widget-small primary coloured-icon d-flex justify-content-between">
            <i className="icon fa fa-users fa-3x"></i>
            <div className="info ">
              <h4>رحلات خاصة قيد الانتظار</h4>
            </div>
            <div className="d-flex  align-items-center mx-2">
              <b className="h4"> {currentliveTrips?.length}</b>
            </div>
          </div>
        </div>
        <div onClick={() => setSelectedTable(2)} className="col-md-6 col-lg-3">
          <div className="widget-small primary coloured-icon d-flex justify-content-between">
            <i className="icon fa fa-users fa-3x"></i>
            <div className="info ">
              <h4>رحلات مشتركة قيد الانتظار</h4>
            </div>
            <div className="d-flex  align-items-center mx-2">
              <b className="h4"> {currentliveSharedTrips?.length}</b>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div className="widget-small warning coloured-icon">
            <i className="icon fa fa-files-o fa-3x"></i>
            <div className="info">
              <h4>الرحلات الممغيه</h4>
              <p>
                <b>{0} </b>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="widget-small danger coloured-icon">
            <i className="icon fa fa-star fa-3x"></i>
            <div className="info">
              <h4> تحويل االرحلات المقترحة</h4>
              <p>
                <b>{0}</b>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
        { selectedTable  === 1 ?  <TripsTableShower data={currentliveTrips ?? []} id={0}   /> 
        
      : <SharedTripsTableShower data={currentliveSharedTrips ?? []} id={0} />
      }
        </div>
      </div>
    </>
  );
}
