import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SharedTrip } from "../../../entities/SharedTrip";
import { useSearch } from "../../Search/context/search.context";
import { useSharedTripTrip } from "../shared-trip.context";
import { allTripsColumns } from "../Types/DataTable";
import { SharedTripsRow } from "../Types/Trip";
import SharedTripsTable from "./Shared-Trips-Table";

export default function SharedTrips({ id }: { id: number }) {
  const [searchedTrips, setSearchedTrips] = useState<[]>([]);
  const { searchSharedTrips } = useSearch();
  async function searchHandler(e) {
    if (e.target.value) {
      const data = await searchSharedTrips?.({ text: e.target.value });

      const trips: [] = data || [];

      setSearchedTrips([...trips]);
    } else {
      setSearchedTrips([]);
    }
  }

  const { allSharedTrip, ongoingSharedTrip } = useSharedTripTrip();

  const [allTripsData, setAllallTripsData] = useState<SharedTripsRow[]>([]);
  useEffect(() => {
    const sampleData: SharedTripsRow[] = [];
    allSharedTrip?.map((trip) => {
      sampleData.push({
        ...trip,
      });
    });

    setAllallTripsData(sampleData);
    return () => {};
  }, [allSharedTrip]);


  const [selectedTable, setSelectedTable] = useState(1);
  return (
    <>
        <div className="row">
        <div onClick={() => setSelectedTable(1)} className="col-md-6 col-lg-3">
          <div className="widget-small primary coloured-icon">
            <i className="icon fa fa-users fa-3x"></i>
            <div className="info">
              <h4> الرحل الكلية </h4>
              <p>
                <b> {allTripsData?.length || 0} </b>
              </p>
            </div>
          </div>
        </div>

        <div onClick={() => setSelectedTable(2)} className="col-md-6 col-lg-3">
          <div className="widget-small primary coloured-icon">
            <i className="icon fa fa-users fa-3x"></i>
            <div className="info">
              <h4>   الرحل المكتملة  </h4>
              <p>
                <b>
                  {" "}
                  {allTripsData?.filter((trip) => trip.tripStatuscode === 666)
                    ?.length || 0}{" "}
                </b>
              </p>
            </div>
          </div>
        </div>
        <div onClick={() => setSelectedTable(3)} className="col-md-6 col-lg-3">
          <div className="widget-small primary coloured-icon">
            <i className="icon fa fa-users fa-3x"></i>
            <div className="info">
              <h4>    الرحل الملغية </h4>
              <p>
                <b>
                {" "}
                  {allTripsData?.filter((trip) => trip.tripStatuscode === 555)
                    ?.length || 0}{" "}
                </b>
              </p>
            </div>
          </div>
        </div>

    
        <div onClick={() => setSelectedTable(4)} className="col-md-6 col-lg-3">
          <div className="widget-small warning coloured-icon">
            <i className="icon fa fa-files-o fa-3x"></i>
            <div className="info">
              <h4>الرحل النشطة</h4>
              <p>
                <b>
                {" "}
                  {allTripsData?.filter((trip) => trip.tripStatuscode !== 555)?.filter((trip) => trip.tripStatuscode !== 666)
                    ?.length || 0}{" "}
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="card px-3 py-5">
      <div className="mb-5">
        <div className="position-relative">
          <form action="#">
            <label htmlFor="amount"> إبحث </label>
            <input
              className="form-control"
              type="text"
              name="search"
              autoComplete="off"
              placeholder="Trip uuid example: 8x1v5"
              onChange={searchHandler}
            />
          </form>
          <div className="card position-absolute" style={{ zIndex: 10000000 }}>
            <ul className="list-group " style={{ padding: "0" }}>
              {searchedTrips.map((sharedTrip: SharedTrip, index) => {
                return (
                  <li key={index} className="list-group-item">
                    <Link to={`/shared-trip/${sharedTrip["uuid"]}`}>
                      <span> {sharedTrip["uuid"]} </span>
                      <div className="d-flex flex-row">
                        <div className="text-secondary">
                          <span> الراكب </span>
                          <span>
                            {" "}
                            {
                              sharedTrip?.["masterPassenger"]?.["firstname"]
                            }{" "}
                          </span>
                          <span>
                            {" "}
                            {sharedTrip?.["masterPassenger"]?.["lastname"]}{" "}
                          </span>
                        </div>
                        <div className="mx-3 text-secondary">
                          <span> السائق </span>
                          <span>
                            {" "}
                            {sharedTrip.driver ? (
                              sharedTrip?.["driver"]?.["firstname"]
                            ) : (
                              <span className="text-danger"> لا يوجد </span>
                            )}{" "}
                          </span>
                          <span> {sharedTrip?.["driver"]?.["lastname"]} </span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <p> التاريخ </p> :
                        <p className="mx-2">
                          {" "}
                          {new Date(
                            sharedTrip?.createdAt ?? ""
                          ).toLocaleDateString()}{" "}
                          {new Date(
                            sharedTrip?.createdAt ?? ""
                          ).toLocaleTimeString()}{" "}
                        </p>
                      </div>
                      <div></div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div>{<SharedTripsTableShower data={allTripsData} id={selectedTable} />}</div>
    </div>
    </>
 
  );
}

export function SharedTripsTableShower({
  id,
  data,
}: {
  id: number;
  data: SharedTripsRow[];
}) {
  switch (id) {
    case 0:
      return (
        <SharedTripsTable
          columns={allTripsColumns}
          data={data .filter((trip) => trip.tripStatuscode === 111).filter(trip => trip.driver === null)}
        ></SharedTripsTable>
      );
    case 1:
      return (
        <SharedTripsTable
          columns={allTripsColumns}
          data={data}
        ></SharedTripsTable>
      );

      break;
    case 2:
      return (
        <SharedTripsTable
          columns={allTripsColumns}
          data={data
            .filter((trip) => trip.tripStatuscode !== 666)}
        ></SharedTripsTable>
      );
      break;
    case 3:
      return (
        <SharedTripsTable
          columns={allTripsColumns}
          data={data
            .filter((trip) => trip.tripStatuscode === 555)}
        ></SharedTripsTable>
      );
      break;
    case 4:
      return (
        <SharedTripsTable
          columns={allTripsColumns}
          data={data
            .filter((trip) => trip.tripStatuscode !== 555)
            .filter((trip) => trip.tripStatuscode !== 666)}
        ></SharedTripsTable>
      );
      break;

    default:
      return <></>;
      break;
  }
}
