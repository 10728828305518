import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAdmin } from "./Admins/Context/admin.context";

export default function Navbar() {
  const [showSideBar, setShowSideBar] = useState(false);
  const [showAdminMenu, setShowAdminMenu] = useState(false);
  const { logoutAdmin} = useAdmin();

  function toggleSideBar() {
    setShowSideBar(!showSideBar);
    if (showSideBar === false) {
      document.body.classList.add("pace-done", "sidenav-toggled");
    } else {
      document.body.classList.remove("pace-done", "sidenav-toggled");
    }
  }
  return (
    <header className="app-header">
      {" "}
      <Link to="/">
        <div className="app-header__logo">Share Taxi</div>
      </Link>
      <div
        className="app-sidebar__toggle"
        onClick={toggleSideBar}
        data-toggle="sidebar"
        aria-label="Hide Sidebar"
      ></div>
      <ul className="app-nav">
        <li className={ showAdminMenu ? "dropdown show" :"dropdown" }>
          <div
            className="app-nav__item"
          onClick={() => {
            setShowAdminMenu(!showAdminMenu)
          }}
            data-toggle={ showAdminMenu ? "dropdown show" :"dropdown" }
            aria-label="Open Profile Menu"
          >
            <i className="fa fa-user fa-lg"></i>
          </div>
          <ul className= { showAdminMenu ? "dropdown-menu settings-menu dropdown-menu-right show" : "dropdown-menu settings-menu dropdown-menu-right" } >
            <li>
              <Link to="/user">
                <div className="dropdown-item">
                  <i className="fa fa-user fa-lg"></i> الحساب
                </div>
              </Link>
            </li>
            <li>
            <div onClick={() => logoutAdmin?.()} className="dropdown-item">
                <i className="fa fa-sign-out fa-lg"></i> تسجيل خروج
              </div>
             
            </li>
          </ul>
        </li>
      </ul>
    </header>
  );
}
