import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { Driver } from "../../../entities/Driver";
import { DriversColumn, DriverssRow } from "../Types/Driver";

export default function DriversTable({
  columns,
  data,
}: {
  columns: DriversColumn[];
  data: DriverssRow[];
}) {
  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedDriver, setSelectedDriver] = useState<Driver>({});
  function openDriver(row) {
    setSelectedDriver({ ...row });
    handleShow();
  }
  return (
    <>
      <DataTable
        pagination
        columns={columns}
        paginationPerPage={200}
        data={data}
        selectableRows
        onRowClicked={(row) => openDriver(row)}
      />
      
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>تفاصيل السائق</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex-col justify-content-between ">
            <div className="d-flex justify-content-between my-3">
              <span> الاسم بالكامل </span>
              <span>
                {selectedDriver.firstname} {selectedDriver.lastname}
              </span>
            </div>

            <div className="d-flex justify-content-between my-3">
              <span> رقم الهاتف </span>
              <span>{selectedDriver.phoneNumber}</span>
            </div>

            <div className="d-flex justify-content-between my-3">
              <span> الايميل </span>
              <span>{selectedDriver.email}</span>
            </div>

            <div className="d-flex justify-content-between my-3">
              <span> الرصيد </span>
              <span>{selectedDriver.balance?.total} SDG</span>
            </div>

            <div className="d-flex justify-content-between my-3">
              <span> رقم الرخصة</span>
              <span>{selectedDriver.driverLicense}</span>
            </div>

            <div className="d-flex justify-content-between my-3">
              <span> تاريخ الانضمام </span>
              <span>{selectedDriver.createdAt}</span>
            </div>

            <div className="d-flex justify-content-between my-3">
              <span>
                {" "}
                <strong> بيانات السيارة الاساسية</strong>{" "}
              </span>
              <span></span>
            </div>

            <div className="d-flex justify-content-between my-3">
              <span> الشركة </span>
              <span>{selectedDriver?.defaultcar?.brand}</span>
            </div>

            <div className="d-flex justify-content-between my-3">
              <span> النوع </span>
              <span>{selectedDriver?.defaultcar?.model}</span>
            </div>
            <div className="d-flex justify-content-between my-3">
              <span> اللوحة </span>
              <span>{selectedDriver?.defaultcar?.licensePlate}</span>
            </div>
            <div className="d-flex justify-content-between my-3">
              <span> الحالة </span>
              <span>{selectedDriver?.defaultcar?.status?.name}</span>
            </div>

            <div className="d-flex justify-content-between my-3">
              <span> سنة الصنع </span>
              <span>{selectedDriver?.defaultcar?.year}</span>
            </div>

            <div className="d-flex justify-content-between my-3">
              <span> اللون </span>
              <span>{selectedDriver?.defaultcar?.color}</span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            غلق
          </Button>
          <Link to={`/driver/${selectedDriver.uuid}`}>
            <Button variant="primary">كل التفاصيل</Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
}
