import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApkVersion } from "../../entities/apk-version";
import { useApkVersion } from "./context/apk-version.context";

export default function EditApkVersion() {
  const { uuid } = useParams();
  let navigate = useNavigate();
  const { apkVersions, updateApkVersion } = useApkVersion();
  const [updatedVersion, setupdatedVersion] = React.useState<ApkVersion | null>(
    {}
  );
  function changer(value) {
    setupdatedVersion({
      ...value,
    });
  }

    const [options, setOptions] = React.useState({ op1: false, op2: true });
  async function handlerSubmit() {

  
    await updateApkVersion?.({
        uuid: updatedVersion?.uuid,
        version: updatedVersion?.version,
        important: options.op1 === true ?  options.op1 : false,
    })
  
      navigate("/apks")
  }

  React.useEffect(() => {
    setupdatedVersion({ ...apkVersions?.find((d) => d.uuid === uuid) });
    setOptions({
        op1: true,
        op2: false
    })
    return () => {};
  }, []);


  
  return (
    <div className="px-3 mt-5">
      <div className="card  my-3 px-3 ">
        <div className="pt-3">
          <h3> تعديل الصلاحية </h3>
        </div>
        <div className="card-body ">
          <form onSubmit={(e) => e.preventDefault()} action="#">
            <div>
              <label htmlFor="amount"> الاسم </label>
              <input
                className="form-control"
                type="number"
                name="version"
                id=""
                value={updatedVersion?.version}
                onChange={(e) =>
                  changer({ ...updatedVersion, version: e.target.value })
                }
              />
            </div>
            <div className="form-check">
              <label className="form-check-label">مهم</label>
              <input
                className="form-check-input mx-3"
                id="optionsRadios1"
                type="radio"
                name="optionsRadios"
                value="option1"
                checked= {options.op1}
                onChange={ e => setOptions({op1: true, op2: false})}
              />
              <br />
              <label className="form-check-label">غير مهم</label>
              <input
                className="form-check-input mx-3"
                id="optionsRadios1"
                type="radio"
                name="optionsRadios"
                value="option1"
                onChange={ e => setOptions({op1: false, op2: true})}
              />
            </div>

            <button
              onClick={(e) => handlerSubmit()}
              className="my-3 btn btn-primary"
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
