import axios from "axios";
import { BASE_URL, SOCKET_BASE_URL } from "./common";

 export class API {
    constructor(private baseURL: string) {}

    init() {
        return axios.create({
            baseURL: this.baseURL,
            headers: {
                "x-api-key":
                "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
              "Content-Type": "application/json",
            },
            
        });
    }
}
 export class SEARCHAPI {
    constructor(private searchBaseURL: string) {}

    init() {
        return axios.create({
            baseURL: this.searchBaseURL,

            
        });
    }
}
 export class SOCKETAPI {
    constructor(private searchBaseURL: string) {}

    init() {
        return axios.create({
            baseURL: this.searchBaseURL,

            
        });
    }
}
export const api = new API(`${BASE_URL}/`).init(); 
export const socketapi = new SOCKETAPI(`${SOCKET_BASE_URL}/`).init(); 
export const searchapi = new SEARCHAPI(`http://localhost:2023/`).init(); 