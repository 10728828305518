import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../../core/common";
import { SharedTrip } from "../../../entities/SharedTrip";
import { Trip } from "../../../entities/trip";
import { useSharedTripTrip } from "../shared-trip.context";

export default function SingleSharedTrip() {
  const { uuid } = useParams();
  const { allSharedTrip, getSharedTrip, selectedTrip,sharedTriploading} = useSharedTripTrip();

  useEffect(() => {

    getSharedTrip?.({uuid: uuid})
    return () => {};
  }, []);

  if (sharedTriploading === true) {
    return (
      <div className="card">
        <div className="card-body">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <h3> بيانات الرحلة المشتركة </h3>
        </div>

        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th scope="col">بيانات الرحلة </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            
            <tr>
              <td>الاسم السائق</td>
              <td> {selectedTrip?.driver?.firstname} </td>
            </tr>

            <tr>
              <td> نقطة البداية </td>
              <td> {selectedTrip?.startLocation?.name} </td>
            </tr>

            <tr>
              <td> نقطة النهاية </td>
              <td> {selectedTrip?.endLocation?.name}</td>
            </tr>

            <tr>
              <td> المسافة </td>
              <td> {selectedTrip?.distance} Km</td>
            </tr>

            <tr>
              <td> نوع الرحلة </td>
              <td> {selectedTrip?.channel?.name}</td>
            </tr>

            <tr>
              <td> سعر الكيلو </td>
              <td> {selectedTrip?.channel?.pricePerKm}</td>
            </tr>

            <tr>
              <td> الحالة </td>
              <td> {selectedTrip?.tripStatus?.name} </td>
            </tr>

          
            <tr>
              <td> أنشأت </td>
              <td> {selectedTrip?.createdAt?.toString()} </td>
            </tr>

            <tr>
              <td> التكلفة الكلية </td>
              <td> {selectedTrip?.totalAmount} جنيه </td>
            </tr>
          </tbody>
        </table>

        <table className="table table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">بيانات الركاب</th>
                    <th scope="col"></th>
                  </tr>
                </thead>

                </table>

                <div className="px-5">
                {
            selectedTrip?.passengers?.map((passenger, index) => {

              return (
                <table key={passenger.uuid} className="table table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th scope="col"> الراكب رقم { index+1 } </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
             
                <tbody>
                <tr>
              <td>الاسم الراكب</td>
              <td> {passenger?.passenger?.firstname} </td>
            </tr>
            

            <tr>
              <td> نقطة البداية </td>
              <td> {passenger?.startLocation?.name} </td>
            </tr>

            <tr>
              <td> نقطة النهاية </td>
              <td> {passenger?.endLocation?.name}</td>
            </tr>

            <tr>
              <td> المسافة </td>
              <td> {passenger?.distance} Km</td>
            </tr>


        

            <tr>
              <td> الحالة </td>
              <td> {passenger?.tripStatus?.name} </td>
            </tr>

            <tr>
              <td> note </td>
              <td> {passenger?.note} </td>
            </tr>
            <tr>
              <td> أنشأت </td>
              <td> {passenger?.createdAt?.toString()} </td>
            </tr>

            <tr>
              <td> التكلفة الكلية </td>
              <td> {passenger?.amount} جنيه </td>
            </tr>
                
                  
                </tbody>
              </table>
              )
            })
          }
                </div>
       
      

        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th scope="col">بيانات السائق</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
          <tr>
              <td> الاسم بالكامل</td>
              <td> {selectedTrip?.driver?.firstname} {selectedTrip?.driver?.lastname} </td>
            </tr>
            <tr>
              <td>  رقم الهاتف </td>
              <td> {selectedTrip?.driver?.phoneNumber} </td>
            </tr>

            <tr>
              <td>تاريخ الإنضمام  </td>
              <td> {selectedTrip?.driver?.createdAt} </td>
            </tr>
          
            <tr>
              <td>  رقم الرخصة   </td>
              <td> {selectedTrip?.driver?.driverLicense} </td>
            </tr>
          
          </tbody>
        </table>
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th scope="col">بيانات السيارة</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
          <tr>
              <td>  الشركة</td>
              <td> {selectedTrip?.car?.brand} </td>
            </tr>
            <tr>
              <td>   النوع </td>
              <td> {selectedTrip?.car?.model} </td>
            </tr>
            <tr>
              <td>   اللوحة  </td>
              <td> {selectedTrip?.car?.licensePlate} </td>
            </tr>
            <tr>
              <td>   الحالة </td>
              <td> {selectedTrip?.car?.status?.name} </td>
            </tr>
            <tr>
              <td>   سنة الصنع </td>
              <td> {selectedTrip?.car?.year} </td>
            </tr>
            <tr>
              <td>   اللون </td>
              <td> {selectedTrip?.car?.color} </td>
            </tr>
            <tr>
              <td>   الصورة </td>
              
              <td>
              <img
                  src={`http://localhost:1000/uploads/resized/${selectedTrip?.car?.licenseImage}`}
                  alt="car license image"
                  width={"300px"}
                />
             </td>
            </tr>

           
          </tbody>
        </table>
      </div>
    </div>
  );
}
