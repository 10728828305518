import React from "react";
import { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import car from "./car.svg";
import { io } from "socket.io-client";
import { SOCKET_BASE_URL } from "../../../core/common";
import { Icon } from "@iconify/react";
import locationIcon from "@iconify/icons-mdi/map-marker";
import { Location } from "../../entities/locations";
import { BiReset } from "react-icons/bi";
const AnyReactComponent = ({
  text,
  lat,
  lng,
  com,
}: {
  text: any;
  lat: number;
  lng: number;
  com: any;
}) => (
  <div>
    {text} {com}
  </div>
);

export default function PickupLocationMap({
  locationSetter,
  secLocationSetter,
  clearerFunc,
}: {
  locationSetter: Function;
  secLocationSetter?: Function;
  clearerFunc?: Function;
}) {
  const defaultProps = {
    center: {
      lat: 15.577608,
      lng: 32.568565,
    },
    zoom: 16,
  };

  const [CurrentSelectedLocation, setCurrentSelectedLocation] =
    useState<CurrentLocation>({ latitude: null, longitude: null });

  const [secondCurrentSelectedLocation, setsecondCurrentSelectedLocation] =
    useState<CurrentLocation>({ latitude: null, longitude: null });

  useEffect(() => {
    locationSetter({ ...CurrentSelectedLocation });

    clearerFunc?.();
    return () => {};
  }, [CurrentSelectedLocation]);

  useEffect(() => {
    secLocationSetter?.({ ...secondCurrentSelectedLocation });

    return () => {};
  }, [secondCurrentSelectedLocation]);

  console.log({
    CurrentSelectedLocation,
    secondCurrentSelectedLocation,
  });

  const apiIsLoaded = (map, maps) => {
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer();
    directionsRenderer.setMap(map);
    // const origin = { lat: 15.582800495989183, lng: 32.580485928336614 };
    // const destination = { lat: 15.583647921512354, lng: 32.578399765623956 };
    const origin = {
      lat: CurrentSelectedLocation.latitude ?? 0,
      lng: CurrentSelectedLocation.longitude ?? 0,
    };
    const destination = {
      lat: secondCurrentSelectedLocation.latitude ?? 0,
      lng: secondCurrentSelectedLocation.longitude ?? 0,
    };

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          // const fullROute = result?.routes[0]

          // console.log({
          //   startName: result?.routes[0].legs[0].start_address,
          //  endName: result?.routes[0].legs[0].end_address,
          //   distance: result?.routes[0].legs[0].distance,
          //   fullROute,
          // });

          directionsRenderer.setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };
  return (
    // Important! Always set the container height explicitly
  <>
    <div className="card">
      <div className="card-body ">
        <div className="" style={{ height: "65vh" }}>
          <div className="w-100 h-100">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyC6F_v_8d-6mmUZJYXIEh-EA_WFu2oUqEk",
              }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              onGoogleApiLoaded={({ map, maps }) => {
                apiIsLoaded(map, maps);
              }}
              onClick={(clicked) => {
                // console.log({
                //   CurrentSelectedLocation:  CurrentSelectedLocation,
                //   secondCurrentSelectedLocation: secondCurrentSelectedLocation
                // });

                CurrentSelectedLocation.latitude === null
                  ? setCurrentSelectedLocation({
                      latitude: clicked.lat,
                      longitude: clicked.lng,
                    })
                  : setsecondCurrentSelectedLocation({
                      latitude: clicked.lat,
                      longitude: clicked.lng,
                    });
              }}
            >
              <AnyReactComponent
                lat={CurrentSelectedLocation?.latitude ?? 0.0}
                lng={CurrentSelectedLocation?.longitude ?? 0.0}
                text=""
                com={
                  <>
                    {" "}
                    <div className="pin">
                      <Icon
                        color="ffc107"
                        fontSize={60}
                        icon={locationIcon}
                        className="pin-icon"
                      />
                      <p className="pin-text"></p>
                    </div>{" "}
                  </>
                }
              />

              <AnyReactComponent
                lat={secondCurrentSelectedLocation?.latitude ?? 0.0}
                lng={secondCurrentSelectedLocation?.longitude ?? 0.0}
                text=""
                com={
                  <>
                    {" "}
                    <div className="pin">
                      <Icon
                        color="ffc107"
                        fontSize={60}
                        icon={locationIcon}
                        className="pin-icon"
                      />
                      <p className="pin-text"></p>
                    </div>{" "}
                  </>
                }
              />
            </GoogleMapReact>
          </div>
        </div>
      </div>
    
    </div>
      <div className="card my-2">
      <div className="card-body">
        <div className="flex" style={{cursor: "pointer"}} onClick={() => {
          setCurrentSelectedLocation({latitude: null, longitude: null})
          setsecondCurrentSelectedLocation({latitude: null, longitude: null})
        }}>
        <BiReset size={40} color={""} /> 
        </div>
      </div>
    </div></>
  );
}

export function ShowPickupLocationMap({
  tripDataGetter,
  startlocation,
  endLocation,
}: {
  startlocation: Location;
  endLocation: Location;
  tripDataGetter: Function;
}) {
  const defaultProps = {
    center: {
      lat: 15.577608,
      lng: 32.568565,
    },
    zoom: 16,
  };

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    return () => {};
  }, []);

  console.log({
    startlocation,
    endLocation,
  });

  const [TripData, setTripData] = useState<{
    route: { lat: number; lng: number }[] | null;
    startLocationName: string | null;
    endLocationName: string | null;
    distance: number;
  }>({
    endLocationName: null,
    startLocationName: null,
    route: null,
    distance: 0,
  });

  const apiIsLoaded = (map, maps) => {
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer();
    directionsRenderer.setMap(map);
    // const origin = { lat: 15.582800495989183, lng: 32.580485928336614 };
    // const destination = { lat: 15.583647921512354, lng: 32.578399765623956 };
    const origin = {
      lat: startlocation.latitude ?? 0,
      lng: startlocation.longitude ?? 0,
    };
    const destination = {
      lat: endLocation.latitude ?? 0,
      lng: endLocation.longitude ?? 0,
    };

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          const fullROute = result?.routes[0].overview_path.map((pa) => {
            return { lat: pa.lat(), lng: pa.lng() };
          });

          setTripData({
            distance: Number(
              result?.routes[0].legs[0].distance?.text.slice(0, -2)
            ),

            startLocationName: result?.routes[0].legs[0].start_address ?? "",
            endLocationName: result?.routes[0].legs[0].end_address ?? "",
            route: fullROute ?? [],
          });

          tripDataGetter?.({
            distance: Number(
              result?.routes[0].legs[0].distance?.text.slice(0, -2)
            ),

            startLocationName: result?.routes[0].legs[0].start_address ?? "",
            endLocationName: result?.routes[0].legs[0].end_address ?? "",
            route: fullROute ?? [],
          });
          // console.log({
          //   startName: result?.routes[0].legs[0].start_address,
          //  endName: result?.routes[0].legs[0].end_address,
          //   distance: result?.routes[0].legs[0].distance,
          //   fullROute,
          // });

          directionsRenderer.setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };
  return (
    // Important! Always set the container height explicitly
    <div className="card">
      <div className="card-body">
        <div>
          <div className="d-flex justify-content-between">
            <p>المسافة</p>
            <b> {TripData.distance} km</b>
          </div>
          <div className="d-flex justify-content-between">
            <p>نقطة البداية</p>
            <b> {TripData.startLocationName} </b>
          </div>
          <div className="d-flex justify-content-between">
            <p>نقطة النهاية</p>
            <b> {TripData.endLocationName} </b>
          </div>
          <div className="d-flex justify-content-between">
            <p> السعر</p>
            <b>
              {" "}
              {TripData.distance > 3.5
                ? (TripData.distance - 3.5) * 300 + 3.5 * 600
                : 3.5 * 600}{" "}
            </b>
          </div>
        </div>
      </div>
      <div className="card-body ">
        <div className="" style={{ height: "65vh" }}>
          <div className="w-100 h-100">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyC6F_v_8d-6mmUZJYXIEh-EA_WFu2oUqEk",
              }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              onGoogleApiLoaded={({ map, maps }) => {
                apiIsLoaded(map, maps);
              }}
              // onClick={(clicked) => {
              //   // console.log({
              //   //   CurrentSelectedLocation:  CurrentSelectedLocation,
              //   //   secondCurrentSelectedLocation: secondCurrentSelectedLocation
              //   // });

              //   CurrentSelectedLocation.latitude === null ?  setCurrentSelectedLocation({
              //     latitude: clicked.lat,
              //     longitude: clicked.lng,
              //   }) : setsecondCurrentSelectedLocation({
              //     latitude: clicked.lat,
              //     longitude: clicked.lng,
              //   });

              // }}
            >
              {/* 
              <AnyReactComponent
                lat={startlocation?.latitude ?? 0.0}
                lng={startlocation?.longitude ?? 0.0}
                text=""
                com={
                  <>
                    {" "}
                    <div className="pin">
                      <Icon color="ffc107" fontSize={60} icon={locationIcon} className="pin-icon" />
                      <p className="pin-text"></p>
                    </div>{" "}
                  </>
                }
              /> */}

              {/* <AnyReactComponent
                lat={endLocation?.latitude ?? 0.0}
                lng={endLocation?.longitude ?? 0.0}
                text=""
                com={
                  <>
                    {" "}
                    <div className="pin">
                      <Icon color="ffc107" fontSize={60} icon={locationIcon} className="pin-icon" />
                      <p className="pin-text"></p>
                    </div>{" "}
                  </>
                }
              /> */}
            </GoogleMapReact>
          </div>
        </div>
      </div>
    </div>
  );
}

type CurrentLocation = {
  latitude: number | null;
  longitude: number | null;
};
