import axios from "axios";
import React, { createContext, ReactNode, useContext, useState } from "react";
import { useEffect } from "react";
import { BASE_URL } from "../../../core/common";
import { ProvidersProps } from "../../../core/types";
import { SharedTrip } from "../../entities/SharedTrip";
import { Trip } from "../../entities/trip";
import { useErrors } from "../Common-components/Error/error.context";
import { SharedTripRepository } from "./repo/shared-trip.repo";

type SharedTripContextType = {
  sharedTriploading?: boolean;
  setLoading?: Function | null;
  allSharedTrip? : SharedTrip[] | null;
  selectedTrip? : SharedTrip| null;
  ongoingSharedTrip? : SharedTrip[] | null;
  getSharedTrip?: Function
};
const SharedTripContext = createContext<SharedTripContextType>({
  sharedTriploading: false,
  setLoading: null,
  getSharedTrip: Function,
  selectedTrip : null
});

const sharedTripRepository: SharedTripRepository = new SharedTripRepository();
export const SharedTripProvider: React.FunctionComponent<ProvidersProps> = ({
  children,
}) => {
      const [sharedTriploading, setSharedTriploading] = useState(false);
    const [allSharedTrip, setAllSharedTrip] = useState<SharedTrip[] | null | undefined>();
    const [selectedTrip, setSelectedTrip] = useState<SharedTrip | null>();
    const [ongoingSharedTrip, setOngoingSharedTrip] = useState<Trip[] | null | undefined>();
    const { setErrors } = useErrors();

    async function getAllSharedTrips() {
      try {
        setSharedTriploading(true)
          const trips= await sharedTripRepository.getAllSharedtrips();
           
          setAllSharedTrip(trips);
            setSharedTriploading(false)

      } catch (error:any) {
        setErrors((state: any) => [
          ...state,
          { message: error.message, code:error.statusCode },
        ]);
        setSharedTriploading(false)

      }
    }
    async function getSharedTrip({uuid}:{uuid: string}) {
      try {
        setSharedTriploading(true)
          const sharedtrip= await sharedTripRepository.getASharedtrip({uuid: uuid});
           
       
            
            setSelectedTrip({...sharedtrip})
            setSharedTriploading(false)

      } catch (error:any) {
        setErrors((state: any) => [
          ...state,
          { message: error.message, code:error.statusCode },
        ]);
        setSharedTriploading(false)

      }
    }
    useEffect(() => {
      getAllSharedTrips();

    
      return () => {
        
      }
    }, [])


  return <SharedTripContext.Provider value={{    
    allSharedTrip,
    ongoingSharedTrip,
    getSharedTrip,
    sharedTriploading,
    selectedTrip
    }}>{children}</SharedTripContext.Provider>;
};

export const useSharedTripTrip = () => useContext(SharedTripContext);
