/* eslint-disable no-throw-literal */
import { api } from "../../../../core";

export class DriverRepository {

    async allDrivers({from, to}: {from: number, to: number}) {
        try {

            const res = await api.get(`api/dashboard/drivers/all`, {

            });
            
                
           return { drivers: res.data.data}
            
        } catch(e:any) {
            // console.log({error: e.response.data.data});
            
           throw e.response.data !== undefined ?  {message: e.response.data.data.error, statusCode: e.response.data.data.statusCode }: {message:e.message, statusCode: 500};
            
        }
    }
    async getDriver({uuid}: {uuid: string}) {
        try {

            const res = await api.get(`api/dashboard/driver/${uuid}`, {
            });
            
            
           return { driver: res.data.data}
            
        } catch(e:any) {
            // console.log({error: e.response.data.data});
            
           throw e.response.data !== undefined ?  {message: e.response.data.data.error, statusCode: e.response.data.data.statusCode }: {message:e.message, statusCode: 500};
            
        }
    }


    async activateOrDeactivateDriver({uuid}: {uuid: string}) {
        try {

            const res = await api.put(`api/dashboard/drivers/activate-deactivate/${uuid}`, {
                headers : {
                    "x-api-key": "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
                    "Content-Type": "application/json",
                  },
            });
            
        
           return { driver: res.data.data}
            
        } catch(e:any) {
            // console.log({error: e.response.data.data});
            
           throw e.response.data !== undefined ?  {message: e.response.data.data.error, statusCode: e.response.data.data.statusCode }: {message:e.message, statusCode: 500};
            
        }
    }
    async activateOrDeactivateCar({uuid, caruuid}: {uuid: string, caruuid: string}) {
        try {
        
        
            const res = await api.put(`api/dashboard/cars/activate-deactivate/${uuid}`,  {
                "caruuid": caruuid
              }, 
              {
                // withCredentials: true,
                headers : {
                  "x-api-key": "XswmOdCS4YEDrhZXdriqcfjJjexumBfRSBQa22Tybaog4u9OnbHEFwWqHVu5I7a",
                  "Content-Type": "application/json",
                },
              });
            
        
           return { car: res.data.data}
            
        } catch(e:any) {
            // console.log({error: e.response.data.data});
            
           throw e.response.data !== undefined ?  {message: e.response.data.data.error, statusCode: e.response.data.data.statusCode }: {message:e.message, statusCode: 500};
            
        }
    }
}




  
  
  