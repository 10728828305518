import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {  PHOTOS_BASE_URL } from "../../../../core/common";
import { Driver } from "../../../entities/Driver";
import {  useAdmin } from "../../Admins/Context/admin.context";
import ProtectedComponentsWrapper from "../../Protected-components-wrapper";
import { useDrivers } from "../context/DriversContext";

export default function SingleDriver() {
  const { uuid } = useParams();
  const { admin } = useAdmin();
  const {
    activateDriverAccount,
    deactivateDriverAccount,
    activateDriverCar,
    deactivateDriverCar,
    selectedDriver,
    getDriver
  } = useDrivers();


  useEffect(() => {
    getDriver?.({uuid: uuid})
    return () => {};
  }, []);



  return (
    <div className="card">
      <div className="card-body col px-5">
        <div>
          {selectedDriver?.image ? (
            <img
              src={`${PHOTOS_BASE_URL}/${selectedDriver?.image?.substring(
                selectedDriver?.image.indexOf("app-assets")
              )}?t=current_time`}
              alt="profile"
              width={250}
              height={250}
            />
          ) : (
            <div
              className="d-flex flex-row justify-items-start
            "
            >
              <div className="mb-5">
                {" "}
                <div className=" bg-primary p-5 "> </div>{" "}
                <span> profile picture</span>{" "}
              </div>

              <div className="  p-5"> </div>
              <div className="  p-5"> </div>
            </div>
          )}
        </div>

        <div className="d-flex justify-content-between">
          <div className="font-weight-bold">
            <span className=""> الرصيد الحالي </span>
            <span> : </span>
            <span> {selectedDriver?.balance?.total} SDG </span>
          </div>

          <div>
            {
              selectedDriver?.active && selectedDriver.driverDetails !== null ? <>
                    <ProtectedComponentsWrapper admin={admin ?? {}} allow={[111, 222, 333]}>
              <Link to={`/driver/add-balance/${selectedDriver?.uuid}`}>
                <button className="btn btn-success mx-2"> إضافة رصيد </button>
              </Link>
              
          
             
            </ProtectedComponentsWrapper>
            <ProtectedComponentsWrapper admin={admin ?? {}} allow={[111, 222]}>
          
              
              <Link to={`/driver/edit-balance/${selectedDriver?.uuid}`}>
                <button className="btn btn-warning"> تعديل رصيد </button>
              </Link>
             
            </ProtectedComponentsWrapper>
              
              </>  : <></>
            }
      
          </div>
        </div>
        {
                 selectedDriver?.active && selectedDriver.driverDetails !== null ?  <>
                   {selectedDriver?.statuscode !== 111 ? (
          <button
            onClick={() => activateDriverAccount({ uuid: selectedDriver?.uuid })}
            className="my-2 btn btn-primary"
          >
            {" "}
            موافقة{" "}
          </button>
        ) : (
          <button
            onClick={() => deactivateDriverAccount({ uuid: selectedDriver?.uuid })}
            className="my-2 btn btn-danger"
          >
            {" "}
            ايقاف{" "}
          </button>
        )}
                 </>  : <></>
        }
      

        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th scope="col">البيانات</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>الاسم بالكامل</td>
              <td>
                {" "}
                {selectedDriver?.firstname} {selectedDriver?.lastname}{" "}
              </td>
            </tr>

            <tr>
              <td> رقم الهاتف </td>
              <td>{selectedDriver?.phoneNumber} </td>
            </tr>

            <tr>
              <td> الايميل </td>
              <td> {selectedDriver?.email} </td>
            </tr>

            <tr>
              <td>رقم الرخصة </td>
              <td> {selectedDriver?.driverLicense}</td>
            </tr>
            <tr>
              <td> حالة الحساب </td>
              <td> {selectedDriver?.Status?.name}</td>
            </tr>

            <tr>
              <td> تاريخ الانضمام </td>
              <td> {selectedDriver?.createdAt} </td>
            </tr>
          </tbody>
        </table>

        <br />
        <br />
        <br />
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th scope="col">بيانات رخصة السائق </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> الاسم الاول </td>
              <td> {selectedDriver?.driverDetails?.firstname} </td>
            </tr>

            <tr>
              <td>الاسم الثاني </td>
              <td> {selectedDriver?.driverDetails?.secname} </td>
            </tr>
            <tr>
              <td> الاسم الثالث</td>
              <td> {selectedDriver?.driverDetails?.thirname} </td>
            </tr>
            <tr>
              <td> الاسم الرابع </td>
              <td> {selectedDriver?.driverDetails?.fourname}</td>
            </tr>
            <tr>
              <td> تاريخ انتهاء الرخصة</td>
              <td> {selectedDriver?.driverDetails?.licenseExpDate} </td>
            </tr>

            <tr>
              <td> صورة الرخصة</td>
              <td>
                <img
                  src={`${PHOTOS_BASE_URL}/${selectedDriver?.driverDetails?.licenseImage?.substring(
                    selectedDriver.driverDetails.licenseImage.indexOf("app-assets")
                  )}`}
                  alt="driver license"
                  width={"300px"}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <br />
{
          selectedDriver?.active && selectedDriver.driverDetails !== null ? 
          <>
           {selectedDriver?.defaultcar?.statuscode !== 111 ? (
          <button
            onClick={() =>
              activateDriverCar({
                uuid: selectedDriver?.uuid,
                caruuid: selectedDriver?.defaultcar?.uuid,
              })
            }
            className="my-2 btn btn-primary"
          >
            {" "}
            موافقة{" "}
          </button>
        ) : (
          <button
            onClick={() =>
              deactivateDriverCar({
                uuid: selectedDriver?.uuid,
                caruuid: selectedDriver?.defaultcar?.uuid,
              })
            }
            className="my-2 btn btn-danger"
          >
            {" "}
            ايقاف{" "}
          </button>
        )}
          </>
          
          : <> </>
}
       
        <br />
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th scope="col">بيانات السيارة الاساسية </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> الشركة </td>
              <td> {selectedDriver?.defaultcar?.brand} </td>
            </tr>

            <tr>
              <td>النوع </td>
              <td> {selectedDriver?.defaultcar?.model} </td>
            </tr>
            <tr>
              <td> اللوحة</td>
              <td> {selectedDriver?.defaultcar?.licensePlate} </td>
            </tr>
            <tr>
              <td> الحالة </td>
              <td> {selectedDriver?.defaultcar?.status?.name}</td>
            </tr>
            <tr>
              <td> سنة الصنع</td>
              <td> {selectedDriver?.defaultcar?.year} </td>
            </tr>
            <tr>
              <td> اللون</td>
              <td> {selectedDriver?.defaultcar?.color} </td>
            </tr>
            <tr>
              <td> صورة شهادة البحث</td>
              <td>
                <img
                  src={`${PHOTOS_BASE_URL}/${selectedDriver?.defaultcar?.licenseImage?.substring(
                    selectedDriver.defaultcar.licenseImage.indexOf("app-assets")
                  )}`}
                  alt="car license"
                  width={"300px"}
                />
              </td>
            </tr>
            <tr>
              <td> صورة  ألسيارة</td>
              <td>
                <img
                  src={`${PHOTOS_BASE_URL}/${selectedDriver?.defaultcar?.carImage?.substring(
                    selectedDriver.defaultcar.carImage.indexOf("app-assets")
                  )}`}
                  alt="car license"
                  width={"300px"}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <br />
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th scope="col"> سجل الرحلات الخاصة </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> عدد الرحلات </td>
              <td> {0} </td>
            </tr>
          </tbody>
        </table>

        <br />
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th scope="col"> سجل الرصيد </th>
              <th scope="col"></th>
            </tr>
            <tr>
              <th scope="col"> الإضافة </th>
              <th scope="col"> النقصان </th>
              <th scope="col"> المجموع السابق </th>
              <th scope="col"> المجموع الحالي </th>
            </tr>
          </thead>
          <tbody>
            {selectedDriver?.balance?.balanceHistory?.map((history) => {
              return (
                <tr>
                  <td> {history?.add?.toString() ?? "0"} </td>
                  <td> {history?.minus?.toString() ?? "0"} </td>
                  <td> {history.prevtotal} </td>
                  <td> {history.total} </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <br />
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th scope="col"> سجل الرحلات المشتركة </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> عدد الرحلات </td>
              <td> {0} </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
