import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Admin } from "../../../../entities/Admin";
import ProtectedComponentsWrapper from "../../../Protected-components-wrapper";
import { useAdmin } from "../../Context/admin.context";
import AllAdminsTable from "./all-admins-table";
import { AdminsColumn, AdminsRow } from "./types/admins";

export default function Admins() {
  const { admins, admin } = useAdmin();
  const [allAdmins, setAllAdmins] = useState<AdminsRow[]>([]);
  useEffect(() => {
    const sampleData: AdminsRow[] = [];
    admins?.map((singleAdmin) => {
      switch (admin?.rolecode) {
        case 111:
          sampleData.push({
            ...singleAdmin,
          });
          break;
        case 222:
          if(singleAdmin.rolecode !== 111) {
            sampleData.push({
              ...singleAdmin,
            });
          }
          break;
        case 333:
          if(singleAdmin.rolecode !== 111 && singleAdmin.rolecode !== 222 ) {
            sampleData.push({
              ...singleAdmin,
            });
          }
          break;
      
        default:
          break;
      }

    });

    setAllAdmins(sampleData);
    return () => {};
  }, [admins]);

  const columns: AdminsColumn[] = [
    {
      name: "اسم ",
      selector: (row: Admin) => row.name,
    },

    {
      name: " الحالة",
      selector: (row: Admin) => row.status?.name,
    },
    {
      name: " الصلاحية",
      selector: (row: Admin) => row.role?.name,
    },
  ];

  return (
    <div>
      <div className="app-title">
        <div>
          <h1>
            <i className="fa fa-th-list"></i> المسؤولين
          </h1>
        </div>
      </div>
      <ProtectedComponentsWrapper admin={admin ?? {}} allow={[111, 222]}>
        <Link to={"/create-admin"}>
          <div className="btn btn-primary">أضافة مسؤول</div>
        </Link>
      </ProtectedComponentsWrapper>

      <br />
      <br />
      <AllAdminsTable columns={columns} data={allAdmins} />
    </div>
  );
}
