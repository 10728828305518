import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChannelProvider } from "./app/components/Channels/channel.context";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Main from "./app/components/Main";
import Channels from "./app/components/Channels/channels";
import Passengers from "./app/components/passenger/Passengers";
import { PassengerProvider } from "./app/components/passenger/context/PassengerContext";
import { DriverProvider } from "./app/components/Drivers/context/DriversContext";
import Drivers from "./app/components/Drivers/Drivers";
import SingleDriver from "./app/components/Drivers/components/driver";
import AuthRoute from "./AuthRoute";
import SharedTripChannels from "./app/components/Channels/components/share-trip-channels/shared-trip-channel";
import { PrivateTrip } from "./app/components/Priavate-Trip/Components/Private-trip";
import { PrivateTripProvider } from "./app/components/Priavate-Trip/private-trip.context";
import SingleTrip from "./app/components/Priavate-Trip/Components/single-trip";
import SingleChannel from "./app/components/Channels/components/Private-trip-channels/Edit-channel";
import AddChannel from "./app/components/Channels/components/Private-trip-channels/add-channel";
import SingleSharedTripChannel from "./app/components/Channels/components/share-trip-channels/Edit-shared-trip-channel";
import SharedTrips from "./app/components/Shared-Trip/components/shared-trips";
import { SharedTripProvider } from "./app/components/Shared-Trip/shared-trip.context";
import SingleSharedTrip from "./app/components/Shared-Trip/components/single-shared-trip";
import { AdminProvider } from "./app/components/Admins/Context/admin.context";
import AdminProfile from "./app/components/Admins/Components/Admins/Admin-profile";
import Login from "./app/components/Admins/Components/Auth/Login";
import AddBalance from "./app/components/Drivers/components/add-balance";
import { BalanceProvider } from "./app/components/Drivers/context/balance.context";
import AddServiceCenter from "./app/components/Service-Center/add-service-center";
import { ServiceCenterProvider } from "./app/components/Service-Center/context/service-center.context";
import ServicesCenters from "./app/components/Service-Center/Service-center";
import EditServiceCenter from "./app/components/Service-Center/edit-service-center";
import CreateAdmin from "./app/components/Admins/Components/Admins/create-admin";
import Admins from "./app/components/Admins/Components/Admins/admins";
import EditAdmin from "./app/components/Admins/Components/Admins/edit-admin";
import ApkVersions from "./app/components/APk-Versions";
import { ApkVersionProvider } from "./app/components/APk-Versions/context/apk-version.context";
import EditApkVersion from "./app/components/APk-Versions/edit-apk-version";
import EditAdmins from "./app/components/Admins/Components/Admins/edit-admins";
import EditAdminPassword from "./app/components/Admins/Components/Admins/edit-admin-password";
import EditBalance from "./app/components/Drivers/components/edit-balance";
import SinglePassenger from "./app/components/passenger/components/passenger";
import { ErrorsProvider } from "./app/components/Common-components/Error/error.context";
import { SearchProvider } from "./app/components/Search/context/search.context";
import LiveDataPage from "./app/components/pages/Live-data-page";
import CallCenterPage from "./app/components/pages/call-center-page";
import { LiveDataProvider } from "./app/components/LiveData/live-data.context";
import CreatePassenger from "./app/components/Priavate-Trip/Components/Private-Trip-creation/create-trip";
import CreateTrip from "./app/components/Priavate-Trip/Components/Private-Trip-creation/create-trip";
import TripCreated from "./app/components/Priavate-Trip/Components/Private-Trip-creation/trip-created";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <ErrorsProvider name="errors">

<ServiceCenterProvider name="ServiceCenterProvider" >
    <AdminProvider name="user provider">
    
      <DriverProvider name={"dirver provider"}>
      <LiveDataProvider name="LiveDataProvider" >
        <BalanceProvider name="balance">
          <ApkVersionProvider name="apk version">
            <ServiceCenterProvider name="service center provider">
              <PrivateTripProvider name="ptivate trip">
                <SharedTripProvider name="shared trip ">
                  <PassengerProvider name="passengers">
                    <ChannelProvider name={"channel provider"}>
    <SearchProvider name="search" >

                      <Router>
                        <Routes>
                          <Route
                            path="/"
                            element={
                              <AuthRoute>
                                <App children={<Main />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/passenger/create-trip/:phoneNumber"
                            element={
                              <AuthRoute>
                                <App children={<CreateTrip/>} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/passenger/trip-created/:tripuuid"
                            element={
                              <AuthRoute>
                                <App children={<TripCreated/>} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/live-data"
                            element={
                              <AuthRoute>
                                <App children={<LiveDataPage />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/call-center"
                            element={
                              <AuthRoute>
                                <App children={<CallCenterPage />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/trip-channels"
                            element={
                              <AuthRoute>
                                <App children={<Channels />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/all-shared-trips"
                            element={
                              <AuthRoute>
                                <App children={<SharedTrips id={0} />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/shared-trip-channels"
                            element={
                              <AuthRoute>
                                <App children={<SharedTripChannels />} />
                              </AuthRoute>
                            }
                          />
                          <Route path="/login" element={<Login from="" />} />
                          <Route
                            path="/passengers"
                            element={
                              <AuthRoute>
                                <App children={<Passengers />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/passenger/:uuid"
                            element={
                              <AuthRoute>
                                <App children={<SinglePassenger />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/ongoing-private-trip"
                            element={
                              <AuthRoute>
                                <App children={<PrivateTrip id={1} />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/all-private-trip"
                            element={
                              <AuthRoute>
                                <App children={<PrivateTrip id={0} />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/trip/:uuid"
                            element={
                              <AuthRoute>
                                <App children={<SingleTrip />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/user"
                            element={<App children={<AdminProfile />} />}
                          />
                          <Route
                            path="/create-admin"
                            element={
                              <AuthRoute>
                                <App children={<CreateAdmin />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/edit-admin/:adminuuid"
                            element={
                              <AuthRoute>
                                <App children={<EditAdmin />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/edit-admin-password/:adminuuid"
                            element={
                              <AuthRoute>
                                <App children={<EditAdminPassword />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/all-admins"
                            element={
                              <AuthRoute>
                                <App children={<Admins />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/drivers"
                            element={
                              <AuthRoute>
                                <App children={<Drivers />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/driver/:uuid"
                            element={
                              <AuthRoute>
                                <App children={<SingleDriver />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/add-channel"
                            element={
                              <AuthRoute>
                                <App children={<AddChannel />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/channel/:uuid"
                            element={
                              <AuthRoute>
                                <App children={<SingleChannel />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/shared-trip-channel/:uuid"
                            element={
                              <AuthRoute>
                                <App children={<SingleSharedTripChannel />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/all-shared-trips"
                            element={
                              <AuthRoute>
                                <App children={<SharedTrips id={0} />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/ongoing-shared-trips"
                            element={
                              <AuthRoute>
                                <App children={<SharedTrips id={1} />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/shared-trip/:uuid"
                            element={
                              <AuthRoute>
                                <App children={<SingleSharedTrip />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/driver/add-balance/:driveruuid"
                            element={
                              <AuthRoute>
                                <App children={<AddBalance />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/driver/edit-balance/:driveruuid"
                            element={
                              <AuthRoute>
                                <App children={<EditBalance />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/service-center"
                            element={
                              <AuthRoute>
                                <App children={<ServicesCenters />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/add-service-center"
                            element={
                              <AuthRoute>
                                <App children={<AddServiceCenter />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/edit-service-center/:uuid"
                            element={
                              <AuthRoute>
                                <App children={<EditServiceCenter />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/apks"
                            element={
                              <AuthRoute>
                                <App children={<ApkVersions />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/edit-apk-version/:uuid"
                            element={
                              <AuthRoute>
                                <App children={<EditApkVersion />} />
                              </AuthRoute>
                            }
                          />
                          <Route
                            path="/edit-admins/:uuid"
                            element={
                              <AuthRoute>
                                <App children={<EditAdmins />} />
                              </AuthRoute>
                            }
                          />
                        </Routes>
                      </Router>
    </SearchProvider>

                    </ChannelProvider>
                  </PassengerProvider>
                </SharedTripProvider>
              </PrivateTripProvider>
            </ServiceCenterProvider>
          </ApkVersionProvider>
        </BalanceProvider>
        </LiveDataProvider>
      </DriverProvider>
    </AdminProvider>
    </ServiceCenterProvider>
  
  </ErrorsProvider>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
