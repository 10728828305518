import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom"
import { socket } from '../../../../../core/common';
import { Trip } from '../../../../entities/trip';
import { TRIP_EVENTS } from '../../../../Socket-Events/events';
import ShowTwoLocations from '../../../Map/small-maps/show-two-locations';
import { usePrivateTrip } from '../../private-trip.context';
export default function TripCreated() {

   //2 modal
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

  const {tripuuid} = useParams();

  const {getAPrivateTrip,SetSelectedPrivateTrip, joinAsocket,cancelTrip, selectedPrivateTrip}= usePrivateTrip();
  

  useEffect(() => {
    
    getAPrivateTrip?.({uuid: tripuuid})

    
    // joinAsocket?.()
    
    socket.emit('CREATE_TRIP', {uuid: selectedPrivateTrip?.uuid})
    return () => {
      
    }
  }, [])
  React.useEffect(() => {
      socket.on(TRIP_EVENTS.TRIP_ACCEPTED, ({trip}) => {
        console.log({
          trip
        });
        
        SetSelectedPrivateTrip?.({...trip});
        
        })
    return () => {
      
    }
  }, [selectedPrivateTrip])
  

  
  return (
<>
<div className="card">
  <div className="card-body">
   {
    <ShowTwoLocations endLocation={{...selectedPrivateTrip?.endLocation} } startlocation={{...selectedPrivateTrip?.startLocation} } />
   }  

   <div>
   <button
        onClick={async () => {
          cancelTrip?.({
            input: selectedPrivateTrip
          })
    
        }}
        className="my-2 mx-2 btn btn-danger"
      >
        إلغاء رحلة
      </button>
   <button
        onClick={async () => {
          socket.emit(TRIP_EVENTS.CREATE_TRIP, {uuid: selectedPrivateTrip?.uuid})
    
        }}
        className="my-2 mx-2 btn btn-primary"
      >
         البحث عن سائق
      </button>
   </div>

   <div className="card-body">
          <h3> بيانات الرحلة الخاصة </h3>
        </div>
      
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th scope="col">بيانات الرحلة </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>الاسم الراكب</td>
              <td> {selectedPrivateTrip?.passenger?.firstname} </td>
            </tr>
            <tr>
              <td>الاسم السائق</td>
              <td> {selectedPrivateTrip?.driver?.firstname} </td>
            </tr>

            <tr>
              <td> نقطة البداية </td>
              <td> {selectedPrivateTrip?.startLocation?.name} </td>
            </tr>

            <tr>
              <td> نقطة النهاية </td>
              <td> {selectedPrivateTrip?.endLocation?.name}</td>
            </tr>

            <tr>
              <td> المسافة </td>
              <td> {selectedPrivateTrip?.distance} Km</td>
            </tr>

            <tr>
              <td> نوع الرحلة </td>
              <td> {selectedPrivateTrip?.channel?.name}</td>
            </tr>

            <tr>
              <td> سعر الكيلو </td>
              <td> {selectedPrivateTrip?.channel?.pricePerKm}</td>
            </tr>

            <tr>
              <td> الحالة </td>
              <td className={selectedPrivateTrip?.tripStatus?.code ?? 0 > 111 ? "text-success" : "text-danger"}> <b> {selectedPrivateTrip?.tripStatus?.name} </b> </td>
            </tr>

            <tr>
              <td> note </td>
              <td> {selectedPrivateTrip?.note} </td>
            </tr>
            <tr>
              <td> أنشأت </td>
              <td> {selectedPrivateTrip?.createdAt?.toString()} </td>
            </tr>

            <tr>
              <td> التكلفة الكلية </td>
              <td> {selectedPrivateTrip?.totalAmount} جنيه </td>
            </tr>
          </tbody>
        </table>

  </div>
</div>
</>
  )
}
