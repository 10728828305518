import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ServiceCenter } from "../../entities/ServiceCenter";
import { useServiceCenter } from "./context/service-center.context";
import ServiceCenterTable from "./service-center-table";
import {
  ServicesCentersColumn,
  ServicesCentersRow,
} from "./types/service-center";
import Loading from "@iconify/icons-mdi/loading";
import { Icon } from "@iconify/react";
import Loader from "../Loader";
import ServiceCenterMap from "./Service-Center-map";

export default function ServicesCenters() {
  const { servicesCenters, loading } = useServiceCenter();

  const [data, setData] = useState<ServicesCentersRow[]>([]);
  useEffect(() => {
    const sampleData: ServicesCentersRow[] = [];
    servicesCenters?.map((center) => {
      sampleData.push({
        ...center,
      });
    });
    console.log({
      servicesCenters,
    });
    setData(sampleData);
    return () => {};
  }, [servicesCenters]);

  const columns: ServicesCentersColumn[] = [
    {
      name: '#',
      selector: (row:  ServiceCenter) => row.createdAt,
      cell: (row, index, column, id) => {
          return    <p className="">{index + 1} </p> 
  },
      sortable: true,
  },
    {
      name: "اسم ",
      selector: (row: ServiceCenter) => row.name,
    },
    {
      name: "  الوصف",
      selector: (row: ServiceCenter) => row.description,
    },
    {
      name: "latitude",
      selector: (row: ServiceCenter) => row.latitude,
    },
    {
      name: " longitude",
      selector: (row: ServiceCenter) => row.longitude,
    },
    {
      name: " الحالة",
      selector: (row: ServiceCenter) => row.status?.code === 111 ? <p  className="green-text"> مفعل </p> : <p  className="red-text"> موقوف</p>,

    },
    {
      name: 'تارخ الإنشاء',
      selector: (row:  ServiceCenter) => row.createdAt,
      cell: (row, index, column, id) => {
          return    <p className="">{new Date(row.createdAt).toLocaleDateString()} </p> 
  },
      sortable: true,
  },
  ];

  return (
    <div>
      <div className="app-title">
        <div>
          <h1>
            <i className="fa fa-th-list"></i> مراكز الخدمات
          </h1>
        </div>
      </div>

      <Link to={"/add-service-center"}>
        <div className="btn btn-primary">أضافة مركز خدمات</div>
      </Link>

      <br />
      <br />
      <div className="card">
        <div className="card-body">
          {loading ? (
          <Loader />
          ) : (
            <>
            <ServiceCenterMap serviceCenters={servicesCenters ?? []} />
            <ServiceCenterTable columns={columns} data={data} />

            </>
          )}
        </div>
      </div>
    </div>
  );
}
