import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import car from "./car.svg";
import { LiveDriversWithLocationType, useLiveData } from "../LiveData/live-data.context";
import { Modal, Button } from "react-bootstrap";
import { Trip } from "../../entities/trip";

const AnyReactComponent = ({
  text,
  lat,
  lng,
  com,
}: {
  text: any;
  lat: number;
  lng: number;
  com: any;
}) => (
  <div>
    {text} {com}
  </div>
);

export default function LiveDataMap({selectedTrip}: {selectedTrip?: Trip}) {

  React.useEffect(() => {
    
  
    return () => {
      
    }
  }, [selectedTrip])
  

  
  const {liveDriversWithLocation} = useLiveData();
  const defaultProps = {
    center: {
      lat: 15.577608,
      lng: 32.568565,
    },
    zoom: 14,
  };

  const apiIsLoaded = (map, maps) => {
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer();
    directionsRenderer.setMap(map);
    // const origin = { lat: 15.582800495989183, lng: 32.580485928336614 };
    // const destination = { lat: 15.583647921512354, lng: 32.578399765623956 };
    const origin = {
      lat: Number(selectedTrip?.startLocation?.latitude) ?? 0,
      lng: Number(selectedTrip?.startLocation?.longitude) ?? 0,
    };
    const destination = {
      lat: Number(selectedTrip?.endLocation?.latitude) ?? 0,
      lng:  Number(selectedTrip?.endLocation?.longitude) ?? 0,
    };
    console.log({
      selectedTrip,
      origin,destination
    });
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          const fullROute = result?.routes[0].overview_path.map((pa) => {
            return { lat: pa.lat(), lng: pa.lng() };
          });

      
          
          // console.log({
          //   startName: result?.routes[0].legs[0].start_address,
          //  endName: result?.routes[0].legs[0].end_address,
          //   distance: result?.routes[0].legs[0].distance,
          //   fullROute,
          // });

          directionsRenderer.setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          const fullROute = result?.routes[0].overview_path.map((pa) => {
            return { lat: pa.lat(), lng: pa.lng() };
          });

      
          
          // console.log({
          //   startName: result?.routes[0].legs[0].start_address,
          //  endName: result?.routes[0].legs[0].end_address,
          //   distance: result?.routes[0].legs[0].distance,
          //   fullROute,
          // });

          directionsRenderer.setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };
  
  // modal
  const [show, setShow] = useState(false);
  const [selectedDriver, setselectedDriver] = useState<LiveDriversWithLocationType | null>(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    // Important! Always set the container height explicitly
    <div className="card">
      <div className="card-body ">
        <div className="" style={{ height: "90vh" }}>
          <div className="w-100 h-100">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyC6F_v_8d-6mmUZJYXIEh-EA_WFu2oUqEk",
              }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              onGoogleApiLoaded={({ map, maps }) => {
                apiIsLoaded(map, maps);
              }}
              onChildMouseDown={(child) => {
              
                setselectedDriver(liveDriversWithLocation?.find(d => d?.driver?.uuid === child) ?? null)
                handleShow()
                
              }}
              onChildMouseUp={(child) => {
                setselectedDriver(null)
                handleClose()
                
              }}
              onChildClick={(child) => {
                console.log({child});
                
              }}
            >
              {liveDriversWithLocation?.map((liveDriver) => {
                return (
                  <AnyReactComponent
                    key={liveDriver.driver.uuid}
                    lat={liveDriver.location.latitude}
                    lng={liveDriver.location.longitude}
                    text
                    com={
                      <>
                        {" "}
                        <img src={car} width={40} height={40} alt="" />{" "}
                      </>
                    }
                  />
                );
              })}
              {/* <AnyReactComponent
          lat={ 15.577608}
          lng={ 32.568565}
          text="Share taxi"
          com={(<>     </>)}
        /> */}
            </GoogleMapReact>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>تفاصيل السائق</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex-col justify-content-between ">
            <div className="d-flex justify-content-between my-3">
              <span> الاسم بالكامل </span>
              <span>
                {selectedDriver?.driver.firstname} {selectedDriver?.driver.lastname}
              </span>
            </div>

     
          </div>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    </div>
  );
}

