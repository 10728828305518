import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { Channel } from "../../../../entities/channel";
import { useChannel } from "../../channel.context";
import { Formik, Field, Form } from "formik";
import { useNavigate } from "react-router-dom";

type Inputs = {
  name: string;
  openPrice: string;
  pricePerKm: string;
};
export default function AddChannel() {

    let navigate = useNavigate();
    const { channels, createChannel } = useChannel();
    
  const [channel, setChannel] = useState<Channel>({
    name: "",
    openPrice: 0.0,
    pricePerKm: 0.00
  });

  useEffect(() => {
    
    return () => {};
  }, [channels]);
  function changer(value) {
    setChannel({
      ...value,
    });
  }

  return (
    <div className="px-3 mt-5">

      <div className="card  my-3 px-3 ">
        <div className="pt-3">
          <h3>   أضافة قناة </h3>
        </div>
        <div className="card-body ">
          <Formik
            initialValues={{
              openPrice: channel?.openPrice,
              pricePerKm: channel?.pricePerKm,
            }}
            onSubmit={async (values) => {
              if (
                channel.name !== "" && channel?.openPrice   && channel?.pricePerKm 
              ) {
         
               await createChannel?.(
                {
                 ...channel
                }
               )
          
                return navigate("/trip-channels"); 
             
              }
             
            }}
          >
            <Form>
              <label htmlFor="openPrice">   اسم القناة </label>
              <Field
                className="form-control"
                name="name"
                type="text"
                value={channel?.name || ""}
                onChange={(e) =>
                  changer({ ...channel, name: e.target.value })
                }
              />
              <label htmlFor="openPrice"> سعر فتح المشوار </label>
              <Field
                className="form-control"
                name="openPrice"
                type="number"
                value={channel?.openPrice || ""}
                onChange={(e) =>
                  changer({ ...channel, openPrice: Number(e.target.value) })
                }
              />

              <label htmlFor="pricePerKm">سعر الكيلو</label>
              <Field
                name="pricePerKm"
                className="form-control"
                type="number"
                value={channel?.pricePerKm || ""}
                onChange={(e) =>
                  changer({ ...channel, pricePerKm: Number(e.target.value) })
                }
              />
              <button className="my-3 btn btn-primary" type="submit">Submit</button>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  )
}
