import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Admin } from '../../../../entities/Admin';
import { useAdmin } from '../../Context/admin.context';

export default function EditAdmin() {
  const {admin, updateAdmin} = useAdmin();
  const { adminuuid } = useParams();
  let navigate = useNavigate();
  
  const roles = [
      // {
      //     name: "super Admin",
      //     code: 111
      // },
      {
          name: "admin",
          code: 222
      },
      {
          name: "tech support ",
          code: 333
      }
  ]
const [updatedAdmin, setUpdatedAdmin] = React.useState<Admin | null>({

});
function changer(value) {
  setUpdatedAdmin({
    ...value
  })
}

async function handlerSubmit() {
  
 await updateAdmin?.({
    ...updatedAdmin
  })
  
  navigate("/user")

}

React.useEffect(() => {
  if(adminuuid === admin?.uuid) {
    setUpdatedAdmin({...admin})
  }
 
  return () => {
    
  }
}, [])


  return (
<div className="px-3 mt-5">
    <div className="card  my-3 px-3 ">
      <div className="pt-3">
        <h3> تعديل البيانات </h3>
      </div>
      <div className="card-body ">
        <form onSubmit={(e) => e.preventDefault()} action="#">
          <div>
            <label htmlFor="amount"> الاسم  </label>
            <input
              className="form-control"
              type="text"
              name="name"
              id=""
              value={updatedAdmin?.name}
              onChange={(e) => changer({...updatedAdmin, name: e.target.value })}
            />
          </div>
          <div>
            <label htmlFor="amount"> ألايميل  </label>
            <input
              className="form-control"
              type="text"
              name="email"
              id=""
              value={updatedAdmin?.email}
              onChange={(e) => changer({...updatedAdmin, email: e.target.value })}
            />
          </div>
        
          <div>
            <label htmlFor="amount">  phone Number </label>
            <input
              className="form-control"
              type="number"
              name="phoneNumber"
              id=""
              value={updatedAdmin?.phoneNumber}
              onChange={(e) => changer({...updatedAdmin, phoneNumber: e.target.value.toString() })}
            />
          </div>
          {/* <div className="form-group">
                    <label htmlFor="exampleSelect1"> الصلاحية </label>
                    <select  value={updatedAdmin?.rolecode}    onChange={(e) => changer({...updatedAdmin, rolecode: Number(e.target.value) })} className="form-control" id="exampleSelect1">
                        {
                           roles.map(number => {
                             return   <option key={number.code} value={number.code}>{number.name}</option>
                            })
                        }
                
                     
                    </select>
                  </div> */}

          <button
            onClick={e => handlerSubmit()}
            className="my-3 btn btn-primary"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
  )
}
