import { searchapi } from "../../../../core";

export class SearchRepository {

    async searchPassengers({ text}: { text: string}) {
        try {

            const res = await searchapi.get(`/search/passengers/${text}`, {
                // withCredentials: true,
                // headers: {
                //     authorization: `Bearer ${accessToken}`,
                //     "x-refresh-token": refreshToken
                // }
            });
            

           return { data: res.data}
            
        } catch(e:any) {
                
           throw e.response.data ?? {error: e.message, statusCode: 400};
            
        }
    }
    async searchDrivers({ text}: { text: string}) {
        try {

            const res = await searchapi.get(`/search/drivers/${text}`, {
                // withCredentials: true,
                // headers: {
                //     authorization: `Bearer ${accessToken}`,
                //     "x-refresh-token": refreshToken
                // }
            });
            
                console.log({ data: res.data});
                
           return { data: res.data}
            
        } catch(e:any) {
                
           throw e.response.data ?? {error: e.message, statusCode: 400};
            
        }
    }
    async searchTrips({ text}: { text: string}) {
        try {

            const res = await searchapi.get(`/search/trips/${text}`, {
                // withCredentials: true,
                // headers: {
                //     authorization: `Bearer ${accessToken}`,
                //     "x-refresh-token": refreshToken
                // }
            });
            
                console.log({ data: res.data});
                
           return { data: res.data}
            
        } catch(e:any) {
                
           throw e.response.data ?? {error: e.message, statusCode: 400};
            
        }
    }
}