import React from "react";
import { useErrors } from "./error.context";

export default function Errormessage() {
  const { errors } = useErrors();
  const [errorsComponents, setErrorsComponents] = React.useState<
    React.ReactElement[]
  >([]);

  React.useEffect(() => {
    let allErrors: React.ReactElement[] = [];
    errors?.map((error, index) => {
      const newCo: React.ReactElement = (
        <div key={index}>
          <div className="alert alert-danger  " role="alert">
          <p>
          {error.message} 
          </p>
</div>
        
        </div>
      );
      allErrors.push(newCo);
    });

    setErrorsComponents(allErrors);

    return () => {};
  }, [errors]);

  return <div className="position-fixed fixed-bottom w-screen h-screen d-flex justify-content-center" style={{zIndex: '1000000'}} >
    {errorsComponents}
  </div>;
}
