import axios from "axios";
import React, { createContext, ReactNode, useContext, useState } from "react";
import { useEffect } from "react";
import { BASE_URL } from "../../../../core/common";
import { Driver } from "../../../entities/Driver";
import { Passenger } from "../../../entities/Passenger";
import { useErrors } from "../../Common-components/Error/error.context";
import { DriverRepository } from "../repository/driver.repo";

type DriversContextType = {
    driverLoading?: boolean,
    setLoading?: Function | null,
    drivers?: Driver[] | undefined | null,
    selectedDriver?: Driver | null,
    setDrivers: Function | null,
    getDrivers: Function,
    getDriver: Function,
    activateDriverAccount: Function
    deactivateDriverAccount: Function
    activateDriverCar: Function
    deactivateDriverCar: Function
    activedrivers?: [] | null
}
const DriversContext = createContext<DriversContextType>({
    drivers: null,
    selectedDriver: null,
    driverLoading: false,
    activedrivers: [] ,
    setDrivers: Function,
    setLoading: Function,
    getDrivers: Function ,
    getDriver: Function ,
    activateDriverAccount: Function,
    deactivateDriverAccount: Function,
    activateDriverCar: Function,
    deactivateDriverCar: Function


});
type ProvidersProps = {
    name: string;
    children?: React.ReactNode;
  };


  const driverRepository = new DriverRepository();


export const  DriverProvider : React.FunctionComponent<ProvidersProps>= ({ children }) =>   {
  // all drivers
  const [drivers, setDrivers] = React.useState<Driver[] | null>();
  const [selectedDriver, setSelectedDriver] = React.useState<Driver | null>();
  const [driverLoading, setDriverLoading] = React.useState<boolean>(false);
  const { errors, setErrors } = useErrors();

  // active driver

  async function activateDriverAccount({uuid}: {uuid: string}) {
    try {
      const {driver} = await driverRepository.activateOrDeactivateDriver({uuid:uuid});
      console.log({
        selectedDriver
      });

        getDriver({uuid})
        console.log({
          selectedDriver
        });
        
    
      
  } catch (error: any) {
    setErrors((state: any) => [
      ...state,
      { message: error.message, code:error.statusCode },
    ]);
  }
   
    
  }
  
  async function deactivateDriverAccount({uuid}: {uuid: string}) {
    try {
      const {driver} = await driverRepository.activateOrDeactivateDriver({uuid:uuid});
     

        getDriver({uuid})
    
    
      
  } catch (error: any) {
    setErrors((state: any) => [
      ...state,
      { message: error.message, code:error.statusCode },
    ]);
  }
  }

  // cars

  async function activateDriverCar({uuid,caruuid}: {uuid: string, caruuid: string}) {
 
    try {
      const {car} = await driverRepository.activateOrDeactivateCar({uuid, caruuid});
     

        getDriver({uuid})
    
    
      
  } catch (error: any) {
    setErrors((state: any) => [
      ...state,
      { message: error.message, code:error.statusCode },
    ]);
  }
  }

  async function deactivateDriverCar({uuid,caruuid}: {uuid: string, caruuid: string}) {
    try {
      const {car} = await driverRepository.activateOrDeactivateCar({uuid, caruuid});
     

        getDriver({uuid})
    
    
      
  } catch (error: any) {
    setErrors((state: any) => [
      ...state,
      { message: error.message, code:error.statusCode },
    ]);
  }
    
  }



  async function getDrivers() {
    try {
        const {drivers} = await driverRepository.allDrivers({from:0, to: 10});

      
      
        setDrivers([
          ...drivers
        ])
        
    } catch (error: any) {
      setErrors((state: any) => [
        ...state,
        { message: error.message, code:error.statusCode },
      ]);
    }
    // setDrivers(driversResponse.data.data);
 
  }
  async function getDriver({uuid}: {uuid: string}) {
    try {
        const {driver} = await driverRepository.getDriver({uuid:uuid});

 
   
        console.log({
          recentDriver:driver
        });
        
      
        setSelectedDriver(
         { ...driver}
        )
        
    } catch (error: any) {
      setErrors((state: any) => [
        ...state,
        { message: error.message, code:error.statusCode },
      ]);
    }
    // setDrivers(driversResponse.data.data);
 
  }

  useEffect(() => {
    getDrivers();
    return () => {};
  }, []);

  
  return (
    <DriversContext.Provider value={{ drivers,setDrivers, 
      getDrivers,
      getDriver,
      driverLoading,
      selectedDriver,
    deactivateDriverAccount,
    activateDriverAccount,
    activateDriverCar,
    deactivateDriverCar
    
    }} >
      {children}
    </DriversContext.Provider>
  );
}

export const useDrivers = () => useContext(DriversContext);
