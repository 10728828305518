/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSearch } from "../Search/context/search.context";
import PassengersTable from "./components/PassengerTable";
import { usePassengers } from "./context/PassengerContext";
import { PassengersRow } from "./types/Passengers";
import {
  allPassengersColumns,
  notConfirmedPassengerColumns,
  notVerifiedPassengerColumns,
} from "./types/PassengerTable";

export default function passengers() {
  const { passengers } = usePassengers();

  const [selectedTable, setSelectedTable] = useState<number>(0);

  const [allPassengersData, setAllPassengersData] = useState<PassengersRow[]>(
    []
  );

  const [searchedPassengers, setSearchedPassenger] = useState<[]>([]);
  const { searchPassengers } = useSearch();
  async function  searchHandler(e) {
    if(e.target.value) {
    const data = await searchPassengers?.({ text: e.target.value });

    const passengers: [] = data || []
 
    
   setSearchedPassenger([...passengers ])  
      
     
   } else {
    setSearchedPassenger([])
   }
   }

  useEffect(() => {
    const sampleData: PassengersRow[] = [];
    passengers?.map((passenger) => {
      sampleData.push({
        ...passenger,
      });
    });

    setAllPassengersData(sampleData);
    return () => {};
  }, [passengers, searchedPassengers]);

  
  return (
    <>
      <div className="app-title">
        <div>
          <h1>
            <i className="fa fa-th-list"></i>الركاب
          </h1>
        </div>
        <div>
          <div className="position-relative">
            <form action="#">
              <label htmlFor="amount"> إبحث </label>
              <input
                className="form-control"
                type="text"
                name="search"
                autoComplete="off"
                placeholder="رقم الهاتف بدون 0"
                onChange={ searchHandler
                
                
                }
              />
            </form>
            <div
              className="card position-absolute"
              style={{ zIndex: 10000000 }}
            >
                <ul className="list-group  list-group-flush" style={{padding: "0"}}>
                  {
                    searchedPassengers.map((passenger, index) => {
                      return (
                        <li key={index} className="list-group-item">
                          <Link to={`/passenger/${passenger["uuid"]}`}>
                            <div className="d-flex flex-row">
                            {" "}
                              <span>{passenger["firstname"]}  {passenger["lastname"]}  </span>
                            </div>
                            <div className="d-flex">
                              <p>الهاتف : </p>
    
                              <p className="text-secondary">
                                {" "}
                                {passenger["phoneNumber"]}{" "}
                              </p>
                            </div>
                          </Link>
                        </li>
                      );
                    })
                  }

                </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div onClick={() => setSelectedTable(0)} className="col-md-6 col-lg-3">
          <div className="widget-small primary coloured-icon">
            <i className="icon fa fa-users fa-3x"></i>
            <div className="info">
              <h4>عدد الركاب الكلي </h4>
              <p>
                <b>{passengers?.length || 0} </b>
              </p>
            </div>
          </div>
        </div>
        <div onClick={() => setSelectedTable(1)} className="col-md-6 col-lg-3">
          <div className="widget-small info coloured-icon">
            <i className="icon fa fa-thumbs-o-up fa-3x"></i>
            <div className="info">
              <h4> حسابات غير مؤكدة</h4>
              <p>SMS</p>
              <p>
                <b>
                  {passengers?.filter((passenger) => passenger.active !== true)
                    .length || 0}
                </b>
              </p>
            </div>
          </div>
        </div>
        <div onClick={() => setSelectedTable(2)} className="col-md-6 col-lg-3">
          <div className="widget-small info coloured-icon">
            <i className="icon fa fa-thumbs-o-up fa-3x"></i>
            <div className="info">
              <h4> حسابات غير مفعلة </h4>
              <p>
                <b>
                  {passengers
                    ?.filter((passenger) => passenger.active === true)
                    .filter((passenger) => passenger.statuscode !== 111)
                    .length || 0}
                </b>
              </p>
            </div>
          </div>
        </div>
        <div onClick={() => setSelectedTable(3)} className="col-md-6 col-lg-3">
          <div className="widget-small info coloured-icon">
            <i className="icon fa fa-thumbs-o-up fa-3x"></i>
            <div className="info">
              <h4>نشط</h4>
              <p>
                <b>
                  {passengers?.filter((passenger) => passenger.active === true)
                    .length || 0}
                </b>
              </p>
            </div>
          </div>
        </div>
        <div onClick={() => setSelectedTable(4)} className="col-md-6 col-lg-3">
          <div className="widget-small warning coloured-icon">
            <i className="icon fa fa-files-o fa-3x"></i>
            <div className="info">
              <h4>محظور</h4>
              <p>
                <b>{0}</b>
              </p>
            </div>
          </div>
        </div>
      </div>

      {<PassengersTableShower data={allPassengersData} id={selectedTable} />}
    </>
  );
}

function PassengersTableShower({
  id,
  data,
}: {
  id: number;
  data: PassengersRow[];
}) {
  switch (id) {
    case 0:
      return (
        <PassengersTable
          columns={allPassengersColumns}
          data={data}
        ></PassengersTable>
      );

    case 1:
      return (
        <PassengersTable
          columns={notVerifiedPassengerColumns}
          data={data.filter((passenger) => passenger.active !== true)}
        ></PassengersTable>
      );

    case 2:
      return (
        <PassengersTable
          columns={notConfirmedPassengerColumns}
          data={data
            .filter((passenger) => passenger.active === true)
            .filter((passenger) => passenger.statuscode !== 111)}
        ></PassengersTable>
      );
    case 3:
      return (
        <PassengersTable
          columns={allPassengersColumns}
          data={data
            .filter((passenger) => passenger.active === true)
            .filter((passenger) => passenger.statuscode === 111)}
        ></PassengersTable>
      );

    case 4:
      return (
        <PassengersTable
          columns={allPassengersColumns}
          data={data.filter((passenger) => passenger.statuscode === 222)}
        ></PassengersTable>
      );

    // case 3:
    //   return <PassengersTable columns={allDriversColumns} data={data.filter(driver => driver.statuscode !== 222)} ></PassengersTable>

    //   break;

    default:
      return <></>;
  }
}
