import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PHOTOS_BASE_URL } from "../../../../core/common";
import { Passenger } from "../../../entities/Passenger";
import { usePassengers } from "../context/PassengerContext";

export default function SinglePassenger() {
  const { uuid } = useParams();
  const { passengers, getPrivateTrips } = usePassengers();

  const [passenger, setpassenger] = useState<Passenger>();

  useEffect(() => {
    setpassenger(passengers?.find((d) => d.uuid === uuid));

    return () => {};
  }, [passengers, uuid]);


  return (
    <div className="card">
      <div className="card-body col px-5">
        <div>
          {passenger?.image ? (
            <img
              src={`${PHOTOS_BASE_URL}/${passenger?.image?.substring(
                passenger?.image.indexOf("app-assets")
              )}`}
              alt="profile"
              width={250}
              height={250}
            />
          ) : (
            <div
              className="d-flex flex-row justify-items-start
            "
            >
              <div className="mb-5">
                {" "}
                <div className=" bg-primary p-5 "> </div>{" "}
                <span> profile picture</span>{" "}
              </div>

              <div className="  p-5"> </div>
              <div className="  p-5"> </div>
            </div>
          )}
        </div>
        <br />
        <br />
        <br />
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th scope="col">البيانات</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>الاسم بالكامل</td>
              <td>
                {" "}
                {passenger?.firstname} {passenger?.lastname}{" "}
              </td>
            </tr>

            <tr>
              <td> رقم الهاتف </td>
              <td>{passenger?.phoneNumber} </td>
            </tr>

            <tr>
              <td> الايميل </td>
              <td> {passenger?.email} </td>
            </tr>

            <tr>
              <td> حالة الحساب </td>
              <td> {passenger?.Status?.name}</td>
            </tr>

            <tr>
              <td> تاريخ الانضمام </td>
              <td> { new Date(passenger?.createdAt ).toLocaleDateString()} </td>
            </tr>
          </tbody>
        </table>

        <br />
     
        <br />
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th scope="col"> سجل الرحلات الخاصة </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> عدد الرحلات </td>
              <td> {passenger?.Trip?.length ?? 0}</td>
            </tr>
          </tbody>
        </table>

        <br />
        <br />
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th scope="col"> سجل الرحلات المشتركة </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> عدد الرحلات </td>
              <td>{passenger?.sharedTrip?.length ?? 0} </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
